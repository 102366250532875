import { usePiggyUser } from "../../utils/piggy/piggy.tsx";
import { useEffect } from "react";
import { toast } from "react-toastify";

export default function PiggyPopupAtCheckoutPage() {
  const { isLoggedIn: piggyUserIsLoggedIn, piggyEnabled } = usePiggyUser();
  useEffect(() => {
    if (piggyEnabled && !piggyUserIsLoggedIn) {
      toast.warning("Don't miss out on points, scan your loyalty card!");
    }
  }, [piggyEnabled, piggyUserIsLoggedIn]);
  return null;
}
