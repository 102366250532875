import { setSelectedArticlegroup } from "../../../../global/utils/redux/menuSlice";
import { scrollArticlegroupContent } from "../../../../global/utils/menu/ScrollArticlegroupTabListener";
import { alpha, ButtonBase, Typography } from "@mui/material";
import Articlegroup, { getArticlegroupName } from "../../../../global/utils/models/menu/Articlegroup";
import { useAppDispatch, useAppSelector } from "../../../../global/utils/redux/store";
import { useScroll } from "../../../../global/utils/ScrollProvider";
import { selectArticlegroupsMap } from "../../../../global/utils/redux/selectors/selectArticlegroupsMap";
import Image from "../../../../global/components/Image";
import clsx from "clsx";
import Box from "@mui/material/Box";
import { selectAppLanguage } from "../../../components/LanguageSelector/useLanguage.ts";

interface Props {
  articlegroup: Articlegroup;
  index: number;
}

export default function HorizontalArticlegroupTab(props: Props) {
  const { scrollTabBlocked } = useScroll();
  const isArticlegroupSelected = useAppSelector((state) => state.menu.selectedArticlegroupIds[props.articlegroup.id]);
  const dispatch = useAppDispatch();
  const articlegroup = props.articlegroup;
  const articlegroupsMap = useAppSelector(selectArticlegroupsMap);
  const lang = useAppSelector(selectAppLanguage);
  // needs to rerender based on this selector
  useAppSelector((state) => state.menu.selectedArticlegroup);

  return (
    <>
      <ButtonBase
        key={articlegroup.id}
        id={"ArticlegroupTab-" + articlegroup.id}
        className={clsx(
          "JS-HorizontalArticlegroupTabBar-Button-Selected",
          isArticlegroupSelected ? "JS-HorizontalArticlegroupTabBar-Tab-Selected" : null
        )}
        onClick={() => {
          scrollTabBlocked.current = true;
          dispatch(setSelectedArticlegroup({ articlegroup, articlegroupsMap }));
          scrollArticlegroupContent(scrollTabBlocked, articlegroup, 0);
        }}
        sx={(theme) => ({
          backgroundColor: isArticlegroupSelected ? alpha(theme.palette.primary.main, 1) : null,
          opacity: 1,
          height: 1,
          gridArea: `1 / ${props.index + 1} / 3 / ${props.index + 2}`,
          zIndex: 0,
        })}
      ></ButtonBase>
      {articlegroup.images[0] ? (
        <Box
          sx={{
            gridArea: `1 / ${props.index + 1} / 2 / ${props.index + 2}`,
            zIndex: 1,
            pointerEvents: "none",
            placeSelf: "center",
          }}
        >
          <Image
            className={"JS-HorizontalArticlegroupTabBar-ArticlegroupImage"}
            srcSet={articlegroup.images[0]?.conversions?.responsive?.srcset}
            style={{
              width: 170,
              padding: "0 30px",
              // height: 200,
              // flex: 1,
              objectFit: "contain",
              justifySelf: "center",
              // gridArea: `1 / ${props.index + 1} / 2 / ${props.index + 2}`,
            }}
          />
        </Box>
      ) : null}

      <Typography
        className={`JS-HorizontalArticlegroupTabBar-Text${
          isArticlegroupSelected ? " JS-HorizontalArticlegroupTabBar-Text-selected" : ""
        }`}
        sx={{
          zIndex: 1,
          pointerEvents: "none",
          marginTop: "15px",
          fontSize: 24,
          color: "background.paper",
          fontWeight: 800,
          width: 170,
          overflowWrap: "break-word",
          borderRadius: 2,
          marginX: 1,
          paddingY: 1,
          marginY: 1,
          gridArea: `2 / ${props.index + 1} / 3 / ${props.index + 2}`,
          alignSelf: "start",
          justifySelf: "center",
          textAlign: "center",
        }}
      >
        {getArticlegroupName(articlegroup, lang)}
      </Typography>
    </>
  );
}
