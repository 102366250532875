import CustomField, { CustomFieldType } from "../../types/shared/CustomField";
import { RootState, useAppDispatch, useAppSelector } from "../../global/utils/redux/store";
import { customFieldStepperPageChanged } from "../../global/utils/redux/customFieldsSlice";
import { useForm } from "react-hook-form";
import { useIntl } from "react-intl";
import { useCallback, useEffect, useMemo, useState } from "react";
import _ from "lodash";
import PiggyGiftcardCheckout from "../../qr/components/Piggy/Giftcards/PiggyGiftcardCheckout";
import { useCustomTexts } from "../../global/utils/useCustomTexts";
import { selectPaymentMethodCustomField } from "../../global/utils/redux/selectors/selectPaymentMethodCustomField";
import { selectCustomFields } from "../../global/utils/redux/selectors/selectCustomFields";
import { createSelector } from "@reduxjs/toolkit";
import { useCustomfieldTranslatableText } from "../../global/utils/languages.ts";
import { ReviewOrderPage } from "../pages/CheckoutPage/ReviewOrderPage.tsx";

const pickCustomFieldByName = (customFields: CustomField[], fieldName: string) =>
  _.find(customFields, { name: fieldName });

const nameMap: Record<string, string> = {
  paymentMethod: "Payment method",
};

type Page = {
  name: string;
  customField?: CustomField;
  Content?: React.FunctionComponent<{ isValid?: boolean; onShouldSlideNext: () => void }>;
  required: boolean;
  isFilledCorrectly: boolean;
};

export function useCustomerOrderInputPages() {
  const intl = useIntl();
  const useFormMethods = useForm({
    reValidateMode: "onChange",
    mode: "all",
    criteriaMode: "all",
  });
  const {
    formState: { isValid, errors },
  } = useFormMethods;
  const [pageIndex, setPageIndex] = useState(0);
  const dispatch = useAppDispatch();

  const [isInitialized, setIsInitialized] = useState<boolean>(false);
  const payDirect = useAppSelector((state) => state.global.salesarea.payDirect);
  const orderCustomFields = useAppSelector((state) => state.customFields.orderCustomFields);
  const customFieldsExceptPayment = useAppSelector(selectCustomFields);
  const paymentCustomField = useAppSelector(selectPaymentMethodCustomField);
  const customFields = (paymentCustomField
    ? [...customFieldsExceptPayment, paymentCustomField]
    : customFieldsExceptPayment
  ).filter((c) => customFieldShouldBeInStepper(c));

  const piggyGiftcardsEnabled = useAppSelector((state) => state.global.salesarea.piggy.giftcards.enabled);
  const piggyGiftcardsShowAsStep =
    useAppSelector((state) => state.global.salesarea.custom_data?.kiosk?.piggy?.show_giftcards_as_step) ?? true;

  const customFieldText = useCustomfieldTranslatableText();

  const ct = useCustomTexts(["loyalty gift card - stepper name"]);

  const pages: Page[] = useMemo(() => {
    const fields = customFields.filter((customField) => {
      if (customField.type === CustomFieldType.PaymentMethodButtons) {
        return customField.options !== undefined && customField.options.length > 1;
      }
      return !customField.hide && customField.type !== CustomFieldType.Hidden;
    });

    const pages: Page[] = [
      ...fields.map((customField) => {
        const currentName = customField.name;
        const required = pickCustomFieldByName(customFields, currentName)?.required ?? false;

        const hasValue = (orderCustomFields[currentName] ?? null)?.value != null;
        const hasError = errors[currentName] != null;
        const isFilledCorrectly = !required || (required && !hasError && hasValue);

        const displayName =
          customFieldText(customField, "stepperLabel") ?? nameMap[customField.name] ?? customField.name;

        return {
          name: displayName,
          customField,
          required,
          isFilledCorrectly,
        };
      }),
    ];

    if (piggyGiftcardsEnabled && payDirect && piggyGiftcardsShowAsStep) {
      pages.push({
        required: true,
        isFilledCorrectly: true,
        name: ct["loyalty gift card - stepper name"] ?? intl.formatMessage({ id: "Piggy Giftcards" }),
        Content: PiggyGiftcardCheckout,
      });
    }

    pages.push({
      required: false,
      isFilledCorrectly: true,
      name: intl.formatMessage({ id: "CustomFields.review-your-order-page-name" }),
      Content: ReviewOrderPage,
    });

    return pages;
  }, [
    customFields,
    piggyGiftcardsEnabled,
    payDirect,
    piggyGiftcardsShowAsStep,
    intl,
    orderCustomFields,
    errors,
    customFieldText,
    ct,
  ]);

  if (!isInitialized && pages?.[0]) {
    dispatch(customFieldStepperPageChanged(pages[0].name));
    setIsInitialized(true);
  }

  const pageStatus = useCallback(
    (idx: number) => {
      const currentPage = pages[idx];

      if (currentPage) {
        const isFilledCorrectly = currentPage.isFilledCorrectly;
        const isReviewPage = currentPage.customField == null;
        const hasKeyboardInput = _.find(
          [CustomFieldType.Text, CustomFieldType.Email, CustomFieldType.Mobilenr, CustomFieldType.Number],
          (type) => {
            return currentPage.customField?.type === type;
          }
        );

        return {
          canGoToPage: isFilledCorrectly && !isReviewPage,
          isFilledCorrectly,
          currentPage,
          hasKeyboardInput,
        };
      } else {
        return {
          canGoToPage: false,
          isFilledCorrectly: false,
          currentPage: null,
          hasKeyboardInput: false,
        };
      }
    },
    [pages]
  );

  const { isFilledCorrectly } = pageStatus(pageIndex);

  const goNext = useCallback(() => {
    const newIndex = Math.min(pages.length - 1, pageIndex + 1);
    setPageIndex(newIndex);
    const page = pages[newIndex];
    dispatch(customFieldStepperPageChanged(page.name));
  }, [dispatch, pageIndex, pages]);
  const goBack = useCallback(() => {
    const newIndex = Math.max(0, pageIndex - 1);
    setPageIndex(newIndex);
    const page = pages[newIndex];
    dispatch(customFieldStepperPageChanged(page.name));
  }, [dispatch, pageIndex, pages]);
  const goToPage = useCallback(
    (idx: number) => {
      setPageIndex(idx);
      const page = pages[idx];
      dispatch(customFieldStepperPageChanged(page.name));
    },
    [dispatch, pages]
  );

  const hasPrev = pageIndex > 0 && pages.length > 0;
  const hasNext = pages.length - 1 > pageIndex;
  const canGoNext = hasNext && isFilledCorrectly;
  const canGoPrev = hasPrev;
  const isOnReviewPage = !hasNext;
  useEffect(() => {
    if (pages[pageIndex] == null) {
      setPageIndex(0);
    }
  }, [pages, pageIndex]);
  return {
    pages,
    pageIndex,
    goNext,
    goBack,
    canGoNext,
    canGoPrev,
    pageStatus,
    goToPage,
    hasPrev,
    hasNext,
    isFilledCorrectly,
    useFormMethods,
    isOnReviewPage,
    isValid,
  };
}

export function customFieldShouldBeInStepper(customField: CustomField) {
  if (customField.type === "hidden" || customField.hide) {
    return false;
  }
  if (customField.type === CustomFieldType.OrderMode) {
    return (customField?.options ?? []).length >= 2;
  }
  return true;
}

export const selectStepperStepcount = createSelector(
  [
    selectCustomFields,
    selectPaymentMethodCustomField,
    (state: RootState) => state.global.salesarea.piggy.loyalty.enabled,
    (state: RootState) => state.global.salesarea.piggy.giftcards.enabled,
    (state: RootState) => state.global.salesarea.custom_data?.kiosk?.piggy?.show_giftcards_as_step ?? true,
  ],
  (customFields, paymentCustomField, piggyLoyaltyEnabled, piggyGiftcardsEnabled, piggyGiftcardsShowAsStep) => {
    return (
      customFields.filter(customFieldShouldBeInStepper).length +
      (piggyGiftcardsEnabled && piggyGiftcardsShowAsStep ? 1 : 0) +
      (paymentCustomField !== undefined && (paymentCustomField?.options ?? []).length > 1 ? 1 : 0)
    );
  }
);
