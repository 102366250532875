import { useEffect, useMemo, useState } from "react";

import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import CloseDialogButton from "./Dialog/CloseDialogButton.tsx";
import useSalesareaIsOpen from "../../global/utils/useSalesareaIsOpen";

import md5 from "md5";
import { useAppSelector } from "../../global/utils/redux/store";
import { selectAppLanguage } from "../../kiosk/components/LanguageSelector/useLanguage";
import { postAnalyticsEvent } from "../../global/utils/analytics/useAnalytics.ts";

const isShownMd5 = sessionStorage.getItem("WelcomePopupScreen.isShownMd5");

export default function WelcomePopupScreen() {
  const isOpen = useSalesareaIsOpen();
  const [open, setOpen] = useState(false);

  const translations = useAppSelector((state) => state.global.salesarea?.htmlTranslations);

  const selectedLanguage = useAppSelector(selectAppLanguage);

  const md5Calculated = useMemo(() => {
    if (translations?.[selectedLanguage]?.infoSplashContent) {
      return md5(translations?.[selectedLanguage]?.infoSplashContent);
    } else {
      return "";
    }
  }, [selectedLanguage, translations]);
  const welcomeScreen = useMemo(() => {
    const welcomeScreen = { title: "", body: "" };
    if (translations?.[selectedLanguage]?.infoSplashTitle) {
      welcomeScreen.title = translations[selectedLanguage].infoSplashTitle;
    }
    if (translations?.[selectedLanguage]?.infoSplashContent) {
      welcomeScreen.body = translations[selectedLanguage].infoSplashContent;
    }

    return welcomeScreen;
  }, [selectedLanguage, translations]);

  useEffect(() => {
    if (isOpen && (welcomeScreen.body || welcomeScreen.title) && md5Calculated !== isShownMd5) {
      sessionStorage.setItem("WelcomePopupScreen.isShownMd5", md5Calculated);
      setOpen(true);
    }
  }, [md5Calculated]);

  return (
    <Dialog
      open={open}
      keepMounted={false}
      onClose={() => {
        setOpen(false);
      }}
    >
      <DialogTitle sx={{ mt: 8, wordBreak: "break-word" }}>{welcomeScreen.title}</DialogTitle>
      <CloseDialogButton
        onClose={() => {
          postAnalyticsEvent({
            category: "WelcomePopupScreen",
            action: "close",
          });
          setOpen(false);
        }}
      />
      <DialogContent>
        <div
          dangerouslySetInnerHTML={{
            __html: welcomeScreen.body,
          }}
        />
      </DialogContent>
    </Dialog>
  );
}
