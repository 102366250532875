import { useIntl } from "react-intl";
import Box from "@mui/material/Box";
import { Button, Collapse, TextField, Typography } from "@mui/material";
import FormattedMessageJamezz from "../../../global/components/FormattedMessageJamezz.tsx";
import useCurrency, { CurrencyLocation } from "../../../global/utils/useCurrency.tsx";

import { useTipDrawer } from "../../../global/utils/useTipping.ts";

/**
 *
 * @param processTip Called when the tip drawer is closed.
 *                   tip parameter is set to 0 if user doesn't want to tip
 * @param open Open/close dialog
 * @constructor
 */
export function TipDrawerBodyKiosk({
  onTipIsAdded,
  paymentAmount,
}: {
  onTipIsAdded: (tipAmount: number) => void;
  paymentAmount: number;
}) {
  const {
    formatCurrency,
    customSelected,
    errorMessage,
    tippingIsAvailable,
    customTexts,
    firstPercentage,
    secondPercentage,
    thirdPercentage,
    amountFirstPercentage,
    amountSecondPercentage,
    amountThirdPercentage,
    chosenTipAmount,
    onChangeCustomTextField,
    onCustomCanceled,
    onCustomSelected,
    onPercentageSelected,
    onConfirm,
    onDontTip,
  } = useTipDrawer({
    onTipIsAdded,
    paymentAmount,
  });
  const intl = useIntl();

  return tippingIsAvailable ? (
    /* z-index necessary to show over a dialog. */

    <Box sx={{ sm: { padding: 1 }, display: "grid", justifyItems: "center", padding: 8 }}>
      <Typography variant="h5" sx={{ paddingBottom: 8, textAlign: "center" }}>
        {customTexts["add_tip_message"] ?? <FormattedMessageJamezz id={"TipDrawer.title.express_thanks_blurb"} />}
      </Typography>

      {errorMessage ? (
        <Typography color={(theme) => theme.palette.error.main} sx={{ paddingBottom: 8 }}>
          {errorMessage}
        </Typography>
      ) : null}

      <Box
        sx={{
          display: "grid",
          gridGap: 36,
          gridTemplateColumns: "repeat(6, 1fr)",
        }}
      >
        {customSelected ? (
          <>
            <TextField
              type="number"
              sx={{ gridColumn: "1 / span 4" }}
              value={chosenTipAmount}
              placeholder={formatCurrency(amountThirdPercentage).toString()}
              onChange={onChangeCustomTextField}
            />
            <Button sx={{ gridColumn: "5 / span 2" }} variant="contained" color={"primary"} onClick={onCustomCanceled}>
              <FormattedMessageJamezz id="Cancel" />
            </Button>
          </>
        ) : (
          <>
            <TipPercentageButton
              chosenTipAmount={chosenTipAmount}
              percentage={firstPercentage}
              amountPercentage={amountFirstPercentage}
              onPercentageSelected={onPercentageSelected}
            />
            <TipPercentageButton
              chosenTipAmount={chosenTipAmount}
              percentage={secondPercentage}
              amountPercentage={amountSecondPercentage}
              onPercentageSelected={onPercentageSelected}
            />
            <TipPercentageButton
              chosenTipAmount={chosenTipAmount}
              percentage={thirdPercentage}
              amountPercentage={amountThirdPercentage}
              onPercentageSelected={onPercentageSelected}
            />

            <Box sx={{ gridRow: "2", gridColumn: "1 / span 6", display: "flex", justifyContent: "center" }}>
              <Button color="primary" variant="outlined" onClick={onCustomSelected}>
                <FormattedMessageJamezz id="TipDrawer.button.custom_amount" />
              </Button>
            </Box>
          </>
        )}
        <Collapse
          in={Number(chosenTipAmount) > 0}
          sx={{
            gridColumn: "1 / span 6",
            gridRow: "3",
            marginY: 2,
            ".MuiCollapse-wrapperInner": { display: "flex", justifyContent: "center" },
          }}
        >
          <Button variant={"contained"} color={"primary"} onClick={onConfirm}>
            <FormattedMessageJamezz
              id="TipDrawer.button.confirmTip"
              values={{
                tipAmount:
                  Number(chosenTipAmount) <= 0
                    ? intl.formatMessage({
                        id: "TipDrawer.current_tip_has_no_value",
                      })
                    : formatCurrency(Number(chosenTipAmount)),
              }}
            />
          </Button>
        </Collapse>
        <Button
          sx={{ gridColumn: "2 / span 4", gridRow: "4" }}
          color={"primary"}
          variant={"outlined"}
          onClick={onDontTip}
        >
          <FormattedMessageJamezz id="TipDrawer.button.dont_tip" />
        </Button>
      </Box>
    </Box>
  ) : null;
}

function TipPercentageButton({
  chosenTipAmount,
  percentage,
  amountPercentage,
  onPercentageSelected,
}: {
  chosenTipAmount: string;
  percentage: number;
  amountPercentage: number;
  onPercentageSelected: (percentage: number) => void;
}) {
  const formatCurrency = useCurrency({ location: CurrencyLocation.Checkout });
  return (
    <Button
      color={"primary"}
      variant={String(amountPercentage) == chosenTipAmount ? "contained" : "outlined"}
      sx={{ whiteSpace: "nowrap", gridColumn: "span 2", letterSpacing: "normal" }}
      onClick={() => {
        onPercentageSelected(amountPercentage);
      }}
    >
      {formatCurrency(amountPercentage)}
      <Typography variant={"caption"}>&nbsp;({percentage}%)</Typography>
    </Button>
  );
}
