import Box from "@mui/material/Box";
import OrderOptionGroup from "../../../../global/utils/models/order/OrderOptionGroup";
import useOptionGroup, { Type } from "../../../../global/utils/orderSelector/useOptionGroup";
import { Divider, Typography } from "@mui/material";
import OptionGroupRadio from "./OptionGroupRadio";
import OptionGroupOptional from "./OptionGroupOptional";
import OptionGroupCountable from "./OptionGroupCountable";
import useScrollShadows from "../../../../global/components/useScrollShadows";
import clsx from "clsx";
import {
  OptionTranslationType,
  useOptionTranslations,
} from "../../../../global/utils/translations/useOptionTranslations";
import { OptionGroupHeaderText } from "../../../../qr/pages/OrderArticleSelectorPage/OptionGroups/OptionGroupContent";
import OrderArticle from "../../../../global/utils/models/order/OrderArticle.ts";

export default function OptionGroupContent({
  orderArticle,
  onChange,
  orderOptionGroup,
}: {
  orderArticle: OrderArticle;
  orderOptionGroup: OrderOptionGroup;
  onChange: (orderOptionGroup: OrderOptionGroup) => void;
}) {
  const optionGroup = orderOptionGroup.optionGroup;

  const { type } = useOptionGroup(orderOptionGroup, orderArticle.article);
  const containerRef = useScrollShadows();
  const { translate } = useOptionTranslations();

  if (orderOptionGroup.orderArticles.length === 0) {
    return null;
  }

  return (
    <Box sx={{ width: 1, height: 1, overflow: "hidden" }}>
      <Box
        sx={{ width: 1, height: 1, overflowY: "auto" }}
        className={clsx("ScrollBar-wide", "JamezzScroll")}
        ref={containerRef}
      >
        <div className="shadow shadow--top" style={{ position: "fixed" }} />
        <Divider />
        <Box sx={{ display: "inline-flex" }}>
          <Typography sx={{ paddingX: 4 }} fontSize={"1.4em"}>
            {translate(optionGroup.name, OptionTranslationType.OptionGroup)}
          </Typography>
        </Box>
        <OptionGroupHeaderText optionGroup={optionGroup} fontSize={"0.95em"} article={orderArticle.article} />
        <Box sx={{ display: "flex", flexWrap: "wrap", width: 1, justifyContent: "center" }}>
          {type === Type.Radio ? (
            <OptionGroupRadio
              orderOptionGroup={orderOptionGroup}
              onChange={(orderOptionGroup) => onChange(orderOptionGroup)}
            />
          ) : null}
          {type === Type.Optional ? (
            <OptionGroupOptional
              orderOptionGroup={orderOptionGroup}
              onChange={(orderOptionGroup) => onChange(orderOptionGroup)}
            />
          ) : null}
          {type === Type.Countable ? (
            <OptionGroupCountable
              orderOptionGroup={orderOptionGroup}
              onChange={(orderOptionGroup) => onChange(orderOptionGroup)}
            />
          ) : null}
        </Box>
        <div className="shadow shadow--bottom"></div>
      </Box>
    </Box>
  );
}
