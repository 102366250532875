import { useCallback, useEffect, useRef } from "react";
import { debounce } from "lodash";

export enum Orientation {
  VERTICAL,
  HORIZONTAL,
}
const scrollGradientHeight = 150;
export default function useScrollShadows(orientation: Orientation = Orientation.VERTICAL) {
  const container = useRef<HTMLDivElement>(null);

  const onScroll = useCallback(
    debounce(() => {
      if (container.current) {
        const shadowTop: HTMLElement | null = container.current.querySelector(".shadow--top");
        const shadowBottom: HTMLElement | null = container.current.querySelector(".shadow--bottom");

        if (shadowTop && shadowBottom) {
          const totalScrollHeight = container.current.scrollHeight - container.current.offsetHeight;
          if (totalScrollHeight == 0) {
            shadowTop.style.opacity = String(0);
            shadowBottom.style.opacity = String(0);
          } else {
            shadowTop.style.opacity = String(Math.min(container.current.scrollTop / scrollGradientHeight, 1));
            shadowBottom.style.opacity = String(
              Math.min((totalScrollHeight - container.current.scrollTop) / scrollGradientHeight, 1)
            );
          }
        }
      }
    }),
    [container.current]
  );

  const onResize = useCallback(
    debounce(() => {
      if (container.current) {
        const totalScrollHeight = container.current.scrollHeight - container.current.offsetHeight;

        if (totalScrollHeight === 0) {
          const shadowBottom: HTMLElement | null = container.current.querySelector(".shadow--bottom");
          if (shadowBottom) {
            shadowBottom.style.opacity = "0";
          }
        }
      }
    }),
    [container.current]
  );

  useEffect(() => {
    const element = container.current;
    let observer: MutationObserver | null = null;
    if (element) {
      const totalScrollHeight = element.scrollHeight - element.offsetHeight;

      if (totalScrollHeight === 0) {
        const shadowBottom: HTMLElement | null = element.querySelector(".shadow--bottom");
        if (shadowBottom) {
          shadowBottom.style.opacity = "0";
        }
      }

      element.addEventListener("scroll", onScroll);
      element.addEventListener("resize", onResize);
      observer = new MutationObserver(onResize);
      observer.observe(element, { subtree: true, childList: true });
    }
    return () => {
      element?.removeEventListener("resize", onResize);
      element?.removeEventListener("scroll", onScroll);
      observer?.disconnect();
    };
  }, [container, onResize, onScroll]);

  return container;
}
