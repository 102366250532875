import { useCustomTexts } from "../../../global/utils/useCustomTexts";
import { PiggyScanState, usePiggyScanner } from "../../utils/piggy/usePiggyScanner";
import store, { useAppDispatch, useAppSelector } from "../../../global/utils/redux/store";
import { useCountdown } from "../../../global/utils/hooks/useCountdown";
import { useEffect } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";
import { LoadingOverlay } from "../../../global/components/LoadingOverlay";
import FormattedMessageJamezz from "../../../global/components/FormattedMessageJamezz";
import Zoom from "@mui/material/Zoom";
import { piggyLoyaltyDisabled, userMinimizedPiggyScanDialog } from "../../../global/utils/redux/piggySlice";

export function PiggyScanCardDialog() {
  const piggyEnabled = useAppSelector((state) => state.global.salesarea.piggy.loyalty.enabled);
  const open = !useAppSelector((state) => state.piggy.piggyScanCardDialogMinimized);
  const ct = useCustomTexts(["loyalty card scan - description", "loyalty card scan - title"]);
  const { cardScanState, turnOff, turnOn } = usePiggyScanner();
  const dispatch = useAppDispatch();
  const { timer: closeTimer, startCountDown, finished: timerFinished } = useCountdown(4000);

  useEffect(() => {
    if (cardScanState === PiggyScanState.SUCCEEDED) {
      startCountDown().then(() => {
        turnOff();
      });
    }
  }, [cardScanState, startCountDown, turnOff]);

  useEffect(() => {
    if (!open) {
      turnOff();
    }
    if (open && !timerFinished) {
      turnOn();
    }
  }, [closeTimer, open, timerFinished, turnOff, turnOn]);

  useEffect(() => {
    if (!piggyEnabled) {
      store.dispatch(piggyLoyaltyDisabled());
    }
  }, [dispatch, piggyEnabled]);

  return (
    <Dialog open={open && !timerFinished} className="JS-PiggyScanDialog-Root" maxWidth={"md"}>
      <LoadingOverlay isLoading={cardScanState === PiggyScanState.LOADING}>
        <>
          <DialogTitle
            className="JS-PiggyScanDialog-Title"
            sx={{ mx: 18, mt: 8, textAlign: "center", lineHeight: 1.2 }}
          >
            {cardScanState === PiggyScanState.SUCCEEDED ? (
              <Typography fontSize="1.4em" fontWeight="bold" sx={{ lineHeight: 1.2 }}>
                <FormattedMessageJamezz id="Success" />
              </Typography>
            ) : cardScanState === PiggyScanState.FAILED ? (
              <Typography fontSize="1.4em" fontWeight="bold" sx={{ lineHeight: 1.2 }}>
                <FormattedMessageJamezz id="Oops" />
              </Typography>
            ) : (
              ct["loyalty card scan - title"] ?? <FormattedMessageJamezz id="Piggy.ScanLoyaltyCardPage.header" />
            )}
          </DialogTitle>
          <DialogContent data-cy="piggy-scan-card-dialog-body" sx={{ mx: 6 }}>
            {cardScanState === PiggyScanState.UNINITIALIZED || cardScanState === PiggyScanState.LOADING ? (
              <Typography
                variant="h2"
                fontSize="1em"
                className="JS-PiggyScanDialog-Body"
                sx={{ mt: 4, textAlign: "center" }}
              >
                {ct["loyalty card scan - description"] ?? (
                  <FormattedMessageJamezz id="Piggy.ScanLoyaltyCardPage.description" />
                )}
              </Typography>
            ) : null}
            <Zoom in={cardScanState === PiggyScanState.SUCCEEDED} mountOnEnter unmountOnExit>
              <Typography
                fontSize="1.2em"
                sx={{ mt: 4, textAlign: "center", lineHeight: 1.2 }}
                className="JS-PiggyScanDialog-Body"
              >
                <FormattedMessageJamezz id="Piggy.ScanLoyaltyCardPage.cardScannedSuccessfully" />
              </Typography>
            </Zoom>
            <Zoom in={cardScanState === PiggyScanState.FAILED} mountOnEnter unmountOnExit>
              <Typography
                fontSize="1.2em"
                sx={{ mt: 4, textAlign: "center", lineHeight: 1.2 }}
                className="JS-PiggyScanDialog-Body"
              >
                <FormattedMessageJamezz id="Piggy.ScanLoyaltyCardPage.cardScanFailure" />
              </Typography>
            </Zoom>
          </DialogContent>
          <DialogActions sx={{ mb: 4, mx: 4, mt: 4 }}>
            <Button
              className="JS-PiggyScanDialog-LaterButton"
              variant="contained"
              onClick={() => {
                dispatch(userMinimizedPiggyScanDialog());
              }}
              data-cy="piggy-scan-card-dialog-dismiss-btn"
              sx={{ borderRadius: "16px" }}
            >
              {cardScanState !== PiggyScanState.SUCCEEDED ? (
                <FormattedMessageJamezz id="Not now" />
              ) : (
                <FormattedMessageJamezz
                  id="Piggy.ScanLoyaltyCardDialog.closingIn"
                  values={{
                    seconds: Math.ceil(closeTimer / 1000),
                  }}
                />
              )}
            </Button>
          </DialogActions>
        </>
      </LoadingOverlay>
    </Dialog>
  );
}
