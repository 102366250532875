import { useCallback, useState } from "react";
import { useAppSelector } from "../redux/store";
import _ from "lodash";
import { useLazyGetLoggedinUserQuery } from "../redux/api/piggyAuthApi.ts";
import { useLazyFetchPiggyTransactionHistoryQuery } from "../redux/api/piggyApi";

function piggyDateToUnixTimestamp(date: string): number | null {
  const dateObj = new Date(date);
  if (dateObj.toString() === "Invalid Date") {
    return null;
  }
  return dateObj.getTime() / 1000;
}

export enum NewPiggyPointsStatus {
  Loading,
  Success,
  MissedOutOnPoints,
  Error,
  PiggyNotEnabled,
}

function wait(seconds: number) {
  return new Promise((resolve) => {
    window.setTimeout(resolve, seconds);
  });
}

export function useShowNewPiggyPoints() {
  const piggyEnabled = useAppSelector((state) => state.global.salesarea.piggy.loyalty.enabled);
  const [newlyReceivedPoints, setNewlyReceivedPoints] = useState<number | null>(null);
  const [status, setStatus] = useState<NewPiggyPointsStatus>(NewPiggyPointsStatus.Loading);

  const [fetchUser] = useLazyGetLoggedinUserQuery();
  const [fetchTransactionHistory] = useLazyFetchPiggyTransactionHistoryQuery();

  const onAfterPayment = useCallback(
    piggyEnabled
      ? _.once(async (timestamp: number) => {
          const userResult = await fetchUser().unwrap();
          // the back-end needs to send a request to piggy to update the transaction history.
          // alternative is to use websocket pingy, probably better than an explicit wait, this is a bit random if it'll work or not
          if (userResult.data.has_session) {
            for (let pollAttempt = 0; pollAttempt < 3; pollAttempt += 1) {
              await wait(1250);
              const transaction_history = await fetchTransactionHistory().unwrap();

              if ("message" in transaction_history) {
                setStatus(NewPiggyPointsStatus.Error);
                return;
              }

              const result = transaction_history.data.find((transaction) => {
                // shouldn't matter, but give a little grace period to the order
                const grace_seconds = 3;
                const piggyTimestamp = piggyDateToUnixTimestamp(transaction.created_at);
                if (piggyTimestamp === null) return false;
                return piggyTimestamp > timestamp - grace_seconds;
              });

              if (result) {
                setNewlyReceivedPoints(result.credits);
                setStatus(NewPiggyPointsStatus.Success);
                return;
              }

              setStatus(NewPiggyPointsStatus.Error);
            }
          } else {
            setStatus(NewPiggyPointsStatus.MissedOutOnPoints);
          }
        })
      : () => {},
    []
  );

  return {
    onAfterPayment,
    newlyReceivedPoints,
    status: piggyEnabled ? status : NewPiggyPointsStatus.PiggyNotEnabled,
  };
}
