import { ButtonBase, Collapse, Divider, Radio } from "@mui/material";

import OptionGroupContent from "../OptionGroups/OptionGroupContent";
import OrderOptionGroup from "../../../../global/utils/models/order/OrderOptionGroup";
import OrderArticle from "../../../../global/utils/models/order/OrderArticle";
import OptionItemNameAndPrice from "./OptionItemNameAndPrice";
import useMenuTagsForArticle from "../../../../global/utils/hooks/useMenuTagsForArticle";
import Box from "@mui/material/Box";
import MenuTag from "../../../../global/utils/models/menu/MenuTag";
import MenuTagView from "../../../components/MenuTagView";
import useSalesareaType, { SalesareaType } from "../../../../global/utils/hooks/useSalesareaType";
import clsx from "clsx";
import { useIntl } from "react-intl";
import { Info } from "@mui/icons-material";
import JTooltip from "../../../../kiosk/components/JamezzMUI/JTooltip";
import { getPropertyOfOptionGroup } from "../../../../global/utils/models/menu/OptionGroup.ts";

interface Props {
  orderArticle: OrderArticle;
  value: string;
  onChange: (value: string) => void;
  onChangeOrderArticle: (orderArticle: OrderArticle) => void;
  index: number;
  orderOptionGroup: OrderOptionGroup;
  isFirstOptionInGroup: boolean;
  isLastOptionInGroup: boolean;
}

function OptionItemRadio(props: Props) {
  const menuTags = useMenuTagsForArticle(props.orderArticle.article);
  const salesareaType = useSalesareaType();
  const isNotAvailable = props.orderArticle.article.isNotAvailable || props.orderArticle.article.isOutOfStock;

  const intl = useIntl();

  return (
    <>
      {!props.isFirstOptionInGroup && (
        <Box sx={{ marginX: 2 }}>
          <Divider sx={{ width: 1, marginY: 1 }} />
        </Box>
      )}
      <JTooltip title={isNotAvailable ? intl.formatMessage({ id: "OptionItem.notAvailable" }) : ""}>
        <ButtonBase
          component={"div"}
          sx={{
            width: 1,
            borderRadius: 4,
            display: "flex",
            flexDirection: "column",
            paddingLeft: 2,
            marginTop: props.isFirstOptionInGroup ? 1 : undefined,
            marginBottom: props.isLastOptionInGroup ? 1 : undefined,
            opacity: isNotAvailable ? 0.5 : "auto",
          }}
          disableTouchRipple={isNotAvailable}
          onClick={() => {
            if (isNotAvailable) return;
            props.onChange(props.orderArticle.article.id);
          }}
        >
          <Box sx={{ display: "flex", width: 1, flex: 1 }}>
            <OptionItemNameAndPrice orderArticle={props.orderArticle} />
            <Box
              className={"JS-OptionItemRadio-RadioWrapper"}
              display={"flex"}
              alignItems={"center"}
              sx={{ visibility: salesareaType === SalesareaType.CATALOG ? "hidden" : null, marginRight: 2 }}
            >
              {isNotAvailable ? (
                <Info
                  sx={{
                    marginRight: "9px",
                  }}
                />
              ) : (
                <Radio
                  className={clsx(
                    "JS-OptionItemRadio-Radio",
                    props.value === props.orderArticle.article.id ? "JS-OptionItemRadio-Radio-checked" : null
                  )}
                  value={props.orderArticle.article.id}
                  color={"primary"}
                  checked={props.value === props.orderArticle.article.id}
                />
              )}
            </Box>
          </Box>
        </ButtonBase>
      </JTooltip>
      {menuTags?.length > 0 && (
        <Box sx={{ display: "flex", flexWrap: "wrap", alignSelf: "stretch", marginLeft: 2 }}>
          {menuTags.map((tag: MenuTag) => {
            return <MenuTagView key={tag.id} menuTag={tag} width={24} />;
          })}
        </Box>
      )}

      <Collapse in={props.value === props.orderArticle.article.id} sx={{ width: 1 }}>
        {props.orderArticle.orderOptionGroups
          .filter(
            (orderOptionGroup) =>
              !props.orderOptionGroup.optionGroup.isUpsell ||
              getPropertyOfOptionGroup(orderOptionGroup.optionGroup, props.orderArticle.article, "minCount") >= 1
          )
          .map((orderOptionGroup, index) => (
            <Box key={index + "-" + orderOptionGroup.id} sx={{ marginLeft: 2, marginBottom: 1 }}>
              <OptionGroupContent
                orderArticle={props.orderArticle}
                orderOptionGroup={orderOptionGroup}
                onChange={(orderOptionGroup) => {
                  const copy: OrderArticle = JSON.parse(JSON.stringify(props.orderArticle));
                  copy.orderOptionGroups[index] = orderOptionGroup;
                  props.onChangeOrderArticle(copy);
                }}
              />
              {/*<Divider sx={{}} />*/}
            </Box>
          ))}
      </Collapse>
    </>
  );
}

export default OptionItemRadio;
