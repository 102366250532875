import Box from "@mui/material/Box";
import { Button, Fade, Step, StepLabel, Stepper, Typography } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { FormProvider } from "react-hook-form";
import CloseDialogButton from "../../components/CloseDialogButton";
import FormattedMessageJamezz from "../../../global/components/FormattedMessageJamezz";
import BackDialogButton from "../../components/BackDialogButton";
import { useAppSelector } from "../../../global/utils/redux/store";
import { Swiper as SwiperType } from "swiper/types";
import { Swiper, SwiperSlide } from "swiper/react";
import { useDialog } from "../../../global/utils/dialog/DialogProvider";
import { useCustomerOrderInputPages } from "../../utils/useCustomerOrderInputPages";
import CustomFieldContent from "../../../qr/pages/CheckoutPage/CustomFieldContent";
import { BackgroundUsingMediaUrl } from "../../hooks/useBackgroundMediaUrl.tsx";
import { selectCustomFieldBackgrounds } from "../../../global/utils/redux/selectors/selectCustomFieldBackgrounds.ts";
import PiggyPopupAtCheckoutPage from "./PiggyPopupAtCheckoutPage.tsx";
import { CustomFieldsStepIcon } from "./CustomFieldsStepIcon.tsx";

function CheckoutPage() {
  const liveEditEnabled = useAppSelector((state) => state.dev.liveEditModeEnabled);
  const payDirect = useAppSelector((state) => state.global.salesarea.payDirect);
  const [swiperRef, setSwiperRef] = useState<SwiperType | null>(null);

  const {
    pages,
    pageIndex,
    canGoNext,
    canGoPrev,
    pageStatus,
    goToPage,
    goNext,
    goBack,
    isFilledCorrectly,
    useFormMethods,
    hasNext,
    hasPrev,
    isValid,
  } = useCustomerOrderInputPages();
  const { closeDialog } = useDialog();
  const customFieldBackgrounds = useAppSelector(selectCustomFieldBackgrounds);
  const defaultBackground = useAppSelector(
    (state) => state.global.salesarea?.media_urls?.extra_settings__kioskv5_background_of_checkout_page?.[0]
  );
  const backgroundCustomField = useMemo(() => {
    const mediaId = pages[pageIndex]?.customField?.media_id;
    return mediaId != null ? customFieldBackgrounds[mediaId]?.[0] : undefined;
  }, [pages, pageIndex, customFieldBackgrounds]);

  useEffect(() => {
    if (swiperRef && swiperRef.activeIndex !== pageIndex) {
      swiperRef.slideTo(pageIndex, 200);
    }
  }, [pageIndex, swiperRef]);

  return (
    <FormProvider {...useFormMethods}>
      <form style={{ height: "100%" }}>
        <BackDialogButton />
        <CloseDialogButton
          onClose={() => {
            closeDialog();
          }}
        />
        <PiggyPopupAtCheckoutPage />
        <BackgroundUsingMediaUrl
          mediaUrl={backgroundCustomField ?? defaultBackground}
          className={"JS-CheckoutPage-Root"}
          sx={{
            display: "flex",
            width: 1,
            justifyContent: "center",
            height: 1,
            position: "relative",
          }}
          fallbackBackgroundColor={"primary.light"}
          relativeTo={"window"}
        >
          <Box
            sx={{
              width: 1,
              height: 1,
              position: "relative",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              padding: 4,
            }}
          >
            <Typography
              className="JS-CheckoutPage-Header-CheckoutOrOrder"
              variant={"h5"}
              sx={{ fontStyle: "italic", fontWeight: 800 }}
            >
              {payDirect ? <FormattedMessageJamezz id={"Checkout"} /> : <FormattedMessageJamezz id={"Order"} />}
            </Typography>

            {/*height accommodates for the pop-up keyboard*/}
            <Box
              className={"JS-CheckoutPage-CustomField-Content-Wrapper"}
              sx={{
                padding: 4,
                width: "100%",
                height: "920px",
                mt: 8,
                gap: 4,
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                transition: "height ease-out 200ms",
                transitionDelay: "220ms",
              }}
            >
              <Stepper alternativeLabel={true} activeStep={pageIndex} connector={null} sx={{ width: "100%" }}>
                {pages.map((page, idx) => {
                  const { name } = page;

                  const status = pageStatus(idx);
                  const isOnReviewOrderPage = idx === pages.length - 1;
                  const completed = status.isFilledCorrectly && !isOnReviewOrderPage;
                  return (
                    <Step
                      key={name}
                      completed={completed}
                      data-cy="custom-fields-step"
                      sx={{
                        p: 1,
                        "& .MuiStepIcon-root": {
                          width: "50px",
                          height: "50px",
                        },
                        "& .MuiStepIcon-text": {
                          fontSize: "16px !important",
                        },
                      }}
                      onClick={() => {
                        if (status.canGoToPage && isFilledCorrectly) {
                          goToPage(idx);
                        }
                      }}
                    >
                      <StepLabel StepIconComponent={CustomFieldsStepIcon}>
                        <Typography fontSize={"32px"} className="JS-CustomFields-Step-Label">
                          {page.name}
                        </Typography>
                      </StepLabel>
                    </Step>
                  );
                })}
              </Stepper>
              <Box sx={{ paddingX: 1, height: "100%" }}>
                <Swiper
                  allowTouchMove={false}
                  onSwiper={(swiper) => {
                    setSwiperRef(swiper);
                  }}
                  style={{ height: "100%" }}
                >
                  {pages.map((page, index) => (
                    <SwiperSlide key={page.name} style={{ height: "100%" }}>
                      {pageIndex >= index ? (
                        page.Content ? (
                          <page.Content isValid={isValid} onShouldSlideNext={() => goNext()} />
                        ) : page.customField ? (
                          <Box sx={{ display: "flex", height: "100%", alignItems: "center" }}>
                            <CustomFieldContent key={page.customField.name} customField={page.customField} />
                          </Box>
                        ) : null
                      ) : null}
                    </SwiperSlide>
                  ))}
                </Swiper>
              </Box>
              <Box sx={{ width: "100%", display: "grid", gap: 4, gridTemplateColumns: "1fr 1fr" }}>
                <Fade in={hasPrev}>
                  <Button
                    data-cy="btn-customfields-stepper-previous"
                    sx={{
                      backgroundColor: "#a04043",
                      borderRadius: 16,
                      fontSize: 42,
                      color: "white",
                      textTransform: "uppercase",
                      fontWeight: 800,
                      padding: 3,
                    }}
                    className={`JS-CustomFields-PreviousButton JS-CustomFields-PreviousNextButtons${
                      !canGoPrev
                        ? " JS-CustomFields-PreviousButton-Disabled JS-CustomFields-PreviousNextButtons-Disabled"
                        : ""
                    }`}
                    disabled={!canGoPrev}
                    onClick={() => {
                      goBack();
                    }}
                  >
                    <FormattedMessageJamezz id="Previous" />
                  </Button>
                </Fade>
                <Fade in={hasNext}>
                  <Button
                    data-cy="btn-customfields-stepper-next"
                    sx={{
                      backgroundColor: "#a04043",
                      borderRadius: 16,
                      fontSize: 42,
                      color: "white",
                      textTransform: "uppercase",
                      fontWeight: 800,
                      padding: 3,
                      ...(liveEditEnabled ? { pointerEvents: "initial !important" } : {}),
                    }}
                    disabled={!canGoNext}
                    className={`JS-CustomFields-NextButton JS-CustomFields-PreviousNextButtons${
                      !canGoNext
                        ? " JS-CustomFields-NextButton-Disabled JS-CustomFields-PreviousNextButtons-Disabled"
                        : ""
                    }`}
                    onClick={() => {
                      goNext();
                    }}
                  >
                    <FormattedMessageJamezz id="Next" />
                  </Button>
                </Fade>
              </Box>
            </Box>
          </Box>
        </BackgroundUsingMediaUrl>
      </form>
    </FormProvider>
  );
}

export default CheckoutPage;
