import { Language } from "../../types/shared/language";
import { useAppSelector } from "./redux/store.tsx";
import { selectAppLanguage } from "../../kiosk/components/LanguageSelector/useLanguage.ts";
import { useCallback } from "react";
import CustomField, { TranslatableText } from "../../types/shared/CustomField.ts";

export const languageTranslations: { [lang in Language]: string } = {
  ar: "عربي",
  ca: "Català",
  da: "Dansk",
  es: "Español",
  hr: "Hrvatski",
  it: "Italiano",
  ja: "日本語",
  sv: "Svenska",
  zh: "中国人",
  nl: "Nederlands",
  en: "English",
  de: "Deutsch",
  fr: "Français",
};
type PickByType<T extends object, U> = { [k in keyof T as U extends T[k] ? k : never]: k };

export function useCustomfieldTranslatableText() {
  const selectedLanguage = useAppSelector(selectAppLanguage);
  const fallbackLanguage = useAppSelector((state) => state.global.salesarea.applicationLanguage);

  return useCallback(
    (customField: CustomField | undefined, key: keyof PickByType<CustomField, TranslatableText>) => {
      const field = customField?.[key];
      if (typeof field === "string") {
        return customField?.[key];
      }
      return field?.[selectedLanguage] ?? field?.[fallbackLanguage];
    },
    [fallbackLanguage, selectedLanguage]
  );
}
