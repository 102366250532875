import { createContext, Dispatch, SetStateAction, useContext, useEffect, useState } from "react";
import { Box, Button, TextField, Typography } from "@mui/material";
import FormattedMessageJamezz from "../../../global/components/FormattedMessageJamezz";
import {
  useCreateAccountMutation,
  useSendEmailMutation,
  useSendVerificationCodeMutation,
} from "../../../global/utils/redux/api/piggyAuthApi";
import { toast } from "react-toastify";
import { useIntl } from "react-intl";
import { SimpleStateMachine } from "../../../global/utils/simpleStateMachine";
import { useCustomTexts } from "../../../global/utils/useCustomTexts";
import { postAnalyticsEvent } from "../../../global/utils/analytics/useAnalytics";
import LoadingButton from "@mui/lab/LoadingButton";
import { assertIsPiggyErrorMessage } from "../../../global/utils/piggy/usePiggyRewards";
import { piggyErrorCodes } from "../../../types/shared/piggy";

type PiggyStateMachine = SimpleStateMachine<{ component: JSX.Element }>;

function ConfirmNewAccountCreation() {
  const { email, next } = useContext(LoginContext);
  const [
    triggerCreateAccount,
    { data: accountCreationResponse, isSuccess, isError, isLoading },
  ] = useCreateAccountMutation();
  const intl = useIntl();
  const [sendVerificationEmail] = useSendEmailMutation();
  const customTexts = useCustomTexts(["register page - description"]);

  useEffect(() => {
    let timeout: number;
    if (isSuccess && accountCreationResponse) {
      // empty response = success
      if ("uuid" in accountCreationResponse.data && typeof accountCreationResponse.data.uuid === "string") {
        sendVerificationEmail(email);
        toast.success(
          intl.formatMessage({
            id: "Loyalty account created successfully",
          })
        );
        next("verify_login");
      } else if ("contact_email" in accountCreationResponse.data) {
        const error_message = accountCreationResponse.data.piggy_response.message;
        if (error_message.includes("already exists")) {
          /*
           * should not really happen. If the account exists,
           * then our piggy API should not have redirected us
           * to "verify code" rather than "create account".
           * in the strange case this does happen, assume intent of logging in.
           */
          console.warn("unexpected situation, piggy account already exists");
          sendVerificationEmail(email);
          next("verify_login");
        }
        toast.warn(
          intl.formatMessage({
            id: "Unexpected error.",
          })
        );
      }
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [accountCreationResponse, email, intl, isSuccess, next, sendVerificationEmail]);

  useEffect(() => {
    if (isError) {
      toast.warn("Communication error, please try again later.");
    }
  }, [isError]);

  return (
    <>
      <Box sx={{ mb: 2 }}>
        <Typography variant="body1">
          {customTexts["register page - description"] ?? (
            <FormattedMessageJamezz
              id="Piggy.auth.message.unknown_email"
              values={{
                email,
                b: (chunks) => <b>{chunks}</b>,
              }}
            />
          )}
        </Typography>
      </Box>

      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr",
          gridGap: "16px",
        }}
      >
        <LoadingButton
          className="JS-PiggyLogin-Button"
          variant="contained"
          data-cy={"piggy-submit-create-account"}
          loading={isLoading}
          onClick={() => {
            postAnalyticsEvent({
              category: "ConfirmNewAccountCreation",
              action: "triggerCreateAccount",
            });
            triggerCreateAccount(email);
          }}
        >
          <FormattedMessageJamezz id="Piggy.auth.btn.create_account"></FormattedMessageJamezz>
        </LoadingButton>
        <Button
          className="JS-PiggyLogin-Button"
          variant="contained"
          disabled={isLoading}
          onClick={() => {
            next("input_email");
          }}
        >
          <FormattedMessageJamezz id="Cancel" />
        </Button>
      </Box>
    </>
  );
}

function PiggyInputEmail() {
  const [sendEmail, { data: piggyEmailResponse, isSuccess, isError, isLoading, error }] = useSendEmailMutation();
  const { email, setEmail, next } = useContext(LoginContext);
  const intl = useIntl();

  useEffect(() => {
    if (piggyEmailResponse && isSuccess) {
      if ("type" in piggyEmailResponse.data.piggy_response) {
        setEmail(piggyEmailResponse.data.contact_email);
        next("verify_login");
      } else {
        toast.warn(intl.formatMessage({ id: "Piggy.messages.error.unknownApiResponse" }));
      }
    }
  }, [isSuccess, next, piggyEmailResponse, setEmail]);

  useEffect(() => {
    if (isError && error) {
      try {
        assertIsPiggyErrorMessage(error);
        if (error.data.context.code === piggyErrorCodes["contact not found"]) {
          next("confirm_account_creation");
        } else {
          toast.warn(intl.formatMessage({ id: "Piggy.messages.error.unknownApiResponse" }));
        }
      } catch (e) {
        toast.warn(intl.formatMessage({ id: "Piggy.messages.error.unknownApiResponse" }));
      }
    }
  }, [isError, error, intl, next]);

  return (
    <form
      className="JS-PiggyLogin-Root"
      style={{
        display: "flex",
        gap: "8px",
      }}
      onSubmit={(e) => {
        postAnalyticsEvent({
          category: "PiggyInputEmail",
          action: "sendEmail",
        });
        e.preventDefault();
        if (email) {
          sendEmail(email);
        }
      }}
    >
      <TextField
        className="JS-PiggyLogin-Input"
        sx={{ flexGrow: 1 }}
        autoFocus={true}
        inputProps={{
          "data-cy": "piggy-input-email",
        }}
        fullWidth
        name="piggy-email"
        required={true}
        type="email"
        value={email}
        onChange={({ target: { value } }) => {
          setEmail(value);
        }}
        label={
          <span className="JS-PiggyLogin-Input-Label">
            <FormattedMessageJamezz id={"E-mail address"} />
          </span>
        }
        size={"small"}
      />

      <LoadingButton
        loading={isLoading}
        data-cy="piggy-submit-email"
        variant={"contained"}
        color={"primary"}
        type="submit"
        className="JS-PiggyLogin-Button"
      >
        <FormattedMessageJamezz id={"Send"} />
      </LoadingButton>
    </form>
  );
}

function VerifyLogin() {
  const [verificationCode, setVerificationCode] = useState("");
  const [
    sendVerificationCode,
    { data: verificationResponse, isSuccess: verificationResponseAvailable, isLoading, isError, error },
  ] = useSendVerificationCodeMutation();
  const { email } = useContext(LoginContext);
  const intl = useIntl();
  const customTexts = useCustomTexts(["login page - verification code name"]);

  useEffect(() => {
    if (verificationResponseAvailable && verificationResponse) {
      // means success
      if ("has_session" in verificationResponse.data) {
        /**
         * note, the actual logging in happens in the background.
         * redux stores the response in its local cache, which is how it is supposed to work.
         * and if it succeeds, it will log the user in, in the rtk-query piggy api itself.
         * @see piggyApi.ts
         * @see PiggyDialog.tsx isLoggedIn
         */
        toast.success(
          intl.formatMessage({
            id: "Piggy.auth.message.logged_in",
          })
        );
      } else {
        toast.warn("Verification code incorrect, please try again.");
      }
    }
  }, [email, intl, verificationResponse, verificationResponseAvailable]);

  useEffect(() => {
    if (isError) {
      try {
        assertIsPiggyErrorMessage(error);
        if (error.data.context.code === piggyErrorCodes["verification code incorrect"]) {
          toast.warn(intl.formatMessage({ id: "Piggy.messages.warning.verificationCodeIncorrect" }));
        }
      } catch (e) {
        toast.warn(intl.formatMessage({ id: "Piggy.messages.error.unknownApiResponse" }));
      }
    }
  }, [error, intl, isError]);

  return (
    <Box>
      <Typography variant="body1" sx={{ pb: 2 }}>
        <FormattedMessageJamezz id="Piggy.auth.message.verify_mail_sent" values={{ email }} />
      </Typography>
      <form
        action=""
        style={{
          display: "flex",
          gap: "8px",
        }}
        onSubmit={(e) => {
          e.preventDefault();
          postAnalyticsEvent({
            category: "VerifyLogin",
            action: "sendVerificationCode",
          });
          sendVerificationCode({
            code: verificationCode,
            contact_email: email,
          });
        }}
      >
        <TextField
          className="JS-PiggyLogin-Input"
          sx={{ flexGrow: 1 }}
          autoFocus={true}
          inputProps={{
            "data-cy": "piggy-input-verification",
          }}
          required
          fullWidth
          size={"small"}
          value={verificationCode}
          onChange={({ currentTarget: { value } }) => {
            setVerificationCode(value);
          }}
          label={
            <span className="JS-PiggyLogin-Input-Label">
              {customTexts["login page - verification code name"] ?? (
                <FormattedMessageJamezz id="Piggy.auth.input.verification_code" />
              )}
            </span>
          }
        />
        <LoadingButton
          className="JS-PiggyLogin-Button"
          loading={isLoading}
          data-cy="piggy-submit-verification"
          variant="contained"
          color="primary"
          type="submit"
        >
          <FormattedMessageJamezz id="Send" />
        </LoadingButton>
      </form>
    </Box>
  );
}

const loginStateMachine: PiggyStateMachine = {
  start: "input_email",
  states: {
    input_email: {
      transitions: ["confirm_account_creation", "verify_login"],
      component: <PiggyInputEmail />,
    },
    confirm_account_creation: {
      transitions: ["verify_login", "input_email"],
      component: <ConfirmNewAccountCreation />,
    },
    verify_login: {
      transitions: ["input_email", "verify_login"],
      component: <VerifyLogin />,
    },
  },
};

const LoginContext = createContext<{
  next: (step: string) => void;
  email: string;
  setEmail: Dispatch<SetStateAction<string>>;
}>({
  next: () => {},
  email: "",
  setEmail: () => {},
});

function MultiStepLoginProcess({ steps }: { steps: PiggyStateMachine }) {
  const [currentStep, setCurrentStep] = useState<string>(steps.start);
  const currentComponent = steps.states[currentStep].component;
  const [email, setEmail] = useState<string>("");

  return (
    <LoginContext.Provider
      value={{
        email,
        setEmail,
        next: (step: string) => {
          const allowedTransitions = steps.states[currentStep].transitions ?? [];
          const isAllowedToTransition = allowedTransitions.find((t) => t === step);

          if (isAllowedToTransition) {
            console.log(`transitioning from ${currentStep} to ${step}`);
            setCurrentStep(step);
          } else {
            console.error(`invalid transition request: from ${currentStep} to ${step}`);
          }
        },
      }}
    >
      {currentComponent}
    </LoginContext.Provider>
  );
}

export default function PiggyLogin() {
  const customTexts = useCustomTexts(["login page - description"]);
  return (
    <Typography variant="h5">
      <FormattedMessageJamezz id="Piggy.auth.btn.login_or_register" />
      <Typography variant="body1">
        {customTexts["login page - description"] ?? <FormattedMessageJamezz id="Piggy.text.loyalty_explanation" />}
      </Typography>
      <Box sx={{ marginTop: 4 }}>
        <MultiStepLoginProcess steps={loginStateMachine} />
      </Box>
    </Typography>
  );
}
