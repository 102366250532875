import Article from "../../../../utils/models/menu/Article.ts";
import store from "../../../../utils/redux/store.tsx";
import {
  ActiveArrangement,
  findArrangementGroupForArticle,
  findArrangementGroupsForArticle,
} from "../../../../utils/redux/arrangements/arrangementsSlice.tsx";
import { selectArticleArticlegroupsMap } from "../../../../utils/redux/selectors/selectArticleArticlegroupsMap.ts";
import { DialogOption } from "../../../../utils/dialog/DialogProvider.tsx";
import { ArrangementLimitationCheck, CheckResult } from "../../ArrangementLimitations.tsx";
import ConfirmMaxRoundItemOrderPopup from "./ConfirmMaxRoundItemOrderPopup.tsx";
import SlideUpTransition from "../../../SlideUpTransition.tsx";
import OrderArticle from "../../../../utils/models/order/OrderArticle.ts";
import { IntlShape } from "react-intl";
import { selectActiveArrangement } from "../../../../utils/redux/arrangements/selectActiveArrangement.tsx";
import { selectTableState } from "../../../../utils/redux/api/selectTableState.ts";

function maxRoundsItemCanBeOrdered(article: Article): number {
  const state = store.getState();
  const activeArrangement = selectActiveArrangement(state);

  if (activeArrangement == null) {
    return 0;
  }
  const groups = findArrangementGroupsForArticle(article, activeArrangement.groups);

  /* in the case where the item is part of more than one active group,
   * find the "strictest" rule.
   * For instance, if you have two groups. One without a limit and one with limit of 1, the limit should be 1:
   *     [infinite, 1] => 1
   */
  return groups.reduce(function (lowest, group) {
    const groupMaximum = group.maximum_order_rounds === 0 ? Infinity : group.maximum_order_rounds;
    if (groupMaximum < lowest) {
      return groupMaximum;
    }
    return lowest;
  }, Infinity);
}

function numberOfRoundsItemHasBeenOrdered(article: Article): number {
  const state = store.getState();
  const rounds = selectTableState(state)?.data?.data.ticket?.ticket_orders ?? [];
  const activeArrangement = selectActiveArrangement(state);
  const articleArticlegroupsMap = selectArticleArticlegroupsMap(state);

  if (!activeArrangement) return 0;
  const g1 = findArrangementGroupForArticle(article, articleArticlegroupsMap, activeArrangement.groups);

  return rounds
    .filter((round) => round.count_as_round)
    .reduce((total, round) => {
      const inRound = round.items.some((item) => {
        const g2 = findArrangementGroupForArticle(
          { id: String(item.product.id) },
          articleArticlegroupsMap,
          activeArrangement.groups
        );
        return g1 === g2;
      });

      return total + (inRound ? 1 : 0);
    }, 0);
}

export const checkMaxRoundsItemsCanBeOrdered: ArrangementLimitationCheck = async (
  { article }: OrderArticle,
  arrangement: ActiveArrangement,
  openDialog: (option: DialogOption) => string,
  closeDialog: () => void,
  intl: IntlShape
) =>
  new Promise((resolve) => {
    const roundsMaximum = maxRoundsItemCanBeOrdered(article);
    const roundsItemHasBeenOrdered = numberOfRoundsItemHasBeenOrdered(article);
    const valid = roundsMaximum > roundsItemHasBeenOrdered;
    if (roundsMaximum === Infinity) {
      resolve({ checkResult: CheckResult.VALID });
      return;
    }
    if (valid) {
      openDialog({
        children: (
          <ConfirmMaxRoundItemOrderPopup
            article={article}
            roundsMaximum={roundsMaximum}
            roundsItemHasBeenOrdered={roundsItemHasBeenOrdered}
            onCancel={() => {
              resolve({ checkResult: CheckResult.SKIPPED });
              closeDialog();
            }}
            onConfirm={() => {
              resolve({ checkResult: CheckResult.VALID });
              // note, resolve(...) calls one instance of `closeDialog()` itself also, so this closes this confirmation popup
              // -and- the article popup
              closeDialog();
            }}
          />
        ),
        disabledBackdropClick: true,
        TransitionComponent: SlideUpTransition,
      });
      return;
    }

    resolve({
      checkResult: CheckResult.INVALID,
      messageText: intl.formatMessage(
        {
          id: "AYCE.limitation-checks.arrangement-groups.max-rounds",
        },
        {
          name: article.name,
          maximum: roundsMaximum,
          current: roundsItemHasBeenOrdered,
        }
      ),
    });
  });
