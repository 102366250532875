import React from "react";
import { SwipeableDrawer } from "@mui/material";
import useServiceArticleListItem from "./useServiceArticleListItem.tsx";
import useIsWidescreen from "../../../global/utils/useIsWidescreen.ts";
import useScrollShadows from "../../../global/components/useScrollShadows.tsx";
import Box from "@mui/material/Box";

type ServiceBottomDrawerProps = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const ServiceBottomDrawer: React.FC<ServiceBottomDrawerProps> = ({ open, setOpen }) => {
  const isWidescreen = useIsWidescreen();
  const serviceArticleListItem = useServiceArticleListItem();

  let styles = { paddingTop: "10px", borderTopLeftRadius: "16px", borderTopRightRadius: "16px", maxHeight: "50%" };
  const widescreenStyles = {
    transform: "translateX(calc(50vw - 50% - 225px)) !important",
    padding: "15px",
    maxWidth: "500px",
    opacity: 0.9,
  };

  const refObject = useScrollShadows();

  if (isWidescreen) {
    styles = { ...styles, ...widescreenStyles };
  }

  return (
    <SwipeableDrawer
      PaperProps={{
        sx: styles,
      }}
      classes={{
        paper: "JS-ServiceBottomDrawer-Root",
      }}
      anchor={"bottom"}
      open={open}
      onClose={() => setOpen(false)}
      onOpen={() => {}}
      disableSwipeToOpen={true}
    >
      <Box sx={{ width: 1, overflowY: "auto" }} ref={refObject}>
        <div className="shadow shadow--top" style={{ position: "absolute", height: 20, minHeight: 20 }} />
        {serviceArticleListItem}
        <div className="shadow shadow--bottom" style={{ position: "absolute", height: 20, minHeight: 20 }} />
      </Box>
    </SwipeableDrawer>
  );
};

export default ServiceBottomDrawer;
