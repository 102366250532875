import { useEffect, useMemo, useState } from "react";
import OrderArticle, {
  initOrderArticle,
  OrderArticleAnalytics,
  OrderArticleOrigin,
} from "../../../global/utils/models/order/OrderArticle";
import { useAppSelector } from "../../../global/utils/redux/store";
import Article, { ArticleType } from "../../../global/utils/models/menu/Article";
import OrderArticleSelectorPageContent from "./OrderArticleSelectorPageContent";
import Articlegroup from "../../../global/utils/models/menu/Articlegroup";
import { OptionTranslationsContext } from "../../../global/utils/translations/useOptionTranslations";
import { selectArticlesMap } from "../../../global/utils/redux/selectors/selectArticlesMap";
import { OrderArticleContext } from "../../../global/utils/contexts/OrderArticleContext";

interface Props extends Pick<OrderArticleAnalytics, "upsellType" | "upsellSource" | "upsellId"> {
  orderArticle?: OrderArticle;
  article?: Article;
  articlegroup?: Articlegroup;
  isUpselled?: boolean;
  isPiggyReward?: boolean;
  callAfterAdding?: () => void;
}

function OrderArticleSelectorPage(props: Props) {
  const [orderArticle, setOrderArticle] = useState<OrderArticle | null>(null);

  const articlesMap = useAppSelector(selectArticlesMap);

  const article = useMemo<Article | null>(() => {
    if (props.orderArticle) {
      return { ...props.orderArticle.article, articlegroup: props.articlegroup };
    } else if (props.article) {
      return { ...props.article, articlegroup: props.articlegroup };
    }
    return null;
  }, [props.article, props.articlegroup, props.orderArticle]);

  const defaultOrderArticle = useMemo<OrderArticle | null>(() => {
    return props.orderArticle
      ? { ...props.orderArticle, articlegroup: props.articlegroup }
      : article
      ? initOrderArticle(
          articlesMap,
          article,
          1,
          "",
          !props.isPiggyReward,
          false,
          props.isUpselled ?? false,
          props.isPiggyReward ? OrderArticleOrigin.PIGGY : OrderArticleOrigin.MENU,
          ArticleType.Regular,
          null,
          props.upsellType,
          props.articlegroup,
          props.article?.sortKey,
          props.upsellSource,
          undefined,
          undefined,
          props.upsellId
        )
      : null;
  }, [
    props.orderArticle,
    props.articlegroup,
    props.isPiggyReward,
    props.isUpselled,
    props.upsellType,
    props.upsellSource,
    props.upsellId,
    props.article,
    article,
    articlesMap,
  ]);

  useEffect(() => {
    setOrderArticle(defaultOrderArticle);
  }, [article, props.orderArticle]); /// do NOT add defaultOrderArticle as dependency

  if (!article || !orderArticle) {
    return null;
  } else {
    return (
      <OrderArticleContext.Provider value={{ orderArticle, setOrderArticle }}>
        <OptionTranslationsContext.Provider value={orderArticle.article.translations}>
          <OrderArticleSelectorPageContent
            orderArticle={orderArticle}
            articlegroup={props.articlegroup}
            modifyingOrderArticle={props.orderArticle}
            isPiggyReward={props.isPiggyReward ?? false}
            callAfterAdding={props.callAfterAdding}
          />
        </OptionTranslationsContext.Provider>
      </OrderArticleContext.Provider>
    );
  }
}

export default OrderArticleSelectorPage;
