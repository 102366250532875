import {
  useFetchTableStateQuery,
  useLazyFetchArrangementsSettingsQuery,
} from "../../global/utils/redux/api/arrangementsApi";
import { useEffect } from "react";
import { useAppSelector } from "../../global/utils/redux/store";

export function useLoadArrangements() {
  const useArrangements = useAppSelector((state) => state.global.salesarea.use_arrangements);
  useFetchTableStateQuery(undefined, {
    pollingInterval: import.meta.env.MODE == "development" ? 120_000 : 120_000,
    skip: !useArrangements,
  });

  const [fetchSettings, { data: responseSettings }] = useLazyFetchArrangementsSettingsQuery({
    pollingInterval: 120000,
  });

  useEffect(() => {
    if (useArrangements) {
      if (!responseSettings) {
        fetchSettings();
      }
    }
  }, [useArrangements, fetchSettings, responseSettings]);
}
