import { useController } from "react-hook-form";
import { useCallback, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../../global/utils/redux/store";
import CustomField from "../../../../types/shared/CustomField";
import { setOrderCustomField } from "../../../../global/utils/redux/customFieldsSlice";
import isKiosk from "../../../../global/utils/helpers/isKiosk";

export default function useCustomField(customField: CustomField, extraRules: any = null) {
  const orderCustomField = useAppSelector((state) => state.customFields.orderCustomFields[customField.name]);
  const dispatch = useAppDispatch();
  const defaultValue = orderCustomField?.value ?? customField.defaultValue;

  const pattern = customField.pattern && isKiosk() ? new RegExp(customField.pattern) : undefined;

  const {
    field: { value, onChange },
  } = useController({
    name: customField.name,
    defaultValue,
    rules: {
      required: customField.required,
      ...extraRules,
      pattern,
    },
  });

  useEffect(() => {
    if (!value && defaultValue) {
      dispatch(setOrderCustomField({ customFieldName: customField.name, value: defaultValue }));
      onChange({ target: { value: defaultValue } });
    }
  }, []);

  const setCustomField = useCallback(
    (value: any) => {
      onChange(value);
      dispatch(setOrderCustomField({ customFieldName: customField.name, value: value }));
    },
    [dispatch, onChange, customField]
  );

  return { setCustomField, value };
}
