import useVouchers from "../useVouchers.ts";
import { useAppSelector } from "../redux/store.tsx";
import { useEffect } from "react";

export default function useDefaultVoucherCode() {
  const defaultVoucherCode = useAppSelector((state) => state.global.salesarea?.defaultVoucherCode);
  const { checkVoucherCode } = useVouchers({ withLoading: false });

  useEffect(() => {
    if (defaultVoucherCode) {
      checkVoucherCode(defaultVoucherCode);
    }
  }, [checkVoucherCode, defaultVoucherCode]);
}
