import { Chip, Collapse, Divider, Grid, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import { useEffect, useMemo, useState } from "react";
import { useDialog } from "../../../global/utils/dialog/DialogProvider";
import OrderArticleContent from "./OrderArticleContent";
import SlideUpTransition from "../../../global/components/SlideUpTransition";
import CheckoutPage from "../CheckoutPage/CheckoutPage";
import CloseDialogButton from "../../components/CloseDialogButton";
import FormattedMessageJamezz from "../../../global/components/FormattedMessageJamezz";
import store, { useAppDispatch, useAppSelector } from "../../../global/utils/redux/store";
import VoucherInput from "../../components/VoucherInput";
import { toast } from "react-toastify";
import { setOrderCustomField } from "../../../global/utils/redux/customFieldsSlice";
import { OnetimeUpsell } from "../../../global/utils/hooks/useOpenOneTimeUpsellDialog";
import ArticleSuggestionPage from "../ArticleSuggestionPage/ArticleSuggestionPage";
import CurrencySelector from "../../../global/components/CurrencySelector/CurrencySelector";
import "./scrollcontainer.css";
import BackDialogButton from "../../components/BackDialogButton";
import useScrollShadows from "../../../global/components/useScrollShadows";
import AccountDiscount from "../../components/AccountDiscount/AccountDiscount";
import AllergensMenu from "../../components/Allergens/AllergensMenu";
import { simphonyCheckCalculate } from "../../../global/utils/redux/listeners/simphonyCheckCalculatorListener";
import useUpsellNoFoodOrNoDrinksInShoppingCart, {
  UpsellType,
} from "../../../global/utils/hooks/useUpsellNoFoodInShoppingCart";
import { selectStepperStepcount } from "../../utils/useCustomerOrderInputPages";
import useOrderLimits from "../../../global/utils/useOrderLimits";
import { useAccountTotalValue } from "../../../global/utils/redux/accountSlice";
import { selectArticlesSum, selectAutoAddedItems } from "../../../global/utils/redux/shoppingCartSlice";
import DiscountsCheckCalculator from "../../../global/components/MWise/DiscountsCheckCalculator";
import { OptionTranslationsContext } from "../../../global/utils/translations/useOptionTranslations";
import PackagingKiosk from "../../components/Packagings/PackagingKiosk";
import OrderArticle from "../../../global/utils/models/order/OrderArticle";
import { selectCustomFields } from "../../../global/utils/redux/selectors/selectCustomFields";
import { selectPaymentMethodCustomField } from "../../../global/utils/redux/selectors/selectPaymentMethodCustomField";
import { useLoadingScreen } from "../../../global/modules/LoadingScreen/loadingScreen";
import { CheckoutButton } from "./CheckoutButton";
import { OrderMore } from "./OrderMore";
import { SendOrder } from "./SendOrder";
import { useScanBoardingPass } from "../../utils/useScanBoardingPass";
import { createSelector } from "@reduxjs/toolkit";
import { InfoTotal } from "./InfoTotal";
import { InfoItemCount } from "./InfoItemCount";
import { OrderPageInfo } from "./OrderPageInfo";
import { OrderPageBottom } from "./OrderPageBottom";
import { PlayArrow } from "@mui/icons-material";
import { useCustomTexts } from "../../../global/utils/useCustomTexts";
import PiggyGiftcardOrderPage from "../../../qr/components/Piggy/Giftcards/PiggyGiftcardOrderPage";
import BackgroundMediaUrlFromSalesarea from "../../hooks/useBackgroundMediaUrl";
import { postAnalyticsEvent, submitEComEvent } from "../../../global/utils/analytics/useAnalytics";
import PiggyRewards from "../../components/piggy/Loyalty/PiggyRewards.tsx";
import { selectVoucherOrderItems } from "../../../global/utils/redux/selectors/selectVoucherOrderItems.tsx";

const selectAccountDiscountEnabled = createSelector(
  [
    (state) => state.global.salesarea.useAccountServer,
    (state) => state.global.salesarea.useAccountServer_input,
    (state) => state.shoppingCart.discountCardNr,
  ],
  (useAccountServer, useAccountServer_input, discountCardNr) => {
    return useAccountServer && useAccountServer_input && !discountCardNr;
  }
);

export default function OrderPage() {
  const { openDialog } = useDialog();
  const scanBoardingPass = useScanBoardingPass();
  const items = useAppSelector((state) => state.shoppingCart.items);
  const autoAddedItems = useAppSelector(selectAutoAddedItems);
  const { errors } = useOrderLimits();
  const dispatch = useAppDispatch();
  const totalAmount = useAccountTotalValue();
  const count = useAppSelector(selectArticlesSum);
  const voucherOrderItems = useAppSelector(selectVoucherOrderItems);
  const [laggedErrors, setLaggedErrors] = useState(errors);
  const customFields = useAppSelector(selectCustomFields);
  const currencyRates = useAppSelector((state) => state.menu.currencyRates);
  const paymentCustomField = useAppSelector(selectPaymentMethodCustomField);
  const payDirect = useAppSelector((state) => state.global.salesarea?.payDirect);
  const usingJamezzVouchers = useAppSelector((state) => state.global.salesarea?.allowVouchers);
  const allergenOrderArticles = useAppSelector((state) => state.shoppingCart.allergenOrderArticles);
  const orderContainerRef = useScrollShadows();
  const accountDiscountEnabled = useAppSelector(selectAccountDiscountEnabled);
  const usePackagings = useAppSelector((state) => state.global.salesarea.use_packagings);
  const wheelchairFriendlyEnabled = useAppSelector((state) => state.kiosk.wheelchairFriendly);

  const ct = useCustomTexts([
    "voucher button text",
    "kiosk - Orderpage checkout button",
    "kiosk - Orderpage order button",
    "kiosk - Orderpage goto checkout button",
    "kiosk - Orderpage order more button",
  ]);
  const check_calculate_settings_enabled = useAppSelector(
    (state) => state.global.salesarea.check_calculate_settings.enabled
  );
  const { isActive: isActiveNoFood, openUpsell: openUpsellNoFood } = useUpsellNoFoodOrNoDrinksInShoppingCart(
    UpsellType.upsellNoFood
  );
  const { isActive: isActiveNoDrinks, openUpsell: openUpsellNoDrinks } = useUpsellNoFoodOrNoDrinksInShoppingCart(
    UpsellType.upsellNoDrinks
  );

  const piggyGiftcardsEnabled = useAppSelector((state) => state.global.salesarea.piggy.giftcards.enabled);
  // const piggyVouchersEnabled = useAppSelector((state) => state.global.salesarea.piggy.vouchers.enabled);
  const contactIdentifierScan = useAppSelector((state) => state.piggy.contactIdentifierScan);
  const { startLoading, stopLoading } = useLoadingScreen();
  const numberOfStepperSteps = useAppSelector(selectStepperStepcount);

  useEffect(() => {
    if (isActiveNoFood) {
      openUpsellNoFood(ArticleSuggestionPage);
    }
  }, []);

  useEffect(() => {
    if (isActiveNoDrinks) {
      openUpsellNoDrinks(ArticleSuggestionPage);
    }
  }, []);

  useEffect(() => {
    if (check_calculate_settings_enabled) {
      startLoading();
      simphonyCheckCalculate(store.getState(), dispatch).finally(() => {
        stopLoading();
      });
    }
  }, []);

  useEffect(() => {
    // if (Object.keys(errors).length === 0 && Object.keys(laggedErrors).length !== 0) {
    if (Object.keys(errors).length > Object.keys(laggedErrors).length) {
      setLaggedErrors(errors);
    } else {
      setTimeout(() => {
        setLaggedErrors(errors);
      }, 200);
    }
  }, [errors, laggedErrors]);

  const allErrors = { ...errors, ...laggedErrors };
  const ordered = Object.keys(allErrors)
    .sort()
    .reduce((obj, key) => {
      // @ts-ignore
      obj[key] = allErrors[key];
      return obj;
    }, {});

  useEffect(() => {
    if (payDirect && !paymentCustomField) {
      toast.error("No payments configured! Configure one or disable payments!", {
        toastId: "err-no-payments-configured",
      });
    }
  }, [payDirect, paymentCustomField]);

  useEffect(() => {
    customFields.forEach((customField) => {
      if (customField.type === "hidden") {
        dispatch(setOrderCustomField({ customFieldName: customField.name, value: customField.value }));
      }
    });
    if (paymentCustomField && paymentCustomField.options?.length === 1) {
      dispatch(
        setOrderCustomField({ customFieldName: paymentCustomField.name, value: paymentCustomField.options[0].id })
      );
    }
  }, []);

  useEffect(() => {
    postAnalyticsEvent({
      category: "OrderPage",
      action: "openDialog->CheckoutPage",
    });
    submitEComEvent("view_cart", autoAddedItems, items, voucherOrderItems, { value: totalAmount });
  }, [autoAddedItems, items, voucherOrderItems, totalAmount]);

  const allergenOrderArticlesArray: OrderArticle[] = useMemo(() => {
    return Object.values(allergenOrderArticles);
  }, [allergenOrderArticles]);

  const piggyRewardsRedeemingEnabled = useAppSelector(
    (state) =>
      state.global.salesarea.piggy.loyalty.enabled && state.global.salesarea.piggy.loyalty.rewards_redeeming_enabled
  );

  return (
    <>
      <BackgroundMediaUrlFromSalesarea
        keyName={"extra_settings__kioskv5_background_of_order_page"}
        className={"JS-OrderPage-Root"}
        data-cy="orderPage"
        sx={{
          width: 1,
          height: 1,
          position: "relative",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          paddingX: 4,
          paddingTop: 4,
          paddingBottom: wheelchairFriendlyEnabled ? null : 4,
        }}
        fallbackBackgroundColor={"primary.light"}
      >
        <BackDialogButton />
        <CloseDialogButton />
        <Typography
          className={"JS-OrderPage-Title"}
          variant={"h5"}
          sx={{ textTransform: "uppercase", fontWeight: 800, fontSize: 80, color: "white" }}
        >
          <FormattedMessageJamezz id={"My Order"} />
        </Typography>
        {Object.keys(ordered).map((errorKey) => {
          return (
            <Collapse key={errorKey} in={laggedErrors[errorKey] !== undefined ? errors[errorKey] !== undefined : false}>
              <Chip
                sx={{
                  backgroundColor: "error.light",
                  padding: 3,
                  margin: 1,
                  fontWeight: 800,
                  color: "error.contrastText",
                }}
                label={<FormattedMessageJamezz id={allErrors[errorKey].id} values={allErrors[errorKey].values} />}
              />
            </Collapse>
          );
        })}
        <Grid
          container
          sx={{
            marginTop: wheelchairFriendlyEnabled ? 0 : 2.5,
            flex: "1 1 0%",
            alignContent: "flex-start",
            overflowY: "auto",
            position: "relative",
          }}
          ref={orderContainerRef}
        >
          <div className="shadow shadow--top"></div>
          <DiscountsCheckCalculator />
          {autoAddedItems.map((orderArticle) => (
            <OptionTranslationsContext.Provider key={orderArticle.uuid} value={orderArticle.article.translations}>
              <OrderArticleContent key={orderArticle.uuid} orderArticle={orderArticle} />
            </OptionTranslationsContext.Provider>
          ))}
          {items.map((orderArticle, index) => (
            <OptionTranslationsContext.Provider key={orderArticle.uuid} value={orderArticle.article.translations}>
              <OrderArticleContent
                key={orderArticle.uuid}
                orderArticle={orderArticle}
                index={index}
                usePiggyVouchers={true}
              />
            </OptionTranslationsContext.Provider>
          ))}
          {[...voucherOrderItems, ...allergenOrderArticlesArray].map((orderArticle) => (
            <OptionTranslationsContext.Provider key={orderArticle.uuid} value={orderArticle.article.translations}>
              <OrderArticleContent key={orderArticle.uuid} orderArticle={orderArticle} />
            </OptionTranslationsContext.Provider>
          ))}

          <div className="shadow shadow--bottom"></div>
        </Grid>
        <Divider />
        {/*{piggyVouchersEnabled ? <OrderPageVouchers /> : null}*/}
        {piggyGiftcardsEnabled ? <PiggyGiftcardOrderPage /> : null}
        {piggyRewardsRedeemingEnabled && contactIdentifierScan ? (
          <Box
            sx={{
              alignSelf: "end",
              marginBottom: 4,
              width: "100%",
              marginTop: 4,
            }}
          >
            <PiggyRewards showBalance={true} />

            <Divider sx={{ borderBottomWidth: "4px", marginTop: 4 }} />
          </Box>
        ) : null}

        <AllergensMenu />
        {usePackagings && numberOfStepperSteps === 0 ? <PackagingKiosk sx={{ width: 1 }} /> : null}
        <OrderPageBottom
          voucherAction={
            <Box sx={{ display: "grid" }}>
              {usingJamezzVouchers ? (
                <VoucherInput>{ct["voucher button text"] ?? <FormattedMessageJamezz id="Add voucher" />}</VoucherInput>
              ) : null}
              {accountDiscountEnabled ? (
                <AccountDiscount>
                  {ct["voucher button text"] ?? <FormattedMessageJamezz id="DISCOUNT CARD" />}
                </AccountDiscount>
              ) : null}
            </Box>
          }
          back={
            <OrderMore>
              <Box
                sx={{
                  display: "grid",
                  alignItems: "center",
                  gridTemplateColumns: "min-content 1fr",
                  width: 1,
                }}
              >
                <span style={{ display: "flex" }}>
                  {<PlayArrow className={"JS-OrderPage-ContinueShopping-PlayArrow"} sx={{ rotate: "180deg" }} />}
                </span>
                <span>
                  {ct["kiosk - Orderpage order more button"] ?? (
                    <>
                      <FormattedMessageJamezz id="Order more" />
                      ...
                    </>
                  )}
                </span>
              </Box>
            </OrderMore>
          }
          forward={
            <>
              {count > 0 ? (
                numberOfStepperSteps === 0 ? (
                  <SendOrder>
                    <Box
                      sx={{
                        width: 1,
                        display: "grid",
                        alignItems: "center",
                        gridTemplateColumns: "1fr min-content",
                      }}
                    >
                      {payDirect
                        ? ct["kiosk - Orderpage checkout button"] ?? <FormattedMessageJamezz id="Checkout" />
                        : ct["kiosk - Orderpage order button"] ?? <FormattedMessageJamezz id="Order" />}
                      <PlayArrow className={"JS-SendOrderButton-PlayArrow"} />
                    </Box>
                  </SendOrder>
                ) : (
                  <CheckoutButton
                    onClick={async () => {
                      await scanBoardingPass();
                      submitEComEvent("begin_checkout", autoAddedItems, items, voucherOrderItems, {
                        value: totalAmount,
                      });
                      openDialog({
                        children: <CheckoutPage />,
                        fullScreen: true,
                        TransitionComponent: SlideUpTransition,
                      });
                    }}
                  >
                    <Box
                      sx={{
                        width: 1,
                        display: "grid",
                        alignItems: "center",
                        gridTemplateColumns: "1fr min-content",
                      }}
                    >
                      {ct["kiosk - Orderpage goto checkout button"] ?? <FormattedMessageJamezz id="Go to checkout" />}
                      <PlayArrow className={"JS-SendOrderButton-PlayArrow"} />
                    </Box>
                  </CheckoutButton>
                )
              ) : (
                <div />
              )}
            </>
          }
          info={
            <OrderPageInfo itemCount={<InfoItemCount />} total={<InfoTotal />}>
              {currencyRates ? <CurrencySelector /> : null}
            </OrderPageInfo>
          }
        />
      </BackgroundMediaUrlFromSalesarea>
      <OnetimeUpsell where={"onOpenShoppingCart"} />
    </>
  );
}
