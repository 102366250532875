import { useRef } from "react";
import CustomField from "../../../../types/shared/CustomField";
import { FormControl, FormHelperText, TextField } from "@mui/material";
import useCustomField from "./useCustomField";
import { onFocusKeyboard } from "../../../../global/utils/redux/globalSlice";
import { useAppDispatch } from "../../../../global/utils/redux/store";
import { useFocusCustomField } from "./useFocusCustomField";
import isKiosk from "../../../../global/utils/helpers/isKiosk";
import Explanation from "./Explanation";

interface Props {
  customField: CustomField;
}

function CustomFieldEmailContent(props: Props) {
  const customField = props.customField;
  const { value, setCustomField } = useCustomField(props.customField);
  const dispatch = useAppDispatch();
  const inputRef = useRef<HTMLInputElement | null>(null);

  useFocusCustomField(inputRef, customField.name);

  return (
    <FormControl sx={{ marginY: 1, width: 1 }}>
      <FormHelperText>{customField.description}</FormHelperText>
      <TextField
        helperText={<Explanation customField={customField} />}
        variant={"outlined"}
        name={customField.name}
        inputRef={inputRef}
        type={"email"}
        inputProps={{
          pattern: customField.pattern,
          required: customField.required,
          className: "JS-CustomFields-Input JS-CustomFieldEmailContent-Input",
          inputMode: isKiosk() ? "none" : undefined,
        }}
        InputLabelProps={{ className: "JS-CustomFieldEmailContent-Label JS-CustomFields-Input-Label" }}
        disabled={customField.disabled}
        label={customField.label}
        value={value}
        fullWidth
        onChange={(e) => {
          setCustomField(e.target.value);
        }}
        onFocus={() => {
          if (isKiosk()) {
            dispatch(
              onFocusKeyboard({
                value: value,
                layout: "default",
              })
            );
          }
        }}
      />
    </FormControl>
  );
}

export default CustomFieldEmailContent;
