import { Box, Typography } from "@mui/material";
import CloseDialogButtonQr from "../../../qr/components/CloseButtons/CloseDialogButtonQr";
import { Close } from "@mui/icons-material";

import { useAppSelector } from "../../utils/redux/store";
import { useCustomTexts } from "../../utils/useCustomTexts";
import FormattedMessageJamezz from "../FormattedMessageJamezz";

export default function TableIsOpenMessage() {
  const checkTableIsOpen = useAppSelector((state) => state.global.salesarea.checkTableIsOpen);

  const customTexts = useCustomTexts(["check_table_is_open_message"]);

  if (checkTableIsOpen.enabled) {
    return (
      <Box>
        <Typography textAlign={"center"} sx={{ py: 2, px: 3 }}>
          {customTexts["check_table_is_open_message"] ?? <FormattedMessageJamezz id={"check_table_is_open_message"} />}
        </Typography>
      </Box>
    );
  } else {
    return null;
  }
}

export function TableIsOpenMessageDialog() {
  return (
    <>
      <Box sx={{ textAlign: "right" }}>
        <CloseDialogButtonQr icon={<Close />} />
      </Box>
      <TableIsOpenMessage />
    </>
  );
}
