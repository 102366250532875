import { useCallback, useEffect, useRef, useState } from "react";
import Articlegroup from "../../../../global/utils/models/menu/Articlegroup";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import { ArrowBackIosNew } from "@mui/icons-material";
import TopBar from "../../../components/TopBar";
import FormattedMessageJamezz from "../../../../global/components/FormattedMessageJamezz";
import { useDialog } from "../../../../global/utils/dialog/DialogProvider";
import KioskTopBar from "../../../components/KioskTopBar/KioskTopBar";
import HorizontalArticlegroupTab from "./HorizontalArticlegroupTab";
import { throttle } from "lodash";
import ScrollArticlegroupTabListener from "../../../../global/utils/menu/ScrollArticlegroupTabListener";
import { useGoToHomeKiosk } from "../../ReturnPage/ReturnPage";
import { Skeleton } from "@mui/material";
import { useAppSelector } from "../../../../global/utils/redux/store";
import { useDocumentDirection } from "../../../../global/components/JamezzIntlProvider";
import BackgroundMediaUrlFromSalesarea from "../../../hooks/useBackgroundMediaUrl.tsx";
import { AreYouSureStartOverDialog } from "../../../components/AreYouSureStartOverDialog.tsx";

interface Props {
  articlegroups: Articlegroup[];
  disableSearch?: boolean;
  disableHomeButton?: boolean;
}

export default function HorizontalArticlegroupTabBar(props: Props) {
  const { openDialog } = useDialog();
  const menuLoaded = useAppSelector((state) => state.menuData.menuLoaded);
  const [scrollableButtons, setScrollableButtons] = useState({ start: true, end: true });
  const documentDirection = useDocumentDirection();
  const box = useRef<HTMLElement>(null);
  const [height, setHeight] = useState(0);
  const goToHome = useGoToHomeKiosk();
  const onScroll = throttle(
    () => {
      onScroll2();
    },
    500,
    { leading: true, trailing: true }
  );
  const onScroll2 = useCallback(() => {
    const copy = { ...scrollableButtons };
    if (box.current) {
      if (
        (documentDirection === "ltr" ? 1 : -1) * box.current.scrollLeft + box.current.clientWidth <
        box.current.scrollWidth
      ) {
        if (!copy.end) {
          copy.end = true;
        }
      } else {
        if (copy.end) {
          copy.end = false;
        }
      }

      if ((documentDirection === "ltr" ? 1 : -1) * box.current.scrollLeft > 0) {
        if (!copy.start) {
          copy.start = true;
        }
      } else {
        if (copy.start) {
          copy.start = false;
        }
      }
      setScrollableButtons(copy);
    }
    // console.log("TOP", isToTopScrollable.current);
    // console.log("BOTTOM", isToDownScrollable.current);
  }, [documentDirection, scrollableButtons]);

  const onResize = useCallback(() => {
    if (box.current) {
      setHeight(box.current.getBoundingClientRect().height);
    }
  }, []);

  useEffect(() => {
    let element: HTMLElement | null = null;
    if (box.current) {
      element = box.current;
      element.addEventListener("scroll", onScroll);
    }
    return () => {
      if (element) {
        element.removeEventListener("scroll", onScroll);
      }
    };
  }, [onScroll]);

  useEffect(() => {
    let element: HTMLElement | null = null;
    if (box.current) {
      element = box.current;
      element.addEventListener("resize", onResize);
    }
    return () => {
      if (element) {
        element.removeEventListener("resize", onResize);
      }
    };
  }, [onResize]);

  useEffect(() => {
    setTimeout(() => {
      onScroll();
    }, 500);
    setTimeout(() => {
      onResize();
    }, 500);
  }, []);

  const onClickHome = useCallback(() => {
    openDialog({
      children: (
        <AreYouSureStartOverDialog
          message={<FormattedMessageJamezz id={"Are you sure you want to start over?"} />}
          onOk={() => {
            goToHome();
          }}
          CancelText={<FormattedMessageJamezz id={"Cancel order"} />}
          OkText={
            <>
              <FormattedMessageJamezz id={"Continue ordering"} />
              ...
            </>
          }
        />
      ),
    });
  }, [openDialog, goToHome]);

  const filteredArticlegroups = props.articlegroups.filter((articlegroup) => articlegroup.showInCategoryMenu);

  return (
    <TopBar>
      <ScrollArticlegroupTabListener articlegroups={props.articlegroups} />
      <BackgroundMediaUrlFromSalesarea
        keyName={"extra_settings__kioskv5_background_of_menu_page_header"}
        className={"JS-HorizontalArticlegroupTabBar-Root"}
        sx={{
          width: 1,
          position: "relative",
          display: "flex",
          flexDirection: "column",
        }}
        fallbackBackgroundColor={"primary.main"}
      >
        <Box
          className={"JS-HorizontalArticlegroupTabBar-SearchWrapper"}
          sx={{ width: 1, textAlign: "center", marginTop: 4 }}
        >
          <KioskTopBar disableSearch={props.disableSearch} disableHomeButton={props.disableHomeButton} />
        </Box>
        <Box
          className={"JS-HorizontalArticlegroupTabBar-TopBanner"}
          sx={{ height: 0, width: 1 }}
          onClick={onClickHome}
        />

        {!menuLoaded ? (
          <>
            <Box sx={{ mx: 2, display: "flex", justifyContent: "center" }}>
              <Skeleton sx={{ width: 200, height: 260, mx: 2 }} />
              <Skeleton sx={{ width: 200, height: 260, mx: 2 }} />
              <Skeleton sx={{ width: 200, height: 260, mx: 2 }} />
              <Skeleton sx={{ width: 200, height: 260, mx: 2 }} />
              <Skeleton sx={{ width: 200, height: 260, mx: 2 }} />
              <Skeleton sx={{ width: 200, height: 260, mx: 2 }} />
            </Box>
          </>
        ) : null}

        {filteredArticlegroups.length > 1 ? (
          <Box
            className={"MuiTabs-scroller"}
            sx={{
              overflowY: "hidden",
              overflowX: "auto",
              display: "flex",
              flexDirection: "row",
              paddingY: 2,
              flex: 1,
              position: "relative",
            }}
            ref={box}
          >
            {
              <IconButton
                className={"JS-HorizontalArticlegroupTabBar-ButtonTop"}
                size={"large"}
                sx={{
                  zIndex: 10,
                  position: "fixed",
                  height,
                  left: documentDirection === "rtl" ? undefined : 0,
                  right: documentDirection === "rtl" ? 0 : undefined,
                  marginX: "auto",
                  color: "primary.dark",
                  visibility: scrollableButtons.start ? undefined : "collapse",
                }}
                aria-label="close"
                onClick={() => {
                  const element = document.getElementById("ArticlegroupTab-" + filteredArticlegroups[0]?.id);
                  if (element && box.current) {
                    element.parentElement?.parentElement?.scrollBy({
                      left: documentDirection === "rtl" ? 600 : -600,
                      behavior: "smooth",
                    });
                  }
                }}
              >
                {/*<Bouncer>*/}
                <ArrowBackIosNew
                  sx={{
                    width: 80,
                    height: 120,
                    transform: documentDirection === "rtl" ? "rotate(180deg)" : "rotate(0deg)",
                    transformOrigin: "center",
                  }}
                />
                {/*</Bouncer>*/}
              </IconButton>
            }

            <Box
              sx={{
                display: "grid",

                gridTemplateColumns: `repeat(${filteredArticlegroups.length}, auto)`,
                gridTemplateRows: "repeat(2, auto)",
                columnGap: "32px",
                marginX: 2,
              }}
            >
              {filteredArticlegroups.map((articlegroup, index) => {
                return <HorizontalArticlegroupTab articlegroup={articlegroup} key={articlegroup.id} index={index} />;
              })}
            </Box>
            {
              <IconButton
                className={"JS-HorizontalArticlegroupTabBar-ButtonDown"}
                size={"large"}
                sx={{
                  zIndex: 10,
                  position: "fixed",
                  left: documentDirection === "rtl" ? 0 : undefined,
                  right: documentDirection === "rtl" ? undefined : 0,
                  height,
                  marginX: "auto",
                  color: "primary.dark",
                  visibility: scrollableButtons.end ? undefined : "collapse",
                }}
                onClick={() => {
                  const element = document.getElementById("ArticlegroupTab-" + filteredArticlegroups[0]?.id);
                  if (element && box.current) {
                    element.parentElement?.parentElement?.scrollBy({
                      left: documentDirection === "rtl" ? -600 : 600,
                      behavior: "smooth",
                    });
                  }
                }}
                aria-label="close"
              >
                {/*<Bouncer>*/}
                <ArrowBackIosNew
                  sx={{
                    width: 80,
                    height: 120,
                    transform: documentDirection === "rtl" ? "rotate(0deg)" : "rotate(180deg)",
                    transformOrigin: "center",
                  }}
                />
                {/*</Bouncer>*/}
              </IconButton>
            }
          </Box>
        ) : null}

        <Box className={"JS-HorizontalArticlegroupTabBar-BottomBanner"} sx={{ height: 0, width: 1 }}></Box>
      </BackgroundMediaUrlFromSalesarea>
    </TopBar>
  );
}
