import { useAppDispatch, useAppSelector } from "../redux/store";
import { voucherRemoved } from "../redux/shoppingCartSlice";
import Voucher from "../vouchers/Voucher";
import { useEffect } from "react";
import { getArticleById } from "../models/menu/Menu";
import { selectArticlesMap } from "../redux/selectors/selectArticlesMap";

/**
 * Responsible for removing vouchers if they are invalid
 */
export function VoucherChecker() {
  const vouchers: Voucher[] = useAppSelector((state) => state.shoppingCart.vouchers);
  const dispatch = useAppDispatch();

  const articlesMap = useAppSelector(selectArticlesMap);

  useEffect(() => {
    // wait until menu data is loaded
    if (Object.keys(articlesMap).length > 0) {
      vouchers.forEach((voucher) => {
        // voucher needs to be removed if:
        // 1. has no articles connected at all
        if (!voucher.articles) {
          dispatch(voucherRemoved(voucher));
          return;
        }

        for (const voucherArticle of voucher.articles) {
          const article = getArticleById(articlesMap, voucherArticle.id);
          if (!article) {
            // 2. has article(s) connected that doesn't exist
            dispatch(voucherRemoved(voucher));
            return;
          }
        }
      });
    }
  }, [dispatch, articlesMap, vouchers]);

  return null;
}
