import { createSelector } from "@reduxjs/toolkit";
import { arrangementsApi } from "../api/arrangementsApi.ts";
import { selectArticlesMap } from "../selectors/selectArticlesMap.ts";

import { selectActiveArrangement } from "./selectActiveArrangement.tsx";

export const CREDITS_PER_COVER_PER_ROUND_DISABLED = 0;

export const selectMaxDishesInRound = createSelector([selectActiveArrangement], (arrangement) => {
  if (arrangement == null || arrangement.variant === "pre-dining") return null;
  const max = arrangement?.max_number_of_items_per_round_per_cover ?? 0;
  return max * arrangement?.total_covers ?? 0;
});
export const selectArrangementMaxTime = createSelector(
  [
    selectActiveArrangement,
    (state) => arrangementsApi.endpoints.fetchTableState.select()(state).data?.data,
    selectArticlesMap,
  ],
  (arrangement, tableState, articlesMap) => {
    if (arrangement?.variant !== "regular") {
      return null;
    }
    let total_time = arrangement?.max_time_in_minutes ?? 0;
    if (
      arrangement &&
      tableState &&
      arrangement.allow_extending_visits &&
      arrangement.time_extension_product_id &&
      arrangement.total_covers
    ) {
      const time_extension_product = articlesMap[arrangement.time_extension_product_id.toString()];
      if (time_extension_product) {
        const time_extension_product_apiid = time_extension_product.apiId1;
        let calculationFactor = 1;
        if (arrangement.visit_extension_calculation_method === "per_table") {
          calculationFactor = 1;
        } else if (arrangement.visit_extension_calculation_method === "per_couvert") {
          calculationFactor = 1 / arrangement.total_covers;
        }

        total_time = tableState.table_items.reduce((minutes, { apiId, count }) => {
          if (apiId === time_extension_product_apiid) {
            const extraTime = count * arrangement.minutes_added_per_time_extension * calculationFactor;
            return minutes + extraTime;
          }
          return minutes;
        }, total_time);
      }
    }
    return total_time;
  }
);
export const selectArrangementMaxRounds = createSelector(
  [
    selectActiveArrangement,
    (state) => arrangementsApi.endpoints.fetchTableState.select()(state).data?.data,
    selectArticlesMap,
  ],
  (arrangement, tableState, articlesMap) => {
    if (arrangement?.variant !== "regular") {
      return 0;
    }
    let totalMaxRounds = arrangement?.max_rounds ?? 0;
    if (
      arrangement &&
      tableState &&
      arrangement.allow_extending_visits &&
      arrangement.round_extension_product_id &&
      arrangement.total_covers
    ) {
      const round_extension_product = articlesMap[arrangement.round_extension_product_id.toString()];
      const round_extension_product_apiid = round_extension_product.apiId1;
      let calculationFactor = 1;
      if (arrangement.visit_extension_calculation_method === "per_table") {
        calculationFactor = 1;
      } else if (arrangement.visit_extension_calculation_method === "per_couvert") {
        calculationFactor = 1 / arrangement.total_covers;
      }

      totalMaxRounds = tableState.table_items.reduce((rounds, { apiId, count }) => {
        if (apiId === round_extension_product_apiid) {
          const extraRounds = count * calculationFactor;
          return rounds + extraRounds;
        }
        return rounds;
      }, totalMaxRounds);
    }

    return totalMaxRounds;
  }
);
