import { useAppSelector } from "../redux/store";
import { useFetchRewardsQuery, useFetchShopRewardsQuery } from "../redux/api/piggyApi";
import Article from "../models/menu/Article";
import { PiggyErrorResponse, PiggyReward } from "../../../types/shared/piggy";

export function usePiggyRewards({ skip = false }: { skip?: boolean } = {}) {
  const piggyEnabled = useAppSelector((state) => state.global.salesarea.piggy.loyalty.enabled);
  const { data, isLoading: rewardsLoading, isUninitialized } = useFetchRewardsQuery(undefined, {
    skip: skip || !piggyEnabled,
  });
  let rewards;

  if (data) {
    rewards = data.data.filter((reward: PiggyReward) => reward.artikelid);
  }

  return { rewards, rewardsLoading, rewardsFetchingUninitialized: isUninitialized };
}

/**
 * Checks whether an article has a piggy offer
 * @param article
 */
export function useIsPiggyOffer(article: Article) {
  const piggyEnabled = useAppSelector((state) => state.global.salesarea.piggy.loyalty.enabled);
  const salesareaId = useAppSelector((state) => state.global.salesarea.id);
  const { result } = useFetchShopRewardsQuery(salesareaId, {
    skip: !piggyEnabled,
    selectFromResult: ({ data }) => {
      return {
        result: data?.find((piggyReward) => piggyReward.artikelid?.toString() === article.id),
      };
    },
  });

  return result;
}

export function assertIsPiggyErrorMessage(error: any): asserts error is PiggyErrorResponse {
  if (!("data" in error)) {
    throw new Error("Not a valid error");
  }
  if (!Number.isFinite(error.data.context?.code)) {
    throw new Error("Not a piggy exception");
  }
}
