import { useCallback, useMemo } from "react";
import Article from "../../models/menu/Article";
import Articlegroup from "../../models/menu/Articlegroup";
import { useAppSelector } from "../../redux/store";
import { MenuTagType } from "../../models/menu/MenuTag";
import { useIsArticleAvailableInArrangement } from "../../redux/arrangements/arrangementsSlice.tsx";
import isQr from "../../helpers/isQr";
import { selectMenuTagFilters } from "../../redux/selectors/selectMenuTagFilters";
import { selectServiceRequestArticles } from "../../redux/selectors/selectServiceRequestArticles";
import { selectArticleArticlegroupsMap } from "../../redux/selectors/selectArticleArticlegroupsMap";
import { selectArticlegroupsMap } from "../../redux/selectors/selectArticlegroupsMap";
import useAgeCheckResponse from "../../ageCheck/useAgeCheckResponse.ts";
import { selectOptionGroupsMap } from "../../redux/selectors/selectOptionGroupsMap.ts";
import { selectArticlesMap } from "../../redux/selectors/selectArticlesMap.ts";
import { selectActiveArrangement } from "../../redux/arrangements/selectActiveArrangement.tsx";
import { useIntl } from "react-intl";
import { getPropertyOfOptionGroup } from "../../models/menu/OptionGroup.ts";

function useMenuFilters(
  {
    usePreferencesAndAllergens = true,
    useArrangementsFilter = true,
    useArticlegroupsFilter = true,
  }: { usePreferencesAndAllergens?: boolean; useArrangementsFilter?: boolean; useArticlegroupsFilter?: boolean } = {
    usePreferencesAndAllergens: true,
    useArrangementsFilter: true,
    useArticlegroupsFilter: true,
  }
) {
  const articleOrderHoursState = useAppSelector((state) => state.menuState.articleOrderHours);
  const articlegroupOrderHoursState = useAppSelector((state) => state.menuState.articlegroupOrderHours);
  const articleArticlegroupsMap = useAppSelector(selectArticleArticlegroupsMap);
  const hideItemsBySelectedAllergens = useAppSelector((state) => state.global.hideItemsBySelectedAllergens);
  const intl = useIntl();
  const menuTags = useAppSelector((state) => selectMenuTagFilters(state, intl));
  const serviceRequestArticles = useAppSelector(selectServiceRequestArticles);
  const menuTagPreferences = useAppSelector((state) => state.menuState.menuTagPreferences);
  const useArrangements = useAppSelector((state) => state.global.salesarea.use_arrangements);
  const arrangement = useAppSelector(selectActiveArrangement);
  const articlegroupsMap = useAppSelector(selectArticlegroupsMap);
  const articlesMap = useAppSelector(selectArticlesMap);
  const ageCheckResponse = useAgeCheckResponse();
  const ageCheckIsDenied = ageCheckResponse === "DENIED";
  const isArticleAvailableInArrangement = useIsArticleAvailableInArrangement(articleArticlegroupsMap);
  const optionGroupsMap = useAppSelector(selectOptionGroupsMap);

  const allergens = useMemo(() => {
    return Object.values(menuTags).filter(
      (menuTag) => menuTag.type === MenuTagType.Exclude && menuTagPreferences?.[menuTag.id]?.checked
    );
  }, [menuTags, menuTagPreferences]);

  const preferences = useMemo(() => {
    return Object.values(menuTags).filter(
      (menuTag) => menuTag.type === MenuTagType.Include && menuTagPreferences?.[menuTag.id]?.checked
    );
  }, [menuTags, menuTagPreferences]);

  const articleFilter = useCallback(
    (
      article: Article,
      index?: number,
      array?: Article[],
      usePreferences: boolean = usePreferencesAndAllergens,
      useArrangementsF = useArrangementsFilter,
      useArticlegroupsF = useArticlegroupsFilter
    ) => {
      if (articleOrderHoursState[article.id] === false) {
        return false;
      }

      if (ageCheckIsDenied && article.requireAge && article.requireAge > 0) {
        return false;
      }

      if (article.blocked) {
        return false;
      }

      if (isQr() && useArrangements && useArrangementsF) {
        if (arrangement && !isArticleAvailableInArrangement(article, arrangement)) {
          return false;
        }
        if (arrangement && "arrangementArticleIds" in arrangement && arrangement.arrangementArticleIds[article.id]) {
          return false; // Arrangement identifiers filteren
        }
      }

      if (serviceRequestArticles[article.id]) {
        return false;
      }

      if (articleArticlegroupsMap[article.id] == null || articleArticlegroupsMap[article.id].length === 0) {
        // Exclude articles which are not found in an active articlegroup, like option articles
        // return false;
      }

      if (
        useArticlegroupsF &&
        articleArticlegroupsMap[article.id]?.every((articlegroupId) => {
          return !articlegroupsMap[articlegroupId].isVisibleInJamezz;
        })
      ) {
        return false;
      }

      if (
        useArticlegroupsF &&
        articleArticlegroupsMap[article.id]?.every((articlegroupId) => {
          return articlegroupOrderHoursState[articlegroupId] === false;
        })
      ) {
        return false;
      }

      if (usePreferencesAndAllergens) {
        if (allergens.length > 0 && hideItemsBySelectedAllergens) {
          if (article.menuTagIds.some((menuTagId) => allergens.some((allergen) => allergen.id == menuTagId))) {
            return false;
          }
        }

        if (preferences.length > 0 && usePreferences) {
          if (article.menuTagIds.every((menuTagId) => !preferences.find((preference) => preference.id == menuTagId))) {
            return false;
          }
        }
      }

      // Find an option group which has minCount > 0 and does not have any options available, this article would fail to be accepted in the pos, so filter it out.
      if (
        article.optionGroupIds.some((optionGroupId) => {
          const optionGroup = optionGroupsMap[optionGroupId];
          if (optionGroup && getPropertyOfOptionGroup(optionGroup, article, "minCount") > 0) {
            if (
              optionGroup.optionIds.every((optionId) => {
                const option = articlesMap[optionId];
                if (!option || !articleFilter(option, undefined, undefined, false, false, false)) {
                  return true;
                } else {
                  return false;
                }
              })
            ) {
              return true;
            }
          }
          return false;
        })
      ) {
        return false;
      }

      return true;
    },
    [
      articleOrderHoursState,
      ageCheckIsDenied,
      useArrangements,
      serviceRequestArticles,
      articleArticlegroupsMap,
      allergens,
      hideItemsBySelectedAllergens,
      preferences,
      arrangement,
      isArticleAvailableInArrangement,
      articlegroupsMap,
      articlegroupOrderHoursState,
      articlesMap,
      optionGroupsMap,
      usePreferencesAndAllergens,
      useArrangementsFilter,
      useArticlegroupsFilter,
    ]
  );

  return articleFilter;
}

export function useArticlegroupFilter() {
  const articlegroupOrderHoursState = useAppSelector((state) => state.menuState.articlegroupOrderHours);
  return useCallback(
    (articlegroup: Articlegroup) => {
      if (!articlegroup.isVisibleInJamezz) {
        return false;
      }
      if (!articlegroup.showInCategoryMenu) {
        return false;
      }

      if (articlegroupOrderHoursState[articlegroup.id] === false) {
        return false;
      }

      return true;
    },
    [articlegroupOrderHoursState]
  );
}

export default useMenuFilters;
