import { Button, SxProps } from "@mui/material";
import useKioskSendCheckout2 from "../utils/useKioskSendCheckout2";
import { ReactNode } from "react";
import { Theme } from "@mui/material/styles";
import { useAskIfUserWantsReceipt } from "./askForReceiptDialog";
import { useAgeCheckByEmployee } from "./ConfirmAgeByEmployeeDialog";
import { AgeCheckResult } from "../../global/utils/ageCheck/ageCheckUtils";
import { useBetweenOrderPage } from "../../global/components/BetweenOrderDialog";
import useShoppingCartConfirmationCheck from "../../global/utils/ageCheck/useShoppingCartConfirmationCheck";
import { useAppSelector } from "../../global/utils/redux/store";
import useTippingAsync from "../pages/CheckoutPage/TippingAtCheckout.tsx";
import { useAccountSubTotalValue } from "../../global/utils/redux/accountSlice.tsx";

interface Props {
  isValid: boolean;
  children: ReactNode;
  sx?: SxProps<Theme>;
}

export default function SendOrderButton(props: Props) {
  const isValid = props.isValid;
  const items = useAppSelector((state) => state.shoppingCart.items);
  const { sendOrder } = useKioskSendCheckout2();
  const askForReceipt = useAskIfUserWantsReceipt();
  const confirmAgeByEmployee = useAgeCheckByEmployee();
  const handleBetweenOrder = useBetweenOrderPage();
  const checkShoppingCart = useShoppingCartConfirmationCheck();
  const subTotalAmount = useAccountSubTotalValue();
  const { tipComponent, askForTip } = useTippingAsync(subTotalAmount);

  return (
    <>
      {tipComponent}
      <Button
        data-cy="btn-send-order"
        sx={{
          margin: 4,
          marginBottom: 8,
          backgroundColor: "primary.contrastText",
          color: "primary.light",
          borderRadius: "30px",
          fontSize: 48,
          fontWeight: 800,
          padding: "20px 48px",
          ...props.sx,
        }}
        variant={"contained"}
        type={"submit"}
        size={"large"}
        className={"JS-SendOrderButton-Root"}
        disabled={!isValid || items.length === 0}
        onClick={async (e) => {
          e.preventDefault();
          const ageConfirmed = await confirmAgeByEmployee();
          if (ageConfirmed !== AgeCheckResult.Accepted) {
            return;
          }

          await handleBetweenOrder();
          await askForReceipt();
          await askForTip();

          const shoppingCartConfirmation = await checkShoppingCart();
          if (shoppingCartConfirmation === true || shoppingCartConfirmation.response === "APPROVED") {
            sendOrder();
          }
        }}
      >
        {props.children}
      </Button>
    </>
  );
}
