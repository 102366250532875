import { ButtonBase, Checkbox, Collapse, Divider } from "@mui/material";

import OrderOptionGroup, { isMoreValid } from "../../../../global/utils/models/order/OrderOptionGroup";
import OrderArticle from "../../../../global/utils/models/order/OrderArticle";
import OptionGroupContent from "../OptionGroups/OptionGroupContent";
import OptionItemNameAndPrice from "./OptionItemNameAndPrice";
import useMenuTagsForArticle from "../../../../global/utils/hooks/useMenuTagsForArticle";
import Box from "@mui/material/Box";
import MenuTag from "../../../../global/utils/models/menu/MenuTag";
import MenuTagView from "../../../components/MenuTagView";
import _ from "lodash";
import useSalesareaType, { SalesareaType } from "../../../../global/utils/hooks/useSalesareaType";
import clsx from "clsx";
import { Info } from "@mui/icons-material";
import { useIntl } from "react-intl";
import JTooltip from "../../../../kiosk/components/JamezzMUI/JTooltip";
import { getPropertyOfOptionGroup } from "../../../../global/utils/models/menu/OptionGroup.ts";

interface Props {
  orderArticle: OrderArticle;
  orderOptionGroup: OrderOptionGroup;
  onChange: (orderArticle: OrderArticle) => void;
  isFirstOptionInGroup: boolean;
  isLastOptionInGroup: boolean;
}

function OptionItemOptional(props: Props) {
  const checked = props.orderArticle.count > 0;
  const menuTags = useMenuTagsForArticle(props.orderArticle.article);
  const salesareaType = useSalesareaType();

  const isNotAvailable = props.orderArticle.article.isNotAvailable || props.orderArticle.article.isOutOfStock;
  const intl = useIntl();

  return (
    <>
      {!props.isFirstOptionInGroup && (
        <Box sx={{ marginX: 2 }}>
          <Divider sx={{ width: 1, marginY: 1 }} />
        </Box>
      )}

      <JTooltip title={isNotAvailable ? intl.formatMessage({ id: "OptionItem.notAvailable" }) : ""}>
        <ButtonBase
          component={"div"}
          sx={{
            width: 1,
            borderRadius: 4,
            display: "flex",
            flexDirection: "column",
            paddingLeft: 2,
            marginTop: props.isFirstOptionInGroup ? 1 : undefined,
            marginBottom: props.isLastOptionInGroup ? 1 : undefined,
            opacity: isNotAvailable ? 0.5 : "auto",
          }}
          disableTouchRipple={isNotAvailable}
          onClick={() => {
            if (isNotAvailable) return;
            if (isMoreValid(props.orderOptionGroup, props.orderArticle.article, checked ? -1 : 1)) {
              const copy = _.cloneDeep(props.orderArticle);
              copy.count = checked ? 0 : 1;
              props.onChange(copy);
            }
          }}
        >
          <Box sx={{ display: "flex", width: 1, flex: 1 }}>
            <OptionItemNameAndPrice orderArticle={props.orderArticle} />
            <Box
              display={"flex"}
              alignItems={"center"}
              className={"JS-OptionItemOptional-CheckboxWrapper"}
              sx={{ visibility: salesareaType === SalesareaType.CATALOG ? "hidden" : null, marginRight: 2 }}
            >
              {isNotAvailable ? (
                <Info
                  sx={{
                    marginRight: "9px",
                  }}
                />
              ) : (
                <Checkbox
                  className={clsx(
                    "JS-OptionItemOptional-Checkbox",
                    checked ? "JS-OptionItemOptional-Checkbox-checked" : null
                  )}
                  checked={checked}
                  color={"primary"}
                />
              )}
            </Box>
          </Box>
        </ButtonBase>
      </JTooltip>
      {menuTags?.length > 0 && (
        <Box sx={{ display: "flex", flexWrap: "wrap", marginLeft: 2, alignSelf: "stretch" }}>
          {menuTags.map((tag: MenuTag) => {
            return <MenuTagView key={tag.id} menuTag={tag} width={24} />;
          })}
        </Box>
      )}

      <Collapse in={checked} sx={{ width: 1 }}>
        {props.orderArticle.orderOptionGroups
          .filter(
            (orderOptionGroup) =>
              !props.orderOptionGroup.optionGroup.isUpsell ||
              getPropertyOfOptionGroup(orderOptionGroup.optionGroup, props.orderArticle.article, "minCount") >= 1
          )
          .map((orderOptionGroup, index) => (
            <Box key={index + "-" + orderOptionGroup.id} sx={{ marginLeft: 2 }}>
              <OptionGroupContent
                orderArticle={props.orderArticle}
                orderOptionGroup={orderOptionGroup}
                onChange={(orderOptionGroup) => {
                  const copy = _.cloneDeep(props.orderArticle);
                  copy.orderOptionGroups[index] = orderOptionGroup;
                  props.onChange(copy);
                }}
              />
            </Box>
          ))}
      </Collapse>
    </>
  );
}

export default OptionItemOptional;
