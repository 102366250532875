import { useCallback, useEffect, useMemo } from "react";
import { useLocation } from "react-router";
import useInputListener from "../../global/utils/useInputListener";
import { useDialog } from "../../global/utils/dialog/DialogProvider";
import { createPath, routes } from "../../global/utils/config";
import useSalesareaIsOpen from "../../global/utils/useSalesareaIsOpen";
import useOrderModeSelector from "../components/OrderModeSelector/useOrderModeSelector";
import PleaseFirstMakeAChoiceDialog from "../components/BarCodeScanner/PleaseFirstMakeAChoiceDialog";
import store, { useAppSelector } from "../../global/utils/redux/store";
import usePriceKeyChecker from "./usePriceKeyChecker";
import useBarCodeChecker from "./useBarCodeChecker";
import EancodeUnknownDialog from "../components/BarCodeScanner/EancodeUnknownDialog";
import SlideUpTransition from "../../global/components/SlideUpTransition";
import useEmployeeCodes from "../hooks/useEmployeeCodes";
import { StringParam, useQueryParams } from "use-query-params";
import { debounce } from "lodash";
import useVouchers from "../../global/utils/useVouchers";
import FormattedMessageJamezz from "../../global/components/FormattedMessageJamezz";
import { toast } from "react-toastify";
import { voucherCodeFromScannedCode } from "../../global/utils/vouchers/Voucher";
import { piggyGiftcardAdded, piggyRewardsCardScanned } from "../../global/utils/redux/piggySlice";
import { isLoading } from "../../global/modules/LoadingScreen/loadingScreen.ts";
import { GiftcardResponse } from "../../types/shared/piggy.ts";
import { useLazyFetchGiftcardQuery } from "../../global/utils/redux/api/piggyApi.ts";
import { useLazyContactByIdentifierQuery } from "../../global/utils/redux/api/piggyAuthApi.ts";

// Sometimes, the scanner scans the same barcode or qr code in very quick succession
// Debounce these instances to prevent duplicate barcode entry handling
export const DOUBLE_SCAN_PREVENTION_DEBOUNCE_MS = 1250;

export default function KioskInputListener() {
  const { orderModes } = useOrderModeSelector();

  const orderModesLength = useMemo(() => {
    return orderModes?.length ?? 0;
  }, [orderModes]);
  const [fetchPiggyGiftcard] = useLazyFetchGiftcardQuery();
  const [fetchContactByUuid] = useLazyContactByIdentifierQuery();
  const { openDialog } = useDialog();
  const { pathname } = useLocation();
  const isOpen = useSalesareaIsOpen();
  const priceKeyChecker = usePriceKeyChecker();
  const barCodeChecker = useBarCodeChecker();

  const { checkVoucherCode } = useVouchers({ withLoading: false });

  const employeeCodes = useEmployeeCodes();

  const isOnHomePage = useMemo(() => {
    return pathname === createPath(routes.kiosk.rootPath);
  }, [pathname]);
  const isOnMenuPage = useMemo(() => {
    return pathname === createPath(routes.kiosk.menuPath);
  }, [pathname]);

  const processBuffer = useCallback(
    (buffer: string) => {
      if (store.getState().global.eancodeUnknownDialogIsOpen || !store.getState().global.scanner_is_enabled) {
        return;
      }

      const barcodeScannerEnabled =
        store.getState().global.salesarea?.custom_data?.kiosk?.enable_product_barcode_scanner ?? true;
      if (((orderModesLength ?? 1) > 1 || !barcodeScannerEnabled) && isOnHomePage) {
        // check if ordermode selection must be done before enable scanning barcodes
        openDialog({ children: <PleaseFirstMakeAChoiceDialog type={"NEED_TO_SELECT_ORDERMODE"} /> });
        return;
      }

      if (isOpen && (isOnMenuPage || isOnHomePage)) {
        if (buffer.length >= 3 && buffer.substring(0, 3) === "QRD") {
          const code = voucherCodeFromScannedCode(buffer);
          if (code) {
            checkVoucherCode(code)
              .then(() => {
                toast.success(<FormattedMessageJamezz id="Voucher.messages.added-successfully" />);
              })
              .catch((err) => {
                if ("result" in err) {
                  toast.error(<FormattedMessageJamezz id={err.result.message} />);
                } else {
                  // fallback error message if server fails to process the request
                  toast.error(<FormattedMessageJamezz id="Vouchers.errors.invalid-response" />);
                }
              });
          }
        } else if (barcodeScannerEnabled && barCodeChecker(buffer)) {
          return;
        } else {
          priceKeyChecker(buffer).catch((err) => {
            console.log(err);
            if (barcodeScannerEnabled && store.getState().global.salesarea?.useAccountServer) {
              // eancode unknown!
              openDialog({
                children: <EancodeUnknownDialog eancode={buffer} />,
                TransitionComponent: SlideUpTransition,
                disabledBackdropClick: Object.keys(employeeCodes).length > 0,
              });
            }
          });

          if (!isOnHomePage && !isLoading()) {
            checkVoucherCode(buffer)
              .then(() => {
                toast.success(<FormattedMessageJamezz id="Voucher.messages.added-successfully" />);
              })
              .catch((err) => console.log(err));
          }
          if (
            !isOnHomePage &&
            (store.getState().global.salesarea.piggy.vouchers.enabled ||
              store.getState().global.salesarea.piggy.loyalty.enabled) &&
            !isLoading()
          ) {
            const date = new Date();
            fetchContactByUuid({ identifier: buffer, timestamp: +date, create_if_not_exists: false }).then(
              (response) => {
                if (response.data?.data.uuid) {
                  store.dispatch(piggyRewardsCardScanned({ code: buffer, timestamp: +date }));
                }
              }
            );
          }

          if (!isOnHomePage && store.getState().global.salesarea.piggy.giftcards.enabled && !isLoading()) {
            fetchPiggyGiftcard(buffer)
              .then((response) => {
                if (response.data && !response.error) {
                  store.dispatch(piggyGiftcardAdded((response.data as GiftcardResponse).data.giftcard));
                }
              })
              .finally(() => {});
          }
        }
      }
    },
    [
      barCodeChecker,
      checkVoucherCode,
      employeeCodes,
      fetchPiggyGiftcard,
      fetchContactByUuid,
      isOnHomePage,
      isOnMenuPage,
      isOpen,
      openDialog,
      orderModesLength,
      priceKeyChecker,
    ]
  );

  const processDebouncedMethod = debounce(processBuffer, DOUBLE_SCAN_PREVENTION_DEBOUNCE_MS, { leading: true });

  const onSubmit = useCallback(processDebouncedMethod, [processDebouncedMethod]);

  window.onScanCode = onSubmit;
  useInputListener(onSubmit);

  useUpdateSelectedArticlegroupParam();

  return null;
}

function useUpdateSelectedArticlegroupParam() {
  const [, setQueryParam] = useQueryParams({ selectedArticlegroupName: StringParam });
  const selectedArticlegroup = useAppSelector((state) => state.menu.selectedArticlegroup);
  useEffect(() => {
    if (store.getState().global.salesarea.custom_data?.kiosk?.selected_articlegroup_name_in_kiosk_url) {
      setQueryParam({ selectedArticlegroupName: selectedArticlegroup?.name }, "replaceIn");
    }
  }, [selectedArticlegroup, setQueryParam]);
}
