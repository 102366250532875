import { MutableRefObject, useCallback, useEffect, useMemo, useState } from "react";
import { throttle } from "lodash";
import Box from "@mui/material/Box";
import ArticleContent from "./ArticleContent/ArticleContent";
import { CircularProgress } from "@mui/material";
import Article from "../../../global/utils/models/menu/Article.ts";

interface Props {
  items: Article[];
  numberOfItemsToRenderInitially: number;
  numberOfItemsToAddEachLoad: number;
  scrollContent: MutableRefObject<any>;
  setRenderUpToArticleIndexRef?: MutableRefObject<any>;
  width: number;
  pixelHeightCartButton?: string;
}

const throttleRender = throttle((scrollContent, setRenderUpToItemIndex, numberOfItemsToAddEachLoad, items) => {
  if (scrollContent.current) {
    const scrollTop = scrollContent.current.scrollTop;
    const scrollHeight = scrollContent.current.scrollHeight;
    const clientHeight = scrollContent.current.clientHeight;
    if (scrollHeight - scrollTop - 3 * clientHeight < 0) {
      setRenderUpToItemIndex((index: number) => {
        return Math.min(index + numberOfItemsToAddEachLoad, items.length - 1);
      });
    }
  }
}, 1000);

export default function LazyLoadItems(props: Props) {
  const [renderUpToItemIndex, setRenderUpToItemIndex] = useState(props.numberOfItemsToRenderInitially);

  useEffect(() => {
    if (props.setRenderUpToArticleIndexRef) {
      props.setRenderUpToArticleIndexRef.current = setRenderUpToItemIndex;
    }
  }, [props.setRenderUpToArticleIndexRef]);

  const lastItemRendered = useMemo(() => {
    return renderUpToItemIndex >= props.items.length - 1;
  }, [renderUpToItemIndex, props.items]);

  const onScroll = useCallback(() => {
    throttleRender(props.scrollContent, setRenderUpToItemIndex, props.numberOfItemsToAddEachLoad, props.items);
  }, [props.items, props.numberOfItemsToAddEachLoad, props.scrollContent]);

  useEffect(() => {
    const element = props.scrollContent.current;
    if (element) {
      element.addEventListener("scroll", onScroll);
    }
    return () => {
      if (element) {
        element.removeEventListener("scroll", onScroll);
      }
    };
  }, [onScroll, props.scrollContent]);

  useEffect(() => {
    setTimeout(() => {
      throttleRender(props.scrollContent, setRenderUpToItemIndex, props.numberOfItemsToAddEachLoad, props.items);
    }, 500);
  }, [props.items, props.numberOfItemsToAddEachLoad, props.scrollContent]);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          width: 1,
        }}
      >
        {props.items.slice(0, renderUpToItemIndex + 1).map((item) => (
          <Box key={item.id} sx={{ width: props.width }} data-cy="search-result">
            <ArticleContent article={item} />
          </Box>
        ))}
      </Box>

      {!lastItemRendered ? (
        <Box sx={{ width: 1, textAlign: "center", paddingBottom: props.pixelHeightCartButton ?? null }}>
          <CircularProgress size={96} />
        </Box>
      ) : null}
    </>
  );
}
