import { Tabs } from "@mui/material";
import { useEffect } from "react";
import Articlegroup from "../../../global/utils/models/menu/Articlegroup";
import ArticlegroupTab from "./ArticelgroupTabBar/ArticlegroupTab";
import store, { useAppSelector } from "../../../global/utils/redux/store";
import { setSelectedArticlegroup } from "../../../global/utils/redux/menuSlice";
import { selectArticlegroupsMap } from "../../../global/utils/redux/selectors/selectArticlegroupsMap";

interface Props {
  articlegroups: Articlegroup[];
}

function ArticlegroupTabBar(props: Props) {
  const background = useAppSelector(
    (state) =>
      state.global.salesarea?.media_urls?.extra_settings__qrv5_list_of_menus_background?.[0]?.conversions?.optimized
        ?.url
  );
  return (
    <>
      <FixSelectedArticlegroup articlegroups={props.articlegroups} />
      <Tabs
        variant={"scrollable"}
        scrollButtons={true}
        value={false}
        // centered={true}
        textColor={"inherit"}
        className={"JS-ArticlegroupTabBar-Root"}
        sx={{
          "& .MuiTabs-indicator": { display: "none" },
          overflow: "hidden",
          display: "flex",
          justifyContent: "center",
          "& .MuiTabs-scrollableX": { flex: "unset" },
          backgroundColor: background ? undefined : "background.default",
          background: background ? `url(${background})` : undefined,
        }}
      >
        {props.articlegroups?.map((articlegroup) => {
          if (articlegroup.showInCategoryMenu) {
            return <ArticlegroupTab key={articlegroup.id} articlegroup={articlegroup} />;
          }
        })}
      </Tabs>
    </>
  );
}

export function FixSelectedArticlegroup(props: Props) {
  const articlegroupsMap = useAppSelector(selectArticlegroupsMap);
  useEffect(() => {
    const selectedArticlegroup = store.getState().menu.selectedArticlegroup; // Do not move outside useEffect
    const articlegroup = props.articlegroups.find((articlegroup) => articlegroup.id === selectedArticlegroup?.id);
    if (!articlegroup && props.articlegroups[0]) {
      store.dispatch(setSelectedArticlegroup({ articlegroup: props.articlegroups[0], articlegroupsMap }));
    }
  }, [props.articlegroups, articlegroupsMap]);
  return null;
}

export default ArticlegroupTabBar;
