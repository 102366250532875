import { ButtonBase, Collapse, Divider, IconButton, Typography } from "@mui/material";
import { Add, Info, Remove } from "@mui/icons-material";
import { useCallback, useMemo } from "react";
import OrderOptionGroup, { isMoreValid } from "../../../../global/utils/models/order/OrderOptionGroup";
import OrderArticle, { isOrderArticleValidAfterSum } from "../../../../global/utils/models/order/OrderArticle";
import OptionGroupContent from "../OptionGroups/OptionGroupContent";
import OptionItemNameAndPrice from "./OptionItemNameAndPrice";
import useMenuTagsForArticle from "../../../../global/utils/hooks/useMenuTagsForArticle";
import Box from "@mui/material/Box";
import MenuTag from "../../../../global/utils/models/menu/MenuTag";
import MenuTagView from "../../../components/MenuTagView";
import _ from "lodash";
import useSalesareaType, { SalesareaType } from "../../../../global/utils/hooks/useSalesareaType";
import { useIntl } from "react-intl";
import JTooltip from "../../../../kiosk/components/JamezzMUI/JTooltip";
import { getPropertyOfOptionGroup } from "../../../../global/utils/models/menu/OptionGroup.ts";

interface Props {
  orderOptionGroup: OrderOptionGroup;
  orderArticle: OrderArticle;
  index: number;
  onChange: (orderArticle: OrderArticle) => void;
  isFirstOptionInGroup: boolean;
  isLastOptionInGroup: boolean;
}

function OptionItemCountable(props: Props) {
  const { orderArticle, onChange } = props;
  const menuTags = useMenuTagsForArticle(props.orderArticle.article);
  const salesareaType = useSalesareaType();
  const count = orderArticle.count;
  const addCount = useCallback(
    (addCount: number) => {
      const copy = _.cloneDeep(props.orderArticle);
      copy.count += addCount;
      onChange(copy);
    },
    [props.orderArticle, onChange]
  );
  const intl = useIntl();

  const isValidAfterCount = useCallback(
    (count: number) => {
      return (
        isMoreValid(props.orderOptionGroup, props.orderArticle.article, count) &&
        isOrderArticleValidAfterSum(props.orderArticle, count)
      );
    },
    [props.orderArticle, props.orderOptionGroup]
  );

  const isValidAfterPlusOne = useMemo(() => {
    return isValidAfterCount(1);
  }, [isValidAfterCount]);

  const isValidAfterMinusOne = useMemo(() => {
    return isValidAfterCount(-1);
  }, [isValidAfterCount]);

  const isNotAvailable = props.orderArticle.article.isNotAvailable || props.orderArticle.article.isOutOfStock;

  return (
    <>
      {!props.isFirstOptionInGroup ? (
        <Box sx={{ marginX: 2 }}>
          <Divider sx={{ width: 1, marginY: 1 }} />
        </Box>
      ) : null}
      <JTooltip title={isNotAvailable ? intl.formatMessage({ id: "OptionItem.notAvailable" }) : ""}>
        <ButtonBase
          component={"div"}
          sx={{
            display: "flex",
            borderRadius: 4,
            alignItems: "center",
            width: 1,
            paddingX: 2,
            marginTop: props.isFirstOptionInGroup ? 1 : undefined,
            marginBottom: props.isLastOptionInGroup ? 1 : undefined,
            opacity: isNotAvailable ? 0.5 : "auto",
          }}
          disableTouchRipple={isNotAvailable}
          onClick={() => {
            if (isNotAvailable) return;
            if (isValidAfterPlusOne) {
              addCount(1);
            } else {
              // TODO
            }
          }}
        >
          <OptionItemNameAndPrice orderArticle={props.orderArticle} />
          {isNotAvailable ? (
            <Info
              sx={{
                marginRight: "9px",
              }}
            />
          ) : (
            <Box
              sx={{
                marginLeft: 0,
                visibility: salesareaType === SalesareaType.CATALOG ? "hidden" : null,
                display: "flex",
                alignItems: "center",
                marginRight: 1,
              }}
            >
              <IconButton
                sx={{
                  padding: 0,
                  visibility: isValidAfterMinusOne ? null : "collapse",
                  marginRight: 2,
                }}
                color={"primary"}
                size={"large"}
                onClick={(e) => {
                  e.stopPropagation();
                  if (isValidAfterMinusOne) {
                    addCount(-1);
                  } else {
                    // TODO
                  }
                }}
              >
                <Remove />
              </IconButton>
              <Typography
                sx={{ flex: 1, textAlign: "center", visibility: count > 0 ? null : "collapse", marginRight: 2 }}
              >
                {count}
              </Typography>
              <IconButton
                sx={{ padding: 0 }}
                color={"primary"}
                size={"large"}
                onClick={() => {
                  if (isValidAfterPlusOne) {
                    addCount(1);
                  } else {
                    // TODO
                  }
                }}
              >
                <Add />
              </IconButton>
            </Box>
          )}{" "}
        </ButtonBase>
      </JTooltip>
      {menuTags?.length > 0 ? (
        <Box sx={{ display: "flex", flexWrap: "wrap", marginLeft: 2 }}>
          {menuTags.map((tag: MenuTag) => {
            return <MenuTagView key={tag.id} menuTag={tag} width={24} />;
          })}
        </Box>
      ) : null}

      <Collapse in={count > 0} sx={{ width: 1 }}>
        {props.orderArticle.orderOptionGroups
          .filter(
            (orderOptionGroup) =>
              !props.orderOptionGroup.optionGroup.isUpsell ||
              getPropertyOfOptionGroup(orderOptionGroup.optionGroup, props.orderArticle.article, "minCount") >= 1
          )
          .map((orderOptionGroup, index) => (
            <OptionGroupContent
              key={index + "-" + orderOptionGroup.id}
              orderArticle={props.orderArticle}
              orderOptionGroup={orderOptionGroup}
              onChange={(orderOptionGroup) => {
                const copy = _.cloneDeep(props.orderArticle);
                copy.orderOptionGroups[index] = orderOptionGroup;
                props.onChange(copy);
              }}
            />
          ))}
      </Collapse>
    </>
  );
}

export default OptionItemCountable;
