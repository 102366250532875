import { useEffect, useMemo, useRef, useState } from "react";
import { Skeleton } from "@mui/material";

enum LoadingState {
  INIT,
  IS_LOADING,
  LOADED,
  ERROR,
}

export default function Image({ style, srcSet, ...rest }: React.HTMLProps<HTMLImageElement>) {
  const [isLoading, setIsLoading] = useState<LoadingState>(LoadingState.INIT);
  const loaded = useRef(Boolean(srcSet));
  const imgRef = useRef<any>();
  const isResponsiveImage = Boolean(srcSet);
  const [sizes, setSizes] = useState(isResponsiveImage ? "1px" : undefined);
  const [loadedResponsiveSize, setLoadedResponsiveSize] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      if (!loaded.current) {
        setIsLoading(LoadingState.IS_LOADING);
      }
    }, 100);
  }, []);

  useEffect(() => {
    if (imgRef.current) {
      if (imgRef.current) {
        const size = imgRef.current.getBoundingClientRect().width;
        if (!size) return;
        setSizes(Math.ceil((size / window.innerWidth) * 100) + "vw");
      }
    }
  }, []);

  const skeleton = useMemo(() => {
    // @ts-ignore
    return <Skeleton {...rest} style={style} />;
  }, [rest, style]);

  return (
    <>
      {isLoading === LoadingState.IS_LOADING || (isResponsiveImage && !loadedResponsiveSize) ? skeleton : null}

      <img
        {...rest}
        srcSet={
          isResponsiveImage
            ? srcSet + ", data:image/webp;base64,UklGRiQAAABXRUJQVlA4IBgAAAAwAQCdASoBAAEADMDOJZwAA3AA/umeAAA= 1w"
            : undefined
        }
        ref={imgRef}
        style={{
          display: isLoading === LoadingState.IS_LOADING || isLoading === LoadingState.ERROR ? "none" : undefined,
          visibility: isResponsiveImage && !loadedResponsiveSize ? "hidden" : undefined,
          ...style,
        }}
        sizes={sizes}
        onLoad={() => {
          if (isResponsiveImage && sizes != "1px") {
            setLoadedResponsiveSize(true);
          }
          loaded.current = true;
          setIsLoading(LoadingState.LOADED);
        }}
        onError={() => {
          loaded.current = true;
          setIsLoading(LoadingState.ERROR);
        }}
      />
    </>
  );
}
