import { useMemo, useState } from "react";
import OptionItemOptional from "../OptionItems/OptionItemOptional";
import OrderOptionGroup from "../../../../global/utils/models/order/OrderOptionGroup";
import { Button, Collapse, Paper } from "@mui/material";
import { Refresh } from "@mui/icons-material";
import FormattedMessageJamezz from "../../../../global/components/FormattedMessageJamezz";
import Box from "@mui/material/Box";
import _ from "lodash";
import { optionGroupGridStyles } from "./OptionGroupCountable";

interface Props {
  orderOptionGroup: OrderOptionGroup;
  onChange: (orderOptionGroup: OrderOptionGroup) => void;
}

function OptionGroupOptional(props: Props) {
  const showMaxNumberOfItems = useMemo(() => {
    if (props.orderOptionGroup.optionGroup.showMaxNumberOfItems == null) {
      return props.orderOptionGroup.orderArticles.length;
    }
    return props.orderOptionGroup.optionGroup.showMaxNumberOfItems;
  }, [props.orderOptionGroup]);

  const [expand, setExpand] = useState(showMaxNumberOfItems === 0);

  const numberOfItemsPerRowOfOrderArticles = 4;

  return (
    <>
      {_.chunk(
        props.orderOptionGroup.orderArticles.slice(0, showMaxNumberOfItems),
        numberOfItemsPerRowOfOrderArticles
      ).map((orderArticles, articleRowIndex) => (
        <Box sx={optionGroupGridStyles(orderArticles.length, 4)}>
          {orderArticles.map((orderArticle, index) => (
            <OptionItemOptional
              orderArticle={orderArticle}
              orderOptionGroup={props.orderOptionGroup}
              key={orderArticle.article.id}
              columnIndex={index % orderArticles.length}
              rowIndex={Math.floor(index / orderArticles.length)}
              onChange={(orderArticle) => {
                const copy = JSON.parse(JSON.stringify(props.orderOptionGroup)) as OrderOptionGroup;
                copy.orderArticles[articleRowIndex * numberOfItemsPerRowOfOrderArticles + index] = orderArticle;
                props.onChange(copy);
              }}
            />
          ))}
        </Box>
      ))}
      {/*<Grid item xs={12}>*/}
      <Collapse in={expand} unmountOnExit sx={{ width: 1 }}>
        {_.chunk(
          props.orderOptionGroup.orderArticles.slice(showMaxNumberOfItems, props.orderOptionGroup.orderArticles.length),
          numberOfItemsPerRowOfOrderArticles
        ).map((orderArticles, articleRowIndex) => (
          <Box sx={optionGroupGridStyles(orderArticles.length, 4)}>
            {orderArticles.map((orderArticle, index) => (
              <OptionItemOptional
                orderArticle={orderArticle}
                orderOptionGroup={props.orderOptionGroup}
                key={orderArticle.article.id}
                columnIndex={index % orderArticles.length}
                rowIndex={Math.floor(index / orderArticles.length)}
                onChange={(orderArticle) => {
                  const copy = JSON.parse(JSON.stringify(props.orderOptionGroup)) as OrderOptionGroup;
                  copy.orderArticles[
                    articleRowIndex * numberOfItemsPerRowOfOrderArticles + index + showMaxNumberOfItems
                  ] = orderArticle;
                  props.onChange(copy);
                }}
              />
            ))}
          </Box>
        ))}
      </Collapse>
      {/*</Grid>*/}
      {showMaxNumberOfItems < props.orderOptionGroup.orderArticles.length ? (
        // <Grid item xs={12} container justifyContent={"center"}>
        <Collapse in={!expand} unmountOnExit sx={{ width: 1 }}>
          <Button
            component={Paper}
            startIcon={<Refresh />}
            sx={{
              marginX: 10,
              marginY: 1,
              padding: 1,
              paddingX: 3,
              whiteSpace: "nowrap",
              backgroundColor: "background.paper",
              borderRadius: 3,
            }}
            color={"inherit"}
            onClick={() => {
              setExpand(true);
            }}
          >
            <FormattedMessageJamezz id={"Load more..."} />
          </Button>
        </Collapse>
      ) : // </Grid>
      null}
    </>
  );
}

export default OptionGroupOptional;
