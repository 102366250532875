import { LoadingButton, LoadingButtonProps } from "@mui/lab";
import {
  useFetchTableStateQuery,
  useRefreshTableStateMutation,
} from "../../../global/utils/redux/api/arrangementsApi.ts";
import { QueryStatus } from "@reduxjs/toolkit/query";
import { ReactNode } from "react";

export default function ArrangementReloadButton({
  children,
  sx = {},
}: {
  children: ReactNode;
  sx?: LoadingButtonProps["sx"];
}) {
  const { isFetching } = useFetchTableStateQuery();
  const [refreshTableState, result] = useRefreshTableStateMutation();

  return (
    <LoadingButton
      variant="contained"
      size="small"
      onClick={() => refreshTableState()}
      loading={isFetching || result.status == QueryStatus.pending}
      sx={sx}
    >
      {children}
    </LoadingButton>
  );
}
