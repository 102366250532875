import { useAppSelector } from "../../utils/redux/store";
import { selectTableIsOpenInPos } from "../../utils/hooks/useSalesareaType";
import { Box, Collapse, Typography } from "@mui/material";
import { useEffect } from "react";
import { useCustomTexts } from "../../utils/useCustomTexts";
import FormattedMessageJamezz from "../FormattedMessageJamezz";
import CloseDialogButtonQr from "../../../qr/components/CloseButtons/CloseDialogButtonQr";
import { Close } from "@mui/icons-material";
import { useDialog } from "../../utils/dialog/DialogProvider";

export default function TableIsNotOpenMessage() {
  const tableIsOpenInPos = useAppSelector(selectTableIsOpenInPos);
  const checkTableIsOpen = useAppSelector((state) => state.global.salesarea.checkTableIsOpen);

  const customTexts = useCustomTexts(["check_table_is_closed_message"]);

  if (checkTableIsOpen.enabled) {
    return (
      <Box>
        <Collapse in={tableIsOpenInPos === false}>
          <Typography textAlign={"center"} sx={{ py: 2, px: 3 }}>
            <div
              dangerouslySetInnerHTML={{
                __html:
                  customTexts["check_table_is_closed_message"] ??
                  (((<FormattedMessageJamezz id={"check_table_is_closed_message"} />) as unknown) as string),
              }}
            ></div>
          </Typography>
        </Collapse>
      </Box>
    );
  } else {
    return null;
  }
}

export function TableIsNotOpenMessageDialog() {
  const tableIsOpenInPos = useAppSelector((state) => state.global.tableIsOpenInPos);
  const { closeDialog } = useDialog();
  useEffect(() => {
    if (tableIsOpenInPos) {
      closeDialog();
    }
  }, [tableIsOpenInPos]);
  return (
    <>
      <Box sx={{ textAlign: "right" }}>
        <CloseDialogButtonQr icon={<Close />} />
      </Box>
      <TableIsNotOpenMessage />
    </>
  );
}
