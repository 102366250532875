import { useCallback } from "react";
import store from "../../../utils/redux/store.tsx";
import { selectArticleTriggers } from "../../../utils/redux/selectors/upsells/selectArticleTriggers.ts";
import { selectMenuTriggers } from "../../../utils/redux/selectors/upsells/selectMenuTriggers.ts";
import OrderArticle from "../../../utils/models/order/OrderArticle.ts";
import Rule from "../../../utils/automation/Rule.ts";
import _ from "lodash";

export default function useRulesForOrderArticle() {
  return useCallback((orderArticle: OrderArticle) => {
    const articleRules = selectArticleTriggers(store.getState());
    const menuRules = selectMenuTriggers(store.getState());

    const rules: Rule[] = [];
    if (orderArticle.articlegroup?.id) {
      menuRules[orderArticle.articlegroup?.id]?.forEach((rule: Rule) => {
        rules.push(rule);
      });
    }
    articleRules[orderArticle.article.id]?.forEach((rule: Rule) => {
      rules.push(rule);
    });

    return _.uniq(rules);
  }, []);
}
