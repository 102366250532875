import OrderArticle, { initOrderArticle } from "../models/order/OrderArticle";
import store from "../redux/store";
import _ from "lodash";
import { selectSumOfDiscountsPerCampaign } from "../redux/selectors/discountsPerCampaign";
import { selectDeliveryFeeAmount, selectTransactionFeeAmount } from "../redux/accountSlice";
import { initSystemProduct } from "../redux/shoppingCartSlice";
import { selectArticlesMap } from "../redux/selectors/selectArticlesMap";
import { selectCheckCalculatorDiscounts } from "../redux/selectors/selectCheckCalculatorDiscounts.ts";
import { selectVoucherOrderItems } from "../redux/selectors/selectVoucherOrderItems.tsx";

export default function addVariousItemsToOrder(list: OrderArticle[]) {
  addTipItemIfNeeded(list);
  addDeliveryItemIfNeeded(list);
  addTransactionFeeItemIfNeeded(list);
  addCheckCalculatorDiscountItemsIfNeeded(list);
}

export function addVariousItemsToVoucherOrderItems(list: OrderArticle[]) {
  addCampaignsDiscountItemIfNeeded(list);
  addVoucherItemsIfNeeded(list);
}

export function addTipItemIfNeeded(list: OrderArticle[]) {
  if (store.getState().account.credits.tipAmount > 0) {
    const articlesMap = selectArticlesMap(store.getState());
    const tipArticle = articlesMap[String(store.getState().global.salesarea.tipProductId)];

    if (tipArticle) {
      const copyArticle = _.cloneDeep(tipArticle);
      copyArticle.price = store.getState().account.credits.tipAmount;
      copyArticle.originalPrice = copyArticle.price;
      copyArticle.hasCustomizedPriceAndName = true;
      copyArticle.isTip = true;
      list.push(initOrderArticle(articlesMap, copyArticle, 1));
    }
  }
}

function addDeliveryItemIfNeeded(list: OrderArticle[]) {
  const deliveryConfig = store.getState().global.salesarea.delivery_settings;
  if (deliveryConfig.enabled) {
    const deliveryFeeAmount = selectDeliveryFeeAmount(store.getState());
    if (deliveryFeeAmount) {
      const delivery_product_id = deliveryConfig.delivery_costs_product_id;
      const newDeliveryCostsProduct = initSystemProduct(selectArticlesMap(store.getState()), delivery_product_id);
      newDeliveryCostsProduct.article.price = deliveryFeeAmount;
      newDeliveryCostsProduct.article.hasCustomizedPriceAndName = true;

      list.push(newDeliveryCostsProduct);
    }
  }
}
export function addTransactionFeeItemIfNeeded(list: OrderArticle[]) {
  const transactionCostConfig = store.getState().global.salesarea.transaction_costs;
  const transactionFeeAmount = selectTransactionFeeAmount(store.getState());
  if (transactionFeeAmount && transactionCostConfig?.enabled && transactionCostConfig.product_id) {
    const newTransactionCostsProduct = initSystemProduct(
      selectArticlesMap(store.getState()),
      transactionCostConfig.product_id
    );
    newTransactionCostsProduct.article.price = transactionFeeAmount;
    newTransactionCostsProduct.article.hasCustomizedPriceAndName = true;
    list.push(newTransactionCostsProduct);
  }
}

function addCampaignsDiscountItemIfNeeded(list: OrderArticle[]) {
  const custom_data = store.getState().global.salesarea.custom_data;
  const totalDiscountAmountOfCampaigns = selectSumOfDiscountsPerCampaign(store.getState());

  const articlesMap = selectArticlesMap(store.getState());
  if (
    custom_data?.boomi?.campaign_discount_product &&
    totalDiscountAmountOfCampaigns > 0 &&
    articlesMap[custom_data?.boomi?.campaign_discount_product]
  ) {
    list.push(
      initOrderArticle(
        articlesMap,
        {
          ...articlesMap[custom_data.boomi.campaign_discount_product],
          price: -totalDiscountAmountOfCampaigns,
          hasCustomizedPriceAndName: true,
        },
        1
      )
    );
  }
}

function addVoucherItemsIfNeeded(list: OrderArticle[]) {
  const voucherOrderItems = selectVoucherOrderItems(store.getState());
  voucherOrderItems.forEach((voucherOrderItem) => {
    list.push(voucherOrderItem);
  });
}

function addCheckCalculatorDiscountItemsIfNeeded(list: OrderArticle[]) {
  const items = selectCheckCalculatorDiscounts(store.getState());
  items?.forEach((item) => {
    list.push(item);
  });
}
