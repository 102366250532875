import { AppBar, Badge, Box, Button, IconButton, Toolbar, Typography } from "@mui/material";
import { FilterAlt } from "@mui/icons-material";
// @ts-ignore
import JamezzLogo from "../../assets/images/JamezzLogo.svg";
import React, { useMemo } from "react";
import { useDialog } from "../../global/utils/dialog/DialogProvider";
import SlideUpTransition from "./../../global/components/SlideUpTransition";
import useMenuTagFilters from "../../global/utils/menu/filters/useMenuTagFilters";
import Image from "../../global/components/Image";
import { useLocation } from "react-router";
import { createPath, routes } from "../../global/utils/config";
import { useAppSelector } from "../../global/utils/redux/store";
import useIsWidescreen from "../../global/utils/useIsWidescreen";
import isQr from "../../global/utils/helpers/isQr";
import { postAnalyticsEvent } from "../../global/utils/analytics/useAnalytics.ts";
import MenuFilterDialogV2 from "../pages/MenuTagFilterPage/MenuFilterDialogV2.tsx";
import MenuIcon from "@mui/icons-material/Menu";
import SideMenuDrawer from "./SideMenuDrawer/SideMenuDrawer.tsx";
import ServiceBottomDrawer from "./ServiceBottomDrawer/ServiceBottomDrawer.tsx";
import useServiceArticles from "../../global/components/useServiceArticles.tsx";
import FormattedMessageJamezz from "../../global/components/FormattedMessageJamezz.tsx";
import EmojiPeopleIcon from "@mui/icons-material/EmojiPeople";

function TopBar() {
  const [openSideDrawer, setOpenSideDrawer] = React.useState(false);
  const [openServiceDrawer, setOpenServiceDrawer] = React.useState(false);

  const serviceArticles = useServiceArticles();

  const background = useAppSelector(
    (state) =>
      state.global.salesarea?.media_urls?.extra_settings__qrv5_topbar_background?.[0]?.conversions?.optimized?.url
  );
  const { openDialog } = useDialog();
  const menuTags = useMenuTagFilters();
  const { pathname } = useLocation();
  const logoTopBar = useAppSelector(
    (state) => state.global.salesarea?.media_urls?.extra_settings__qrv5_logo_top_bar?.[0]?.conversions?.optimized?.url
  );

  const isOnMenuPage = useMemo(() => {
    return pathname === createPath(routes.qr.rootPath);
  }, [pathname]);

  const isOnReturnPage = useMemo(() => {
    return pathname === createPath(routes.qr.returnPath);
  }, [pathname]);

  const menuTagPreferences = useAppSelector((state) => state.menuState.menuTagPreferences);

  const numberOfPreferencesActive = useMemo(() => {
    let numberOfPreferencesActive = 0;

    Object.values(menuTagPreferences).forEach((preference) => {
      if (preference.checked) {
        numberOfPreferencesActive += 1;
      }
    });
    return numberOfPreferencesActive;
  }, [menuTagPreferences]);

  const isWidescreen = useIsWidescreen();
  const isCatalog = useAppSelector((state) => state.global.salesarea.salesarea_type === "CATALOG");

  return (
    <AppBar
      position={"sticky"}
      className={"JS-TopBar-AppBar"}
      sx={{
        display: isOnReturnPage ? "none" : null,
        width: !isCatalog && isWidescreen ? "calc(100vw - 450px)" : undefined,
        background: background ? `url(${background})` : undefined,
      }}
    >
      <SideMenuDrawer open={openSideDrawer} setOpen={setOpenSideDrawer} />
      <ServiceBottomDrawer open={openServiceDrawer} setOpen={setOpenServiceDrawer} />

      <Toolbar sx={{ height: 56 }}>
        <Box sx={{ position: "absolute", left: 16 }}>
          {/*<LanguageButton />*/}
          <IconButton
            data-cy={"side-drawer-menu-button"}
            size={"large"}
            edge={"start"}
            onClick={() => setOpenSideDrawer(true)}
          >
            <MenuIcon className={"JS-TopBar-MenuIcon"} />
          </IconButton>
        </Box>
        <Box sx={{ height: 1, flex: "1", textAlign: "center", padding: 1 }}>
          <Image
            key={logoTopBar ?? "jamezzlogo"}
            src={logoTopBar ?? JamezzLogo}
            className={"JS-TopBar-LogoImage"}
            style={{ height: "100%" }}
          />
        </Box>
        {serviceArticles.length ? (
          <Button
            sx={{
              position: "absolute",
              right: 16,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              cursor: "pointer",
              minWidth: "unset",
            }}
            data-cy={"open-service-drawer-button"}
            onClick={() => setOpenServiceDrawer(true)}
          >
            <EmojiPeopleIcon sx={{ color: "text.primary" }} className={"JS-TopBar-ServiceIcon"} />
            {/*<Image*/}
            {/*  style={{ width: "18px" }}*/}
            {/*  src={"https://jamezz.blob.core.windows.net/jamezz/appv5/assets/images/ober_icon.webp"}*/}
            {/*/>*/}
            <Typography
              className={"JS-TopBar-Service-Text"}
              sx={{
                fontSize: "0.55rem",
                color: "text.primary",
              }}
            >
              <FormattedMessageJamezz id={"Service"} />
            </Typography>
          </Button>
        ) : null}

        {serviceArticles.length === 0 && isOnMenuPage && menuTags.length > 0 ? (
          <IconButton
            sx={{ position: "absolute", right: 16 }}
            size={"large"}
            edge={"end"}
            onClick={() => {
              postAnalyticsEvent({
                category: "TopBar",
                action: "MenuFilterDialog",
              });
              openDialog({
                children: <MenuFilterDialogV2 />,
                fullScreen: !(isQr() && isWidescreen),
                TransitionComponent: SlideUpTransition,
              });
            }}
          >
            {numberOfPreferencesActive > 0 ? (
              <Badge badgeContent={numberOfPreferencesActive} color={"primary"}>
                <div>
                  <FilterAlt className={"JS-TopBar-AllergyIcon"} />
                </div>
              </Badge>
            ) : (
              <div>
                <FilterAlt className={"JS-TopBar-AllergyIcon"} />
              </div>
            )}
          </IconButton>
        ) : null}
      </Toolbar>
    </AppBar>
  );
}

export default TopBar;
