import {
  CeleryIcon,
  CrustaceanIcon,
  EggIcon,
  FishIcon,
  GlutenIcon,
  LupinIcon,
  MilkIcon,
  MolluscIcon,
  MustardIcon,
  NutsIcon,
  PeanutIcon,
  SesameIcon,
  SoyaIcon,
  SulphiteIcon,
  //@ts-ignore
} from "react-allergens-css";

export const allergensDictionary = {
  ei: {
    label: "Ei",
    image: EggIcon,
  },
  schaal: {
    label: "Schaaldieren",
    image: CrustaceanIcon,
  },
  gluten: {
    label: "Gluten",
    image: GlutenIcon,
  },
  vis: {
    label: "Vis",
    image: FishIcon,
  },
  pinda: {
    label: "Pinda",
    image: PeanutIcon,
  },
  soja: {
    label: "Soja",
    image: SoyaIcon,
  },
  melk: {
    label: "Melk",
    image: MilkIcon,
  },
  noten: {
    label: "Noten",
    image: NutsIcon,
  },
  sesamzaad: {
    label: "Sesamzaad",
    image: SesameIcon,
  },
  zwaveldioxide: {
    label: "Zwaveldioxide",
    image: SulphiteIcon,
  },
  lupine: {
    label: "Lupine",
    image: LupinIcon,
  },
  weekdieren: {
    label: "Weekdieren",
    image: MolluscIcon,
  },
  selderij: {
    label: "Selderij",
    image: CeleryIcon,
  },
  mosterd: {
    label: "Mosterd",
    image: MustardIcon,
  },
};
