import { rtkQueryApi } from "../../redux/api/rtkQueryApi.tsx";
import { FindVoucherResponse, VoucherV2Response } from "./VoucherResponses.ts";
import { VoucherV2 } from "../vouchersV2Slice.tsx";

const vouchersEndpoints = rtkQueryApi.enhanceEndpoints({
  addTagTypes: [],
});

function transformVoucherV2(voucherV2: VoucherV2Response): VoucherV2 {
  switch (voucherV2.voucherdef.discountType) {
    case "AMOUNT_OFF_TOTAL":
      return {
        voucher: voucherV2.voucher,
        voucherdef: {
          ...voucherV2.voucherdef,
          freeProducts_JSON: JSON.parse(voucherV2.voucherdef.freeProducts_JSON ?? "[]"),
          priceDiscountAmount: Number(voucherV2.voucherdef.priceDiscountAmount),
        },
      };
    case "AMOUNT_OFF_PRODUCT":
      return {
        voucher: voucherV2.voucher,
        voucherdef: {
          ...voucherV2.voucherdef,
          freeProducts_JSON: JSON.parse(voucherV2.voucherdef.freeProducts_JSON ?? "[]"),
          includedProducts_JSON: JSON.parse(voucherV2.voucherdef.includedProducts_JSON ?? "[]"),
          priceDiscountAmount: Number(voucherV2.voucherdef.priceDiscountAmount),
          maxChooseItems: Number(voucherV2.voucherdef.maxChooseItems),
        },
      };
    case "PERCENTAGE_OFF_TOTAL":
      return {
        voucher: voucherV2.voucher,
        voucherdef: {
          ...voucherV2.voucherdef,
          freeProducts_JSON: JSON.parse(voucherV2.voucherdef.freeProducts_JSON ?? "[]"),
          priceDiscountPercentage: Number(voucherV2.voucherdef.priceDiscountPercentage),
        },
      };
    case "PERCENTAGE_OFF_PRODUCT":
      return {
        voucher: voucherV2.voucher,
        voucherdef: {
          ...voucherV2.voucherdef,
          freeProducts_JSON: JSON.parse(voucherV2.voucherdef.freeProducts_JSON ?? "[]"),
          includedProducts_JSON: JSON.parse(voucherV2.voucherdef.includedProducts_JSON ?? "[]"),
          priceDiscountPercentage: Number(voucherV2.voucherdef.priceDiscountPercentage),
          maxChooseItems: Number(voucherV2.voucherdef.maxChooseItems),
        },
      };
    case "ADD_DISCOUNTED_PRODUCT":
      return {
        voucher: voucherV2.voucher,
        voucherdef: {
          ...voucherV2.voucherdef,
          freeProducts_JSON: JSON.parse(voucherV2.voucherdef.freeProducts_JSON ?? "[]"),
        },
      };
    case "COLLECTION":
      return {
        voucher: voucherV2.voucher,
        voucherdef: {
          ...voucherV2.voucherdef,
        },
        subVouchers: voucherV2.subVouchers?.map((subVoucher) => transformVoucherV2(subVoucher)) ?? [],
      };
  }
}

export const vouchersApi = vouchersEndpoints.injectEndpoints({
  endpoints: (builder) => ({
    findVoucherV2: builder.query<VoucherV2, { code: string; vouchersV2: VoucherV2[] }>({
      query: ({ code, vouchersV2 }) => {
        return {
          url: `/v5_2/vouchers/find`,
          method: "POST",
          body: { voucher_code: code, vouchersV2 },
        };
      },
      transformResponse: (baseQueryReturnValue: FindVoucherResponse) => {
        return transformVoucherV2(baseQueryReturnValue.data.voucher);
      },
    }),
  }),
});

export const { useLazyFindVoucherV2Query } = vouchersApi;
