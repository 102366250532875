import { httpPost } from "./api/http";
import { apiRoutes } from "./config";
import store from "./redux/store.tsx";
import _ from "lodash";
import { setVouchers } from "./redux/shoppingCartSlice.tsx";

export function signalToServerReloadVouchersFromLocalStorage(reloadType: string, reloadData: object) {
  const data = { reloadType, reloadData };
  httpPost(apiRoutes.kiosk.reinstateVoucherSession, data, { showToastSuccess: false, showSpinner: false })
    .then((response: any) => {
      const data = response.data?.data ?? [];

      if (Array.isArray(data)) {
        const vouchers = store.getState().shoppingCart.vouchers;

        const backendVouchersByVoucherId = _.groupBy(response.data.data, "voucherId");

        let newVouchers = [...vouchers];

        newVouchers = newVouchers.filter((voucher) => !backendVouchersByVoucherId[voucher.id]);
        newVouchers = _.uniqBy(newVouchers, "id");
        if (!_.isEqual(vouchers, newVouchers)) {
          store.dispatch(setVouchers(newVouchers));
        }
      }

      // ok
      return 1;
    })
    .catch((err) => {
      // error
      return 0;
    });
}
