import { ReactNode } from "react";
import { useAppSelector } from "../../../global/utils/redux/store.tsx";
import useOrderLimits from "../../../global/utils/useOrderLimits.ts";
import useKioskSendCheckout2 from "../../utils/useKioskSendCheckout2.tsx";
import { useAskIfUserWantsReceipt } from "../../components/askForReceiptDialog.tsx";
import { useAgeCheckByEmployee } from "../../components/ConfirmAgeByEmployeeDialog.tsx";
import { useBetweenOrderPage } from "../../../global/components/BetweenOrderDialog.tsx";
import useShoppingCartConfirmationCheck from "../../../global/utils/ageCheck/useShoppingCartConfirmationCheck.tsx";
import { OrderPageAction } from "./OrderPageAction.tsx";
import { AgeCheckResult } from "../../../global/utils/ageCheck/ageCheckUtils";

import { useScanBoardingPass } from "../../utils/useScanBoardingPass";
import { useAccountSubTotalValue } from "../../../global/utils/redux/accountSlice.tsx";
import useTippingAsync from "../CheckoutPage/TippingAtCheckout.tsx";
import { selectArticlesSum } from "../../../global/utils/redux/shoppingCartSlice.tsx";

export function SendOrder({ children }: { children: ReactNode }) {
  const { isValid } = useOrderLimits();
  const noItemsInCart = useAppSelector(selectArticlesSum) === 0;
  const { sendOrder } = useKioskSendCheckout2();
  const askForReceipt = useAskIfUserWantsReceipt();
  const confirmAgeByEmployee = useAgeCheckByEmployee();
  const handleBetweenOrder = useBetweenOrderPage();
  const checkShoppingCart = useShoppingCartConfirmationCheck();
  const scanBoardingPass = useScanBoardingPass();

  const subTotalAmount = useAccountSubTotalValue();
  const { tipComponent, askForTip } = useTippingAsync(subTotalAmount);

  return (
    <>
      {tipComponent}
      <OrderPageAction
        data-cy="btn-send-order"
        className="JS-SendOrderButton-Root"
        disabled={!isValid || noItemsInCart}
        onClick={async () => {
          await scanBoardingPass();
          const ageConfirmed = await confirmAgeByEmployee();
          if (ageConfirmed !== AgeCheckResult.Accepted) {
            return;
          }
          await handleBetweenOrder();
          await askForReceipt();
          await askForTip();

          const shoppingCartConfirmation = await checkShoppingCart();
          if (shoppingCartConfirmation === true || shoppingCartConfirmation.response === "APPROVED") {
            sendOrder();
          }
        }}
      >
        {children}
      </OrderPageAction>
    </>
  );
}
