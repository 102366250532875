import { OptionTranslations } from "./Article";
import { useMemo } from "react";
import FormattedMessageJamezz from "../../../components/FormattedMessageJamezz";
import { allergensDictionary } from "../../api/parseApis/useParseApiAllergen";
import { MediaUrl } from "../../redux/api/menuDataApi.ts";

export enum MenuTagType {
  Include,
  Exclude,
}

export default interface MenuTag {
  id: string;
  type: MenuTagType;
  name: string;
  showTag: boolean;
  description?: string;
  translations?: OptionTranslations;
  mediaUrl?: MediaUrl;
  image?: keyof typeof allergensDictionary;
  updatedAt: string;
}

export const menuTagNamesMap = {
  Celery: "Allergens.Selderij",
  Crustaceans: "Allergens.Schaaldieren",
  Egg: "Allergens.Ei",
  Fish: "Allergens.Vis",
  Gluten: "Allergens.Gluten",
  Lupin: "Allergens.Lupine",
  Milk: "Allergens.Melk",
  Molluscs: "Allergens.Weekdieren",
  Mustard: "Allergens.Mosterd",
  Nuts: "Allergens.Noten",
  Peanut: "Allergens.Pinda",
  "Sesame seeds": "Allergens.Sesamzaad",
  Soybeans: "Allergens.Soja",
  "Sulphur dioxide": "Allergens.Zwaveldioxide",
};

export function getMenuTagTranslatedName(menuTag: MenuTag, selectedLanguage: string) {
  return menuTag.translations?.[selectedLanguage]?.name ?? menuTag.name;
}

export function useHasFilteredAllergens(menuTags: MenuTag[]) {
  return useMemo(() => menuTags.some((activeMenuTag) => activeMenuTag.type === MenuTagType.Exclude), [menuTags]);
}
