import { Tab, Tabs } from "@mui/material";
import { useMemo, useRef } from "react";
// @ts-ignore
import scrollIntoView from "scroll-into-view";
import Articlegroup, { getCategoryName } from "../../../global/utils/models/menu/Articlegroup";
import { useAppDispatch, useAppSelector } from "../../../global/utils/redux/store";
import { selectSelectedSupergroupId, setSelectedSupergroupId } from "../../../global/utils/redux/menuSlice";
import { capitalizeFirstLetter } from "../../../global/utils/helpers/StringMethods";
import { selectAppLanguage } from "../../../kiosk/components/LanguageSelector/useLanguage";
import Box from "@mui/material/Box";
import clsx from "clsx";
import { postAnalyticsEvent } from "../../../global/utils/analytics/useAnalytics.ts";

interface Props {
  supergroups: Articlegroup[];
}

function SupergroupTabBar({ supergroups }: Props) {
  const backgroundOfFood = useAppSelector(
    (state) =>
      state.global.salesarea?.media_urls?.["extra_settings__qrv5_food_background"]?.[0]?.conversions?.optimized?.url
  );
  const backgroundOfDrinks = useAppSelector(
    (state) =>
      state.global.salesarea?.media_urls?.["extra_settings__qrv5_drinks_background"]?.[0]?.conversions?.optimized?.url
  );
  const dispatch = useAppDispatch();
  const selectedSupergroupId = useAppSelector(selectSelectedSupergroupId);

  const lang = useAppSelector(selectAppLanguage);
  const maxWidth = useMemo(() => {
    return (supergroups?.length ?? 1) * 360;
  }, [supergroups]);
  const tabsRef = useRef<any>();

  if (tabsRef.current) {
    const indicators = tabsRef.current.getElementsByClassName("MuiTabs-indicator");
    for (const indicator of indicators) {
      indicator.classList.add("JS-Supergroups-Indicator");
    }
  }

  return (
    <Box className={"JS-SuperGroups-Root-Wrapper"} sx={{ backgroundColor: "background.default", width: 1 }}>
      <Tabs
        className="JS-SuperGroups-Root"
        ref={tabsRef}
        sx={{ maxWidth: maxWidth, marginX: "auto" }}
        variant={"scrollable"}
        scrollButtons={"auto"}
        value={selectedSupergroupId ?? false}
        textColor={"inherit"}
        indicatorColor={"primary"}
        onChange={(e, supergroupId) => {
          if (supergroupId !== selectedSupergroupId) {
            postAnalyticsEvent({
              category: "SupergroupTabBar",
              action: "setSelectedSupergroupId(" + supergroupId + ")",
            });
            dispatch(setSelectedSupergroupId(supergroupId));
          }
          const element = document.getElementById("menu-content");
          if (element) {
            scrollIntoView(
              element,
              {
                time: 500,
                align: {
                  top: 0,
                  topOffset: 300,
                },
                validTarget: () => true,
              },
              () => {}
            );
          }
        }}
      >
        {supergroups?.map((supergroup) => {
          let backgroundUrl;
          if (supergroup.id === "eten" && backgroundOfFood) {
            backgroundUrl = backgroundOfFood;
          } else if (supergroup.id === "drinken" && backgroundOfDrinks) {
            backgroundUrl = backgroundOfDrinks;
          }
          return (
            <Tab
              data-cy={"supergroup-tab-bar:" + supergroup.id}
              key={supergroup.id}
              value={supergroup.id}
              color={"inherit"}
              sx={{
                opacity: supergroup.id === selectedSupergroupId ? 1 : 0.6,
                fontWeight: 800,
                flexGrow: 1,
                textTransform: "unset",
                "::first-letter": { textTransform: "uppercase", fontWeight: 400 },
                background: backgroundUrl ? `url(${backgroundUrl})` : undefined,
              }}
              label={
                <span
                  className={clsx(
                    supergroup.id === selectedSupergroupId
                      ? "JS-MenuPage-CategoryText-selected"
                      : "JS-MenuPage-CategoryText"
                  )}
                >
                  {capitalizeFirstLetter(getCategoryName(supergroup, lang))}
                </span>
              }
            />
          );
        })}
      </Tabs>
    </Box>
  );
}

export default SupergroupTabBar;
