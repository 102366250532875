import { useMemo } from "react";
import { Avatar, Tab } from "@mui/material";
import ArticleTrait from "../../../../../../global/utils/models/ArticleTraits/ArticleTrait";
import { getImageFilePath } from "../../../../../../global/utils/models/ImageSrc";
import { Theme } from "@mui/material/styles";

interface Props {
  articleTrait: ArticleTrait;
  active: boolean;
}

export const tabItemStyles = (theme: Theme) => ({
  borderRadius: "30px",

  transition: "all .5s",
  padding: "10px 15px",

  width: 300,
  border: "1px solid",
  borderColor: theme.palette.text.primary,
  margin: "10px 30px",

  "&.Mui-selected": {
    "&, &:hover": {
      color: theme.palette.background.default,
      backgroundColor: theme.palette.text.primary,
      boxShadow: "0 7px 10px -5px rgba(76, 175, 80, 0.4)",
    },
  },
});

export default function QuickTraitTabButton(props: Props) {
  const startIcon = useMemo(() => {
    if (props.articleTrait.imageSrcs[2]) {
      return <Avatar src={getImageFilePath(props.articleTrait.imageSrcs[2])} />;
    } else {
      return null;
    }
  }, [props.articleTrait]);

  return (
    <Tab
      classes={tabItemStyles}
      label={props.articleTrait.name}
      icon={startIcon ?? undefined}
      iconPosition={"start"}
      value={props.articleTrait.apiId}
    />
  );
}
