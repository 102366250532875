import OrderArticle from "../../../../global/utils/models/order/OrderArticle";
import { Typography } from "@mui/material";
import useCurrency, { CurrencyLocation } from "../../../../global/utils/useCurrency";
import { useOptionTranslations } from "../../../../global/utils/translations/useOptionTranslations";
import Box from "@mui/material/Box";
import { getArticleDescription, getArticleName } from "../../../../global/utils/models/menu/Article";
import _ from "lodash";
import { useAppSelector } from "../../../../global/utils/redux/store.tsx";
import { selectAppLanguage } from "../../../../kiosk/components/LanguageSelector/useLanguage.ts";

interface Props {
  orderArticle: OrderArticle;
}

export default function OptionItemNameAndPrice(props: Props) {
  const toCurrency = useCurrency({ location: CurrencyLocation.ArticleDetail });
  const { translate } = useOptionTranslations();
  const lang = useAppSelector(selectAppLanguage);

  return (
    <Box sx={{ display: "flex", flexDirection: "column", textAlign: "left", width: 1, justifyContent: "center" }}>
      <Typography
        variant={"subtitle2"}
        className="JS-OptionItemNameAndPrice-Name"
        sx={{ fontSize: "0.9rem", fontWeight: 800 }}
      >
        {!_.isEmpty(props.orderArticle.article.translations)
          ? getArticleName(props.orderArticle.article, lang)
          : translate(props.orderArticle.article.name)}
      </Typography>

      <Typography
        variant={"subtitle2"}
        className="JS-OptionItemNameAndPrice-Description"
        sx={{ fontSize: "0.9rem", display: "none" }}
        dangerouslySetInnerHTML={{
          __html: !_.isEmpty(props.orderArticle.article.translations)
            ? getArticleDescription(props.orderArticle.article, lang)
            : translate(props.orderArticle.article.description),
        }}
      ></Typography>

      {props.orderArticle.article.price > 0 && (
        <Typography variant={"subtitle2"} className="JS-OptionItemNameAndPrice-Price" sx={{ fontSize: "0.8rem" }}>
          {toCurrency(props.orderArticle.article.price)}
        </Typography>
      )}
    </Box>
  );
}
