import { useEffect } from "react";
import CustomField from "../../../../types/shared/CustomField";
import { GlobalStyles, Grid, ToggleButton } from "@mui/material";
import clsx from "clsx";
import useCustomField from "./useCustomField";
import { useAccountTotalValue } from "../../../../global/utils/redux/accountSlice";

interface Props {
  customField: CustomField;
}

function CustomFieldButtonGroupContent({ customField }: Props) {
  const { value, setCustomField } = useCustomField(customField);

  const totalAmount = useAccountTotalValue();

  useEffect(() => {
    if ((value == null || value === "") && customField?.options?.[0]?.id) {
      const value = String(customField.options[0].id);
      setCustomField(value);
    }
  }, [customField?.options, setCustomField, totalAmount, value]);

  if (totalAmount <= 0) {
    return null;
  }

  return (
    <Grid container spacing={3} sx={{ marginY: 1 }} className={"JS-CustomFieldButtonGroupContent-Root"}>
      {customField.styles && <GlobalStyles styles={customField.styles} />}
      {customField.options?.map((option: any) => (
        <Grid
          item
          xs={(customField.options?.length ?? 0) > 1 ? 6 : 12}
          container
          justifyContent={"center"}
          key={option.id}
          className={"JS-CustomFieldButtonGroupContent-Grid"}
        >
          <ToggleButton
            className={clsx(
              "JS-CustomFieldButtonGroupContent-Button",
              String(option.id) === String(value)
                ? "JS-CustomFieldButtonGroupContent-Button-Selected"
                : "JS-CustomFieldButtonGroupContent-Button-Unselected"
            )}
            onClick={() => {
              const value = String(option.id);
              setCustomField(value);
            }}
            value={String(option.id)}
            sx={{ width: 300, height: 300 }}
            selected={String(option.id) === value}
          >
            {option.customHtml ? (
              <div
                dangerouslySetInnerHTML={{
                  __html: option.customHtml,
                }}
              ></div>
            ) : (
              option.label
            )}
          </ToggleButton>
        </Grid>
      ))}
    </Grid>
  );
}

export default CustomFieldButtonGroupContent;
