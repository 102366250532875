import { Divider, Skeleton, Typography } from "@mui/material";
import { useGetLoggedinUserQuery } from "../../../global/utils/redux/api/piggyAuthApi";
import PiggyLogin from "./PiggyLogin";
import { PiggyPage } from "./PiggyPage";
import { useLazyFetchLoyaltyProgramQuery } from "../../../global/utils/redux/api/piggyApi";
import { useCustomTexts } from "../../../global/utils/useCustomTexts";
import { PiggyRewardItem } from "./PiggyRewardItem";
import FormattedMessageJamezz from "../../../global/components/FormattedMessageJamezz";
import { PiggyReward } from "../../../types/shared/piggy";
import BackgroundMediaUrlFromSalesarea from "../../../kiosk/hooks/useBackgroundMediaUrl.tsx";
import CloseDialogButton from "../Dialog/CloseDialogButton.tsx";

export default function PiggyRewardsDialog({ reward }: { reward?: PiggyReward }) {
  const { data: loggedInUser } = useGetLoggedinUserQuery();
  const isLoggedIn = loggedInUser?.data.has_session ?? false;

  const customTexts = useCustomTexts(["loyalty page - title"]);

  const [
    trigger,
    { data: loyaltyProgram, isSuccess: loyaltySuccess, isUninitialized },
  ] = useLazyFetchLoyaltyProgramQuery();

  if (!customTexts["loyalty page - title"] && isUninitialized) {
    trigger();
  }

  return (
    <BackgroundMediaUrlFromSalesarea
      keyName={"extra_settings__qrv5_background_of_order_page"}
      data-cy="piggy-dialog-rewards"
      className="JS-PiggyDialog-Root"
      sx={{
        width: 1,
        height: 1,
        padding: 4,
        display: "flex",
        flexDirection: "column",
      }}
      fallbackBackgroundColor={"primary.light"}
    >
      <CloseDialogButton />
      <Typography variant="h5" sx={{ fontWeight: 800, mb: 4, mx: 4, textAlign: "center" }}>
        {customTexts["loyalty page - title"] ? (
          customTexts["loyalty page - title"]
        ) : loyaltySuccess && loyaltyProgram ? (
          loyaltyProgram.data.custom_credit_name
        ) : (
          <Skeleton />
        )}
      </Typography>
      {isLoggedIn ? (
        <PiggyPage />
      ) : (
        <>
          <PiggyLogin />
          {reward ? (
            <>
              <Divider
                sx={(theme) => ({
                  mt: 6,
                  borderColor: theme.palette.primary.main,
                  boxShadow: "0 2px 3px 0px #bbb",
                })}
              />
              <Typography sx={{ my: 2 }}>
                <FormattedMessageJamezz
                  id="Piggy.auth.message.sign_up_for_loyalty_and_get"
                  values={{
                    reward_name: reward.title,
                  }}
                />
              </Typography>
              <PiggyRewardItem item={reward} piggyPoints={0} noClaim />
            </>
          ) : null}
        </>
      )}
    </BackgroundMediaUrlFromSalesarea>
  );
}
