import Article, { ArticleType } from "../../../../global/utils/models/menu/Article";
import Box from "@mui/material/Box";
import { JSClassAnnotatedWithIds } from "../../../../kiosk/utils/Helpers";
import { findInvalidOrderOptionGroup, initOrderArticle } from "../../../../global/utils/models/order/OrderArticle";
import OrderArticleSelectorPage from "../../OrderArticleSelectorPage/OrderArticleSelectorPage";
import SlideUpTransition from "../../../../global/components/SlideUpTransition";
import useIsOrderingAllowed from "../../../../global/utils/hooks/useIsOrderingAllowed";
import Articlegroup from "../../../../global/utils/models/menu/Articlegroup";
import { postAnalyticsEvent, postEcomAnalyticsEvent } from "../../../../global/utils/analytics/useAnalytics";
import store, { useAppDispatch, useAppSelector } from "../../../../global/utils/redux/store";
import { selectArticlesMap } from "../../../../global/utils/redux/selectors/selectArticlesMap";
import { useDialog } from "../../../../global/utils/dialog/DialogProvider";
import useIsWidescreen from "../../../../global/utils/useIsWidescreen";
import useAddOrderArticleToShoppingCart from "../../../../global/utils/order/useAddOrderArticleToShoppingCart";
import { orderArticlesPushedByUser } from "../../../../global/utils/redux/shoppingCartSlice";
import { Collapse, Typography } from "@mui/material";
import { selectShoppingCartCountPerArticleId } from "../../../../global/utils/redux/selectors/selectShoppingCartCountPerArticleId";
import { groupBy } from "lodash";
import QuickRemoveOrderArticleList from "./QuickRemoveList";
import { Add, Remove } from "@mui/icons-material";
import useAskNewArticleOrExistingArticle from "./AskNewArticleOrExistingArticle";

export default function QuickAddOrderButton({
  article,
  articlegroup,
  isColumnOrientation,
}: {
  article: Article;
  articlegroup: Articlegroup;
  isColumnOrientation: boolean;
}) {
  const canOrder = useIsOrderingAllowed(articlegroup, article);
  const articlesMap = useAppSelector(selectArticlesMap);
  const dispatch = useAppDispatch();
  const { openDialog } = useDialog();
  const isWidescreen = useIsWidescreen();
  const addOrderArticleToShoppingCart = useAddOrderArticleToShoppingCart();
  const count = useAppSelector((state) => selectShoppingCartCountPerArticleId(state)[article.id]) ?? 0;
  const ask = useAskNewArticleOrExistingArticle();
  const alwaysShowOptions = useAppSelector(
    (state) => state.global.salesarea.custom_data?.appv5_always_show_options_using_quick_add_order_button?.enabled
  );

  if (canOrder && !article.isOutOfStock && !article.isNotAvailable && article.type !== ArticleType.Filler) {
    return (
      <Box
        sx={{
          position: "relative",
          display: "flex",
          overflow: "hidden",
          borderRadius: 16,
          minWidth: 90,
          ml: "auto",
          mt: "auto",
          flexDirection: "row",
          justifyContent: "flex-end",
        }}
        className={JSClassAnnotatedWithIds("JS-ArticleContent-QuickAddOrderButton-Root", article)}
      >
        <Collapse orientation={"horizontal"} in={count > 0} className={"JS-ArticleContent-QuickAddOrderButton"}>
          <Box sx={{ display: "flex" }}>
            <Box
              className={JSClassAnnotatedWithIds("JS-ArticleContent-QuickRemoveOrderButton", article)}
              sx={{
                borderRadius: 2,
                fontWeight: 800,
                fontSize: "1.5rem",
                marginTop: isColumnOrientation ? "unset" : "auto",
                marginLeft: !isColumnOrientation ? "unset" : "auto",
                aspectRatio: 1,
                width: 30,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              onClick={(e) => {
                e.stopPropagation();

                const items = store.getState().shoppingCart.items;
                const orderArticles = groupBy(items, "article.id")[article.id];

                if (orderArticles && orderArticles.length > 1) {
                  // quick remove
                  openDialog({
                    children: <QuickRemoveOrderArticleList article={article} />,
                    fullScreen: !isWidescreen,
                    TransitionComponent: SlideUpTransition,
                  });
                } else if (orderArticles && orderArticles.length === 1) {
                  const orderArticle = orderArticles[0];
                  if (orderArticle) {
                    dispatch(orderArticlesPushedByUser([{ ...orderArticle, count: -1 }]));
                    e.stopPropagation();
                  }
                }
              }}
            >
              <Remove className={"JS-ArticleContent-QuickAddOrderButton-MinusIcon"} />
            </Box>

            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography
                className={"JS-ArticleContent-QuickAddOrderButton-Count"}
                sx={{ width: 30, textAlign: "center" }}
              >
                {count}
              </Typography>
            </Box>
            <Box sx={{ width: 30, height: 30 }}></Box>
          </Box>
        </Collapse>
        <Box
          className={"JS-ArticleContent-QuickAddOrderButton JS-ArticleContent-QuickAddOrderButton-PlusIcon"}
          sx={{
            borderRadius: "50%",
            fontWeight: 800,
            fontSize: "1.5rem",
            aspectRatio: 1,
            width: 30,
            marginTop: isColumnOrientation ? "unset" : "auto",
            marginLeft: !isColumnOrientation ? "unset" : "auto",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",

            position: "absolute",
          }}
          onClick={async (e) => {
            e.stopPropagation();

            const items = store.getState().shoppingCart.items;
            const orderArticles = groupBy(items, "article.id")[article.id] ?? [];

            if (orderArticles.length > 0 && orderArticles[0].orderOptionGroups.length > 0) {
              const answer = await ask(orderArticles);

              switch (answer) {
                case "NEW_ARTICLE":
                  postAnalyticsEvent({
                    category: "ArticleContent",
                    action: "OrderArticleSelectorPage(" + article.name + ")",
                  });
                  postEcomAnalyticsEvent("select_item", {
                    item_list_id: articlegroup.id,
                    item_list_name: articlegroup.name,
                    items: [
                      {
                        item_id: article.id,
                        item_name: article.name,
                        price: article.price,
                        quantity: 1,
                      },
                    ],
                  });
                  openDialog({
                    children: (
                      <OrderArticleSelectorPage
                        article={orderArticles[0].article}
                        upsellSource={article.id}
                        articlegroup={articlegroup}
                      />
                    ),
                    fullScreen: !isWidescreen,
                    TransitionComponent: SlideUpTransition,
                  });
                  break;
                case "EXISTING_ARTICLE":
                  if (orderArticles.length > 1) {
                    openDialog({
                      children: <QuickRemoveOrderArticleList article={article} />,
                      fullScreen: !isWidescreen,
                      TransitionComponent: SlideUpTransition,
                    });
                  } else {
                    addOrderArticleToShoppingCart({ ...orderArticles[0], count: 1 }).catch((err) => console.log(err));
                  }
                  break;
              }
            } else {
              const orderArticle = initOrderArticle(articlesMap, article, 1);
              if (
                !findInvalidOrderOptionGroup(orderArticle) &&
                canOrder == "ALLOWED" &&
                (alwaysShowOptions !== true || orderArticle.orderOptionGroups.length === 0)
              ) {
                addOrderArticleToShoppingCart(orderArticle).catch((err) => console.log(err));
              } else {
                postAnalyticsEvent({
                  category: "ArticleContent",
                  action: "OrderArticleSelectorPage(" + article.name + ")",
                });
                postEcomAnalyticsEvent("select_item", {
                  item_list_id: articlegroup.id,
                  item_list_name: articlegroup.name,
                  items: [
                    {
                      item_id: article.id,
                      item_name: article.name,
                      price: article.price,
                      quantity: 1,
                    },
                  ],
                });
                openDialog({
                  children: (
                    <OrderArticleSelectorPage
                      article={orderArticle.article}
                      upsellSource={orderArticle.article.id}
                      articlegroup={articlegroup}
                    />
                  ),
                  fullScreen: !isWidescreen,
                  TransitionComponent: SlideUpTransition,
                });
              }
            }
          }}
        >
          <Add className={"JS-ArticleContent-QuickAddOrderButton-PlusIcon"} />
        </Box>
      </Box>
    );
  } else {
    return null;
  }
}
