import { alpha, Tab, useTheme } from "@mui/material";
import { setSelectedArticlegroup } from "../../../../global/utils/redux/menuSlice";
import { scrollArticlegroupContent } from "../../../../global/utils/menu/ScrollArticlegroupTabListener";
import Articlegroup, { getArticlegroupName } from "../../../../global/utils/models/menu/Articlegroup";
import { useScroll } from "../../../../global/utils/ScrollProvider";
import { useAppDispatch, useAppSelector } from "../../../../global/utils/redux/store";
import clsx from "clsx";
import { postAnalyticsEvent } from "../../../../global/utils/analytics/useAnalytics";
import { selectArticlegroupsMap } from "../../../../global/utils/redux/selectors/selectArticlegroupsMap";
import { selectAppLanguage } from "../../../../kiosk/components/LanguageSelector/useLanguage.ts";

interface Props {
  articlegroup: Articlegroup;
}

export default function ArticlegroupTab({ articlegroup }: Props) {
  const isArticlegroupSelected = useAppSelector((state) => state.menu.selectedArticlegroupIds[articlegroup.id]);
  const theme = useTheme();
  const { scrollTabBlocked } = useScroll();
  const dispatch = useAppDispatch();
  const lang = useAppSelector(selectAppLanguage);
  const articlegroupsMap = useAppSelector(selectArticlegroupsMap);

  return (
    <Tab
      className={clsx(
        "JS-ArticlegroupTabBar-Tab",
        isArticlegroupSelected ? "JS-ArticlegroupTabBar-Tab-selected" : "JS-ArticlegroupTabBar-Tab-not-selected"
      )}
      key={articlegroup.id}
      id={"ArticlegroupTab-" + articlegroup.id}
      sx={{
        backgroundColor: isArticlegroupSelected ? alpha(theme.palette.primary.main, 1) : null,
        opacity: isArticlegroupSelected ? 1 : 0.6,
        fontWeight: 800,
        textTransform: "none",
        borderRadius: 2,
        marginX: 1,
        paddingY: 1,
        marginY: 1,
        minHeight: 0,
        height: 30,
      }}
      label={getArticlegroupName(articlegroup, lang)}
      onClick={() => {
        postAnalyticsEvent({
          category: "ScrollToArticleGroup",
          action: articlegroup.name,
        });
        scrollTabBlocked.current = true;
        dispatch(setSelectedArticlegroup({ articlegroup, articlegroupsMap }));
        // scroll to the bottom of the tab bar.
        const topOffset = document.querySelector(".JS-ArticlegroupTabBar-Root")?.getBoundingClientRect()?.bottom ?? 136;
        scrollArticlegroupContent(scrollTabBlocked, articlegroup, topOffset + 4);
      }}
    />
  );
}
