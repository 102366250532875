import { ReactNode, useState } from "react";
import { useAppDispatch } from "../../../global/utils/redux/store";
import { Button, Dialog, DialogContent, DialogTitle, Grid, TextField } from "@mui/material";
import Box from "@mui/material/Box";
import FormattedMessageJamezz from "../../../global/components/FormattedMessageJamezz";
import CloseDialogButton from "../CloseDialogButton";
import usePriceKeyChecker from "../../utils/usePriceKeyChecker";
import { toast } from "react-toastify";
import { onFocusKeyboard } from "../../../global/utils/redux/globalSlice";
import usePriceKeysWithOnlyCodes from "../../utils/usePriceKeysWithOnlyCodes";
import { Controller, FormProvider, useForm } from "react-hook-form";
import isKiosk from "../../../global/utils/helpers/isKiosk";
import { useLoadingScreen } from "../../../global/modules/LoadingScreen/loadingScreen.ts";
import { OrderPageAction } from "../../pages/OrderPage/OrderPageAction.tsx";

export default function AccountDiscount({ children }: { children: ReactNode }) {
  const [openDialog, setOpenDialog] = useState(false);

  return (
    <>
      <OrderPageAction
        data-cy="btn-account-discount-open"
        className="JS-Vouchers-VoucherButton"
        onClick={() => {
          setOpenDialog(true);
        }}
      >
        {children}
      </OrderPageAction>
      <AccountDiscountForm open={openDialog} setOpen={setOpenDialog} />
    </>
  );
}

export function AccountDiscountForm(props: { open: boolean; setOpen: (open: boolean) => void }) {
  const priceKeyCheck = usePriceKeyChecker();

  const dispatch = useAppDispatch();

  const { startLoading, stopLoading } = useLoadingScreen();
  const priceKeysWithOnlyCodes = usePriceKeysWithOnlyCodes();

  const useFormMethods = useForm({
    defaultValues: {
      identifier: "",
      name: "",
    },
  });

  const { getValues, trigger, control } = useFormMethods;

  return (
    <Dialog
      className="JS-AccountDiscount-Dialog-Root"
      disableRestoreFocus
      open={props.open}
      keepMounted={false}
      onClose={() => {
        props.setOpen(false);
      }}
      maxWidth={"md"}
      sx={{ paddingBottom: 20 }}
    >
      <DialogTitle sx={{ marginRight: 5 }} className="JS-AccountDiscount-Dialog-Title">
        <FormattedMessageJamezz id={"Login"} />
      </DialogTitle>
      <CloseDialogButton
        onClose={() => {
          props.setOpen(false);
        }}
      />

      <DialogContent>
        <FormProvider {...useFormMethods}>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              trigger().then((isValid) => {
                if (isValid) {
                  startLoading();
                  const { identifier, name } = getValues();
                  priceKeyCheck(identifier, name)
                    .then(() => {
                      toast.success(<FormattedMessageJamezz id="Discounts.messages.discount-applied" />);
                      props.setOpen(false);
                    })
                    .catch(() => {
                      toast.error(<FormattedMessageJamezz id="Discounts.messages.incorrect-code" />);
                    })
                    .finally(() => {
                      stopLoading();
                    });
                }
              });
            }}
          >
            <Grid container spacing={3} sx={{ pt: 4 }}>
              <Grid item xs={12}>
                <Controller
                  name="identifier"
                  control={control}
                  rules={{ required: true }}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      inputProps={{
                        className: "JS-AccountDiscount-Dialog-Input",
                        inputMode: isKiosk() ? "none" : undefined,
                      }}
                      InputLabelProps={{
                        className: "JS-AccountDiscount-Dialog-InputLabel",
                      }}
                      data-cy="account-discount-field-id-or-code"
                      autoFocus
                      {...field}
                      error={Boolean(error)}
                      label={priceKeysWithOnlyCodes ? <FormattedMessageJamezz id={"Code"} /> : "ID"}
                      onFocus={() => {
                        dispatch(onFocusKeyboard({ value: field.value, layout: "default" }));
                      }}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12}>
                {!priceKeysWithOnlyCodes ? (
                  <Controller
                    name="name"
                    control={control}
                    rules={{ required: true }}
                    render={({ field, fieldState: { error } }) => (
                      <TextField
                        inputProps={{
                          className: "JS-AccountDiscount-Dialog-Input",
                          inputMode: isKiosk() ? "none" : undefined,
                        }}
                        InputLabelProps={{
                          className: "JS-AccountDiscount-Dialog-InputLabel",
                        }}
                        {...field}
                        data-cy="account-discount-field-lastname"
                        error={Boolean(error)}
                        label={<FormattedMessageJamezz id={"Lastname"} />}
                        onFocus={() => {
                          dispatch(onFocusKeyboard({ value: field.value, layout: "default" }));
                        }}
                      />
                    )}
                  />
                ) : null}
              </Grid>
              <Grid item sx={{ display: "grid", gridTemplateColumns: "1.5fr 2fr", gap: 4, width: "100%" }}>
                <Button
                  variant="contained"
                  className="JS-AccountDiscount-Dialog-Cancel"
                  data-cy="btn-account-server-cancel"
                  onClick={() => {
                    props.setOpen(false);
                  }}
                >
                  <FormattedMessageJamezz id="Cancel" />
                </Button>
                <Button
                  color="success"
                  data-cy="btn-account-server-login"
                  type="submit"
                  className="JS-AccountDiscount-Dialog-Login"
                  variant="contained"
                >
                  <FormattedMessageJamezz id="Login" />
                </Button>
              </Grid>
            </Grid>
          </form>
        </FormProvider>
      </DialogContent>
    </Dialog>
  );
}
