import { Button, Chip, Fade } from "@mui/material";
import React, { useMemo } from "react";
import { useAppSelector } from "../../../global/utils/redux/store.tsx";
import { selectArticlesSum } from "../../../global/utils/redux/shoppingCartSlice.tsx";
import {
  selectArrangementCreditCost,
  selectArrangementLoadingState,
} from "../../../global/utils/redux/arrangements/arrangementsSlice.tsx";
import { AyceRuleResult, useCheckAyceRules } from "../../utils/arrangements/useCheckAyceRules.ts";
import { ArrangementsLoadingState } from "../../../types/qr/arrangements.ts";
import {
  CREDITS_PER_COVER_PER_ROUND_DISABLED,
  selectMaxDishesInRound,
} from "../../../global/utils/redux/arrangements/arrangementRuleSelectors.ts";
import isQr from "../../../global/utils/helpers/isQr.tsx";
import SlideUpTransition from "../../../global/components/SlideUpTransition.tsx";
import Box from "@mui/material/Box";
import FormattedMessageJamezz from "../../../global/components/FormattedMessageJamezz.tsx";
import OrderPage from "../../pages/OrderPage/OrderPage.tsx";
import { useDialog } from "../../../global/utils/dialog/DialogProvider.tsx";
import useIsWidescreen from "../../../global/utils/useIsWidescreen.ts";
import { useTheme } from "@mui/material/styles";

export default function JamezzOrderButton() {
  const isAyce = useAppSelector((state) => state.global.salesarea.use_arrangements);
  const maxDishesInRound = useAppSelector(selectMaxDishesInRound);
  const count = useAppSelector(selectArticlesSum);
  const arrangementCreditCount = useAppSelector(selectArrangementCreditCost);
  const ayceRuleResult = useCheckAyceRules();
  const customComponentStylingFromSalesarea = useAppSelector(
    (state) => state.global.salesarea?.custom_component_styling
  );

  const theme = useTheme();
  let backgroundColor: string = theme?.palette?.background?.default;

  type RGB = { r: number; g: number; b: number };

  const hexToRgbObject = (hex: string): RGB => {
    // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
    const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
    hex = hex.replace(shorthandRegex, function (m, r, g, b) {
      return "#" + r + r + g + g + b + b;
    });

    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);

    return { r, g, b };
  };

  const rgbStringToObject = (rgbString: string): RGB => {
    const match = rgbString.match(/^rgb\((\d+),\s*(\d+),\s*(\d+)\)$/);
    if (match !== null) {
      return {
        r: parseInt(match[1]),
        g: parseInt(match[2]),
        b: parseInt(match[3]),
      };
    }
    return { r: 255, g: 255, b: 255 };
  };

  const rgbObjectToRgbaString = (rgbObject: RGB, a: number = 0): string => {
    return `rgba(${rgbObject.r}, ${rgbObject.g}, ${rgbObject.b}, ${a})`;
  };

  let bottomGradientColor: RGB;

  if (customComponentStylingFromSalesarea) {
    const customComponentStyling = JSON.parse(customComponentStylingFromSalesarea);
    backgroundColor = customComponentStyling?.["JS-MenuContent-Root"]?.backgroundColor ?? backgroundColor;
  }

  if (backgroundColor.includes("#")) {
    bottomGradientColor = hexToRgbObject(backgroundColor);
  } else {
    bottomGradientColor = rgbStringToObject(backgroundColor);
  }

  const { openDialog } = useDialog();
  const isWidescreen = useIsWidescreen();

  const arrangementLoadingState = useAppSelector(selectArrangementLoadingState);
  const badgeContent = useMemo(() => {
    if (isAyce && ayceRuleResult !== AyceRuleResult.PRE_DINING) {
      if (
        ayceRuleResult === AyceRuleResult.EATING_TIME_OVER ||
        ayceRuleResult === AyceRuleResult.ROUND_COUNTER_EXCEEDED
      ) {
        return 0;
      } else if (maxDishesInRound != null && maxDishesInRound > 0) {
        return `${arrangementCreditCount}/${maxDishesInRound}`;
      } else if (maxDishesInRound === CREDITS_PER_COVER_PER_ROUND_DISABLED) {
        return count;
      }
    } else {
      return count;
    }
  }, [maxDishesInRound, isAyce, arrangementCreditCount, count, ayceRuleResult]);

  return (
    <>
      {/* TODO Jhorie: Ik ben hier geen fan van, specifieke arrangements logica in JamezzShowOrderButton render? Ideeen?*/}
      {/* Mijn idee is om iets te doen met de global loading state van de app. Zolang `(!salesareaId)`, disable JamezzShowOrderButton */}
      {/* Maak deze `!salesareaId` naar `function appReadyForInteraction`, en breidt deze uit met o.a. arrangements */}
      <Fade
        unmountOnExit
        in={count > 0 && arrangementLoadingState !== ArrangementsLoadingState.ERR_NO_ACTIVE_ARRANGEMENT}
      >
        <Box
          sx={{
            display: "grid",
            gridTemplateAreas: "'area'",
            gridTemplateColumns: "1fr",
            gridTemplateRows: "1fr",
            justifyContent: "center",
            width: 1,
            position: "sticky",
            bottom: 0,
          }}
        >
          <Box
            sx={{
              gridArea: "area",
              background: `linear-gradient(${rgbObjectToRgbaString(bottomGradientColor)} 2.5%, ${rgbObjectToRgbaString(
                bottomGradientColor,
                0.9
              )} 40%);`,
              bottom: 0,
              width: "100%",
              height: "77px",
            }}
          />
          <Button
            className={"JS-ShowOrderButton-Root"}
            data-cy={"show-order-button"}
            variant={"contained"}
            sx={{
              gridArea: "area",
              justifySelf: "center",
              mx: 3,
              mb: 3,
              zIndex: 15,
              width: "90%",
              maxWidth: 500,
              borderRadius: "45px",
              color: "primary.contrastText",
              padding: "12px 40px",
              fontSize: "1rem",
              letterSpacing: "0.5px",
              fontFamily: "sans-serif",
              display: "inline-block",
            }}
            onClick={() => {
              openDialog({
                children: <OrderPage />,
                fullScreen: !(isQr() && isWidescreen),
                TransitionComponent: SlideUpTransition,
              });
            }}
          >
            <FormattedMessageJamezz id={"Show order"} />
            {count ? (
              <Chip
                className={"JS-ShowOrderButton-Chip"}
                sx={{
                  boxShadow: "0 1px 10px 0px rgba(0,0,0,0.2)",
                  minWidth: "35px",
                  minHeight: "35px",
                  borderRadius: "50%",
                  color: "primary.contrastText",
                  position: "absolute",
                  right: "10.7px",
                  top: "50%",
                  transform: "translateY(-50%)",
                }}
                data-cy={"show-order-button-sum-of-count-of-items"}
                label={badgeContent}
                size="small"
              />
            ) : null}
            {/*{customTexts["menu page - save loyalty points button"] ?? "Save loyaltypoints!"}*/}
          </Button>
        </Box>
      </Fade>
    </>
  );
}
