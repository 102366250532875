import { useCallback } from "react";
import { httpPost } from "./api/http";
import { apiRoutes } from "./config";
import Voucher, { VoucherType } from "./vouchers/Voucher";
import store, { useAppSelector } from "./redux/store";
import { setVouchers } from "./redux/shoppingCartSlice";
import { getArticleById } from "./models/menu/Menu";
import { selectArticlesMap } from "./redux/selectors/selectArticlesMap";
import { assertIsValidVoucherResponse, ValidResponse, VoucherResponse } from "../../types/shared/vouchers";
import { useLazyFindVoucherV2Query } from "./vouchersV2/api/vouchersApi.tsx";
import { VoucherV2, voucherV2Added } from "./vouchersV2/vouchersV2Slice.tsx";
import { useLoadingScreen } from "../modules/LoadingScreen/loadingScreen.ts";
import { canAddNewVoucher } from "./vouchersV2/canAddNewVoucher.ts";

export default function useVouchers({ withLoading = true }: { withLoading: boolean } = { withLoading: true }) {
  const voucherSystem = useAppSelector((state) => state.global.salesarea?.custom_data?.use_vouchers_system) ?? "v1";
  const [trigger, result] = useLazyFindVoucherV2Query();
  useLoadingScreen(result.isFetching && withLoading);
  const checkVoucherCode = useCallback((voucherCode: string) => {
    return new Promise<VoucherResponse["data"]["data"]>((resolve, reject) => {
      const items = store.getState().shoppingCart.items;
      const vouchers = store.getState().shoppingCart.vouchers;

      const data = { items, voucherCode };
      httpPost<VoucherResponse>(apiRoutes.kiosk.checkVoucherCode, data, {
        showToastSuccess: false,
        showSpinner: withLoading,
      })
        .then((response) => {
          try {
            assertIsValidVoucherResponse(response);
            const newVouchers: Voucher[] = [];

            checkVoucher(response.data.data, newVouchers);
            if (newVouchers) {
              store.dispatch(setVouchers([...vouchers, ...newVouchers]));
            }
            resolve(response.data.data);
          } catch {
            reject(response.data.data);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }, []);

  const checkVoucherCodeV2 = useCallback(
    (voucherCode: string) => {
      return new Promise<VoucherV2>((resolve, reject) => {
        trigger({ code: voucherCode, vouchersV2: store.getState().vouchersV2.vouchers })
          .then((response) => {
            if (response.isSuccess) {
              const result = canAddNewVoucher(store.getState().vouchersV2.vouchers, response.data);
              if (!result.isError) {
                resolve(response.data);
                store.dispatch(voucherV2Added(response.data));
              } else {
                reject({ result: { message: result.message } });
              }
            } else {
              if (
                response.error &&
                "data" in response.error &&
                response.error.data &&
                typeof response.error.data == "object" &&
                "message" in response.error.data
              ) {
                reject({ result: { message: response.error?.data?.message ?? "Voucher error" } });
              }
            }
          })
          .catch((err) => {
            console.log(err);
          });
      });
    },
    [trigger]
  );

  if (voucherSystem === "v1") {
    return { checkVoucherCode };
  } else {
    return { checkVoucherCode: checkVoucherCodeV2 };
  }
}

function checkVoucher(response: ValidResponse, newVouchers: Voucher[]) {
  let voucher: Voucher | undefined = undefined;
  const voucherDef = response.voucherDef;
  response.products.forEach((newProduct: any) => {
    const article = getArticleById(selectArticlesMap(store.getState()), String(newProduct.id));
    if (article) {
      if (voucherDef.discountType === "PERCENTAGE_OFF_TOTAL") {
        if (!voucher) {
          voucher = {
            voucherType: VoucherType.PERCENTAGE_OFF_TOTAL,
            amount: Number(voucherDef.priceDiscountPercentage) / 100,
            articles: [],
            id: String(response.voucher.id),
            useRequestList: String(response.voucher.useRequestList),
            vouchercode: response.voucher.vouchercode,
            voucherDef: response.voucherDef,
          };
        }
        voucher.articles.push(article);
      } else if (voucherDef.discountType === "AMOUNT_OFF_TOTAL") {
        if (voucherDef.discountType === "AMOUNT_OFF_TOTAL") {
          if (!voucher) {
            voucher = {
              voucherType: VoucherType.AMOUNT_OFF_TOTAL,
              amount: Number(voucherDef.priceDiscountAmount),
              articles: [],
              id: String(response.voucher.id),
              useRequestList: String(response.voucher.useRequestList),
              vouchercode: response.voucher.vouchercode,
              voucherDef: response.voucherDef,
            };
          }
          voucher.articles.push(article);
        }
      } else if (voucherDef.discountType === "ADD_DISCOUNTED_PRODUCT") {
        if (voucherDef.discountType === "ADD_DISCOUNTED_PRODUCT") {
          if (!voucher) {
            voucher = {
              voucherType: VoucherType.ADD_DISCOUNTED_PRODUCT,
              amount: 0,
              articles: [],
              id: String(response.voucher.id),
              useRequestList: String(response.voucher.useRequestList),
              vouchercode: response.voucher.vouchercode,
              voucherDef: response.voucherDef,
            };
          }
          voucher.articles.push(article);
        }
      } else if (voucherDef.discountType === "AMOUNT_OFF_PRODUCT") {
        if (voucherDef.discountType === "AMOUNT_OFF_PRODUCT") {
          if (!voucher) {
            voucher = {
              voucherType: VoucherType.AMOUNT_OFF_PRODUCT,
              amount: Number(voucherDef.priceDiscountAmount),
              articles: [],
              id: String(response.voucher.id),
              useRequestList: String(response.voucher.useRequestList),
              vouchercode: response.voucher.vouchercode,
              voucherDef: response.voucherDef,
            };
          }
          voucher.articles.push(article);
        }
      } else if (voucherDef.discountType === "PERCENTAGE_OFF_PRODUCT") {
        if (voucherDef.discountType === "PERCENTAGE_OFF_PRODUCT") {
          if (!voucher) {
            voucher = {
              voucherType: VoucherType.PERCENTAGE_OFF_PRODUCT,
              amount: Number(voucherDef.priceDiscountPercentage) / 100,
              articles: [],
              id: String(response.voucher.id),
              useRequestList: String(response.voucher.useRequestList),
              vouchercode: response.voucher.vouchercode,
              voucherDef: response.voucherDef,
            };
          }
          voucher.articles.push(article);
        }
      }
    }
  });

  if (response.subVouchers) {
    response.subVouchers.forEach((subVoucher) => {
      checkVoucher(subVoucher, newVouchers);
    });
  }
  if (voucher) {
    newVouchers.push(voucher);
  }
}
