import { ORDER_STATUS, TicketOrder } from "../../../../types/qr/arrangements.ts";
import { Close, Done, DoneAll, QuestionMark } from "@mui/icons-material";

export function OrderStatusTick({ orderStatus }: Pick<TicketOrder, "orderStatus">) {
  // POSTicketOrders always have status null, the fact that they exist at all means they succeeded.
  if (orderStatus === ORDER_STATUS.SUCCEEDED || orderStatus === null) {
    return <DoneAll fontSize="small" />;
  } else if (orderStatus === ORDER_STATUS.PENDING) {
    return <Done fontSize="small" />;
  } else if (orderStatus === ORDER_STATUS.RETRYING) {
    return <Done fontSize="small" />;
  } else if (orderStatus === ORDER_STATUS.FAILED) {
    return <Close fontSize="small" color="error" />;
  }

  return <QuestionMark fontSize="small" />;
}