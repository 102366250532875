import SlideUpTransition from "../../global/components/SlideUpTransition";
import { useCallback } from "react";
import { useDialog } from "../../global/utils/dialog/DialogProvider";
import QrArticleSuggestionPage from "../pages/ArticleSuggestionPage/ArticleSuggestionPage";
import KioskArticleSuggestionPage from "../../kiosk/pages/ArticleSuggestionPage/ArticleSuggestionPage";
import Article from "../../global/utils/models/menu/Article";
import { getArticleById } from "../../global/utils/models/menu/Menu";
import useMenuFilters from "../../global/utils/menu/filters/useMenuFilters";
import useIsWidescreen from "../../global/utils/useIsWidescreen";
import store from "../../global/utils/redux/store";
import { selectArticlesMap } from "../../global/utils/redux/selectors/selectArticlesMap";
import isKiosk from "../../global/utils/helpers/isKiosk.tsx";
import { selectAppLanguage } from "../../kiosk/components/LanguageSelector/useLanguage.ts";
import ShowArticleDialogAction from "../../global/utils/automation/actions/ShowArticleDialogAction.ts";

export default function useAutomation() {
  const actions = useActions();
  return { actions };
}

function useActions() {
  const { openDialog } = useDialog();
  const isWidescreen = useIsWidescreen();
  const articleFilter = useMenuFilters();

  const action = useCallback(
    (action: ShowArticleDialogAction, source: string) => {
      const lang = selectAppLanguage(store.getState());
      if (store.getState().global.salesarea.custom_data.upsell_enabled === false) {
        return;
      }
      const ArticleSuggestionPage = isKiosk() ? KioskArticleSuggestionPage : QrArticleSuggestionPage;

      let articles: Article[] = [];
      const articlesMap = selectArticlesMap(store.getState());
      action.articleIds.forEach((articleId) => {
        const article = getArticleById(articlesMap, articleId);
        if (article) {
          articles.push(article);
        }
      });
      articles = articles.filter(articleFilter);
      const validArticlesCount = articles.filter((article) => {
        return !(article.isOutOfStock || article.isNotAvailable);
      }).length;

      if (validArticlesCount > 0) {
        openDialog({
          children: (
            <ArticleSuggestionPage
              articles={articles}
              upsellType={action.upsellId ? "Upsells" : "Freeoption"}
              upsellId={action.upsellId}
              upsellSource={source ?? null}
              title={action.titleTranslations?.[lang] ?? action.title}
              text={action.descriptionTranslations?.[lang] ?? action.description}
            />
          ),
          fullScreen: isKiosk() ? "almostFullScreen" : !isWidescreen,
          TransitionComponent: SlideUpTransition,
        });
      }
    },
    [openDialog, articleFilter, isWidescreen]
  );

  return {
    orderOneOfTheFollowingArticles: action,
  };
}
