import { useRef } from "react";
import CustomField from "../../../../types/shared/CustomField";
import { Grid, TextField } from "@mui/material";
import Box from "@mui/material/Box";
import { Controller, useFormContext } from "react-hook-form";
import useCustomField from "./useCustomField";
import { onFocusKeyboard } from "../../../../global/utils/redux/globalSlice";
import { useAppDispatch } from "../../../../global/utils/redux/store";
import isKiosk from "../../../../global/utils/helpers/isKiosk";
import { useFocusCustomField } from "./useFocusCustomField";
import Explanation from "./Explanation.tsx";

interface Props {
  customField: CustomField;
}

function CustomFieldNumpadContent(props: Props) {
  const customField = props.customField;
  const { value, setCustomField } = useCustomField(props.customField);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const dispatch = useAppDispatch();

  const { control } = useFormContext();
  const maxLength = Number.isNaN(customField.maxlength) ? 1024 : Math.max(1, Number(customField.maxlength));
  useFocusCustomField(inputRef, customField.name);

  return (
    <Grid item xs={12} container justifyContent={"center"} className={"JS-CustomFieldNumpadContent-Root"}>
      <Box sx={{ width: 600 }}>
        <Controller
          control={control}
          name={customField.name}
          rules={{ required: { value: customField.required ?? false, message: "Required!" } }}
          render={() => (
            <TextField
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
              }}
              helperText={<Explanation customField={customField} />}
              focused={true}
              sx={{ marginY: 1, width: 1 }}
              inputRef={inputRef}
              variant={"outlined"}
              name={customField.name}
              type={"number"}
              inputProps={{
                pattern: customField.pattern ?? (customField.required ? ".*\\S+.*" : null),
                required: customField.required,
                className: "JS-CustomFields-Input JS-CustomFieldNumpadContent-Input",
                inputMode: isKiosk() ? "none" : undefined,
                maxLength,
              }}
              InputLabelProps={{ className: "JS-CustomFields-Input-Label JS-CustomFieldNumpadContent-Label" }}
              label={customField.label}
              value={value}
              onChange={(e) => {
                setCustomField(e.target.value);
              }}
              onFocus={() => {
                if (isKiosk()) {
                  dispatch(
                    onFocusKeyboard({
                      value: value,
                      layout: "numonly",
                    })
                  );
                }
              }}
            />
          )}
        />
      </Box>
    </Grid>
  );
}

export default CustomFieldNumpadContent;
