import store, { useAppSelector } from "../../utils/redux/store";
import OrderArticle, { orderArticlesAreEqual } from "../../utils/models/order/OrderArticle";
import { toast } from "react-toastify";
import { IntlShape, useIntl } from "react-intl";
import { AgeCheckResult, countProductsWithAgeRequirement } from "../../utils/ageCheck/ageCheckUtils";

/**
 * @example
 * const checkAge = useAgeCheck();
 * <product onAddToCart={async (product) => {
 *   const ageAccepted = await checkAge(product);
 * }} />
 *
 * @description
 * When age hasn't been filled in, shows pop-up and lets user input age
 * If age has already been set, it will immediately return without showing the input popup
 */
export function useMaxAlcoholicItemsCheck() {
  const intl = useIntl();
  const maxAlcoholicItems = useAppSelector(
    (state) => state.global.salesarea.custom_data?.kiosk?.max_alcoholic_items_in_basket
  );

  return function checkAge(orderArticle: OrderArticle, modifyingOrderArticle?: OrderArticle) {
    let countAlcoholicItems = 0;
    if (modifyingOrderArticle == null) {
      countAlcoholicItems = countProductsWithAgeRequirement(1, ...store.getState().shoppingCart.items, orderArticle);
    } else {
      const items = [...store.getState().shoppingCart.items];

      const itemIndex = items.findIndex((item) => item.uuid === orderArticle.uuid);
      if (itemIndex >= 0) {
        if (orderArticle.count === 0) {
          items.splice(itemIndex, 1);
        } else {
          const index = items.findIndex(
            (item, index) => index !== itemIndex && orderArticlesAreEqual(item, orderArticle)
          );
          if (index >= 0) {
            items[index].count += orderArticle.count;
            items.splice(itemIndex, 1);
          } else {
            items[itemIndex] = orderArticle;
          }
        }
      }

      countAlcoholicItems = countProductsWithAgeRequirement(1, ...items);
    }

    if (maxAlcoholicItems == undefined || !maxAlcoholicItems || maxAlcoholicItems >= countAlcoholicItems) {
      return new Promise<AgeCheckResult>((resolve) => resolve(AgeCheckResult.Accepted));
    }
    /**
     * 2) product has age requirement, but user either
     *   a) has not given age yet
     *   or
     *   b) user has given age for product with required age of for example 18
     *      but the current product requires for example 21
     */

    return new Promise<AgeCheckResult>((resolve) => {
      resolve(AgeCheckResult.Denied);
      showMaxAlcoholicItems(intl, maxAlcoholicItems);
    });
  };
}

export function showMaxAlcoholicItems(intl: IntlShape, maxCount: number) {
  toast.warn(
    intl.formatMessage(
      {
        id: "AgeCheck.warning.limitReached",
        description: "This message is shown after reaching a limit of the number of alcoholic items in your basket.",
      },
      {
        maxCount: maxCount,
      }
    )
  );
}
