import React, { useCallback, useEffect, useState } from "react";
import Article from "../models/menu/Article";
import store, { useAppDispatch, useAppSelector } from "../redux/store";
import isKiosk from "../helpers/isKiosk";
import SlideUpTransition from "../../components/SlideUpTransition";
import isQr from "../helpers/isQr";
import { useDialog } from "../dialog/DialogProvider";
import useIsWidescreen from "../useIsWidescreen";
import { ButtonBase, Paper, Typography } from "@mui/material";
import { default as OrderArticleSelectorPageQr } from "../../../qr/pages/OrderArticleSelectorPage/OrderArticleSelectorPage";
import { default as OrderArticleSelectorPageKiosk } from "../../../kiosk/pages/OrderArticleSelectorPage/OrderArticleSelectorPage";
import Box from "@mui/material/Box";
import Image from "../../components/Image";
import FormattedMessageJamezz from "../../components/FormattedMessageJamezz";
import OrderArticle from "../models/order/OrderArticle";
import { getUpsellItems } from "./useDDAiData";
import { selectArticleHashNamesMap } from "../redux/selectors/selectArticleHashNamesMap";
import OrderPage from "../../../qr/pages/OrderPage/OrderPage";

export default function DisplayAIUpsellOrderPage() {
  const dispatch = useAppDispatch();
  const { openDialog } = useDialog();
  const isWidescreen = useIsWidescreen();
  const aiUpsellData = useAppSelector((state) => state.aidata.currentUpsell);
  const articleHashNamesMap = useAppSelector(selectArticleHashNamesMap);
  const productsLoaded = useAppSelector((state) => state.menuData.productsLoaded ?? false);
  const products = useAppSelector((state) => state.menuData.products);
  const useBasketUpsell = useAppSelector((state) => state.global.salesarea?.custom_data?.ai?.useBaksetUpsell ?? true);
  const [aiUpsellProducts, setAiUpsellProducts] = useState<Article[]>([]);
  const items = useAppSelector((state) => state.shoppingCart.items);
  const max_suggestions = useAppSelector((state) => state.global.salesarea?.custom_data?.ai?.max_suggestions ?? 4);

  useEffect(() => {
    const articles = getUpsellItems(aiUpsellData, articleHashNamesMap, max_suggestions);
    if (aiUpsellData && productsLoaded) {
      setAiUpsellProducts(articles);
    }
  }, [dispatch, products, aiUpsellData, productsLoaded, articleHashNamesMap, items, max_suggestions]);

  const postActionAdding = useCallback(() => {
    openDialog({
      children: <OrderPage />,
      fullScreen: true,
      TransitionComponent: SlideUpTransition,
    });
  }, [openDialog]);

  return useBasketUpsell && aiUpsellProducts.length > 0 && store.getState().shoppingCart.items.length > 0 ? (
    <Box>
      <FormattedMessageJamezz id={"Others also bought"} />
      <Box
        sx={{
          display: "flex",
          flexWrap: "nowrap",
          overflowX: "scroll",
          gap: 2,
          paddingTop: 2,
        }}
      >
        {aiUpsellProducts.map((article: Article) => (
          <Box key={article.hashedName} sx={{ width: "30vw", flex: "0 0 auto" }}>
            <Paper
              data-cy="piggy-reward-item"
              sx={{ borderRadius: 4, width: 1, height: 1, display: "flex", flexDirection: "column" }}
              onClick={() => {
                if (isKiosk()) {
                  openDialog({
                    children: (
                      <OrderArticleSelectorPageKiosk
                        article={article}
                        isUpselled={true}
                        upsellType={"AiUpsellCart"}
                        upsellSource={"CART"}
                        callAfterAdding={postActionAdding}
                      />
                    ),
                    fullScreen: "almostFullScreen",
                    TransitionComponent: SlideUpTransition,
                  });
                } else if (isQr()) {
                  openDialog({
                    children: (
                      <OrderArticleSelectorPageQr
                        article={article}
                        isUpselled={true}
                        upsellType={"AiUpsellCart"}
                        upsellSource={"CART"}
                        callAfterAdding={postActionAdding}
                      />
                    ),
                    fullScreen: !isWidescreen,
                    TransitionComponent: SlideUpTransition,
                  });
                }
              }}
            >
              <ButtonBase
                className={"JS-ArticleSuggestionItem-Root"}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  margin: 2,
                  flexBasis: "calc(50% - 32px)",
                  boxShadow: "0 1px 10px 0px rgba(0,0,0,0.2)",
                }}
              >
                <Image
                  srcSet={article.imageMediaUrls?.[0]?.conversions?.responsive?.srcset}
                  style={{
                    objectFit: "contain",
                    alignSelf: "center",
                    justifySelf: "center",
                    width: "100%",
                    marginRight: 32,
                    marginLeft: 32,
                  }}
                />
                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: "1fr",
                    textAlign: "left",
                    boxSizing: "content-box",
                    height: "100%",
                  }}
                >
                  <Typography sx={{ fontWeight: 800 }}>{article.name}</Typography>
                </Box>
              </ButtonBase>
            </Paper>
          </Box>
        ))}
      </Box>
    </Box>
  ) : null;
}
