import Article, { getArticleDescription, getArticleName } from "../../../global/utils/models/menu/Article";
import Box from "@mui/material/Box";
import CloseDialogButtonQr from "../../../qr/components/CloseButtons/CloseDialogButtonQr";
import { Button, Typography } from "@mui/material";
import Image from "../../../global/components/Image";
import MenuTag from "../../../global/utils/models/menu/MenuTag";
import MenuTagView from "../../../qr/components/MenuTagView";
import { TransitionGroup } from "react-transition-group";
import FormattedMessageJamezz from "../../../global/components/FormattedMessageJamezz";
import useCurrency, { CurrencyLocation } from "../../../global/utils/useCurrency";
import useMenuTagsForArticle from "../../../global/utils/hooks/useMenuTagsForArticle";
import { useDialog } from "../../../global/utils/dialog/DialogProvider";
import { useCustomTexts } from "../../../global/utils/useCustomTexts";
import { useAppSelector } from "../../../global/utils/redux/store.tsx";
import { selectAppLanguage } from "../LanguageSelector/useLanguage.ts";

export default function ArticleInformationDialog({ article }: { article: Article }) {
  const lang = useAppSelector(selectAppLanguage);
  const parseCurrency = useCurrency({ location: CurrencyLocation.ArticleDetail });
  const menuTags = useMenuTagsForArticle(article);

  const customTexts = useCustomTexts(["order_article_page_back_button"]);
  // const activeMenuTags = useActiveMenuTagsOfOrderArticle(orderArticle);
  const { closeDialog } = useDialog();
  return (
    <Box
      data-cy={"page-order-article-content"}
      className={"JS-OrderArticleSelectorPage-Root"}
      sx={{
        background: "background.paper",
        width: 1,
        maxWidth: 1080,
        flexGrow: 1,
        position: "relative",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        alignSelf: "center",
      }}
    >
      <Box
        className={"JS-OrderArticleSelectorPageContent-HeaderWrapper"}
        sx={{
          position: "sticky",
          top: 0,
          width: 1,
          display: "flex",
          alignItems: "center",
          backgroundColor: "background.paper",
          zIndex: 1,
          paddingY: 1,
          paddingX: 2,
        }}
      >
        <CloseDialogButtonQr />
        <Typography
          variant={"body1"}
          sx={{
            fontWeight: 800,
            marginLeft: "20px",
            textAlign: "center",
            width: 1,
            marginRight: 5.5,
          }}
          className={"JS-ArticleDialog-Name"}
        >
          {getArticleName(article, lang)}
        </Typography>
      </Box>
      <Box
        sx={{
          flex: "1 1 0%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: 1,
          marginTop: 2,
        }}
      >
        {article.imageMediaUrls?.[0]?.conversions?.responsive?.srcset ? (
          <Image
            srcSet={article.imageMediaUrls?.[0]?.conversions?.responsive?.srcset}
            style={{ maxHeight: 300, maxWidth: "100%", objectFit: "contain", width: "100%" }}
            className={"JS-OrderArticleSelectorPage-Image"}
          />
        ) : null}
        <Typography
          sx={{
            paddingX: 2,
            width: 1,
            fontSize: "2rem",
            marginTop: 3,
            wordBreak: "break-word",
            textAlign: "center",
          }}
          className={"JS-ArticleDialog-Description"}
          dangerouslySetInnerHTML={{ __html: getArticleDescription(article, lang) }}
        ></Typography>
        <Typography variant={"h6"} sx={{ fontStyle: "italic" }} className={"JS-ArticleDialog-Price"}>
          {article.price != 0 ? parseCurrency(article.price) : null}
        </Typography>

        <Box sx={{ display: "flex", flexWrap: "wrap", alignItems: "center", marginX: 2, marginBottom: 1 }}>
          {menuTags.map((menuTag: MenuTag) => {
            return <MenuTagView key={menuTag.id} menuTag={menuTag} width={48} />;
          })}
        </Box>
        <TransitionGroup>
          {/*{activeMenuTags.map((activeMenuTag, index) => (*/}
          {/*  <Collapse key={activeMenuTag.id}>*/}
          {/*    <Box sx={{ display: "flex", marginTop: index > 0 ? 2 : 0, flexWrap: "wrap", justifyContent: "center" }}>*/}
          {/*      <ActiveMenuTag menuTag={activeMenuTag} />*/}
          {/*    </Box>*/}
          {/*  </Collapse>*/}
          {/*))}*/}
        </TransitionGroup>
      </Box>

      <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
        <Button
          sx={{ marginBottom: 4 }}
          variant={"contained"}
          onClick={() => {
            closeDialog();
          }}
        >
          {customTexts["order_article_page_back_button"] ?? <FormattedMessageJamezz id={"Back"} />}
        </Button>
      </Box>
    </Box>
  );
}
