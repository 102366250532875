import OrderArticle, { getTotalPrice } from "../../../global/utils/models/order/OrderArticle";
import { Grid, Typography } from "@mui/material";
import { getArticleName } from "../../../global/utils/models/menu/Article";
import useCurrency, { CurrencyLocation } from "../../../global/utils/useCurrency";
import { useOptionTranslations } from "../../../global/utils/translations/useOptionTranslations";
import _ from "lodash";
import { useAppSelector } from "../../../global/utils/redux/store.tsx";
import { selectAppLanguage } from "../../../kiosk/components/LanguageSelector/useLanguage.ts";

export default function PrintOptionOrderArticle({
  orderArticle,
  count = 1,
}: {
  orderArticle: OrderArticle;
  count: number;
}) {
  const lang = useAppSelector(selectAppLanguage);
  const toCurrency = useCurrency({ location: CurrencyLocation.ArticleDetail });
  const { translate } = useOptionTranslations();

  const amount = getTotalPrice(orderArticle) * count;
  const amountSuffix = amount !== 0 ? " " + toCurrency(amount) : "";
  return (
    <Grid item xs={12} container key={orderArticle.uuid}>
      {orderArticle.count > 1 ? (
        <Typography
          sx={{
            marginRight: 1,
            fontSize: "0.8rem",
            opacity: 0.6,
          }}
        >
          {orderArticle.count}x
        </Typography>
      ) : null}
      <Typography sx={{ fontSize: "0.8rem", opacity: 0.6 }}>
        {(!_.isEmpty(orderArticle.article.translations)
          ? getArticleName(orderArticle.article, lang)
          : translate(orderArticle.article.name)) + amountSuffix}
      </Typography>
      {orderArticle.extraOrderArticles?.map((extraOrderArticle) => (
        <PrintOptionOrderArticle orderArticle={orderArticle} count={count * extraOrderArticle.count} />
      ))}
    </Grid>
  );
}
