import Box from "@mui/material/Box";
import { Button, Skeleton } from "@mui/material";
import { useDialog } from "../../../global/utils/dialog/DialogProvider";
import PiggyRewardsDialog from "./PiggyRewardsDialog.tsx";
import SlideUpTransition from "../../../global/components/SlideUpTransition";
import { useGetLoggedinUserQuery } from "../../../global/utils/redux/api/piggyAuthApi";
import FormattedMessageJamezz from "../../../global/components/FormattedMessageJamezz";
import { useCustomTexts } from "../../../global/utils/useCustomTexts";
import isQr from "../../../global/utils/helpers/isQr";
import useIsWidescreen from "../../../global/utils/useIsWidescreen";
import { postAnalyticsEvent } from "../../../global/utils/analytics/useAnalytics.ts";
import { useCallback } from "react";

const buttonHeightForSkeleton = 40;
const buttonWidthForSkeleton = 200;

export default function Piggy() {
  const { data: loggedInUser, isLoading } = useGetLoggedinUserQuery();
  const { openDialog } = useDialog();
  const isWidescreen = useIsWidescreen();
  const isLoggedIn = loggedInUser?.data.has_session ?? false;
  const email = loggedInUser?.data.piggy_contact_email ?? "";

  const customTexts = useCustomTexts(["menu page - save loyalty points button"]);

  const onClick = useCallback(() => {
    postAnalyticsEvent({
      category: "Piggy",
      action: "PiggyDialog",
    });
    openDialog({
      children: <PiggyRewardsDialog />,
      fullScreen: !(isQr() && isWidescreen),
      TransitionComponent: SlideUpTransition,
    });
  }, [isWidescreen, openDialog]);

  return (
    <Box sx={{ padding: 1, my: 2, textAlign: "center" }} data-cy="piggy-home">
      <Box component={"span"}>
        {isLoading ? (
          <Skeleton
            sx={{ mx: "auto" }}
            variant="rectangular"
            width={buttonWidthForSkeleton}
            height={buttonHeightForSkeleton}
          />
        ) : isLoggedIn ? (
          <Button
            className={"JS-PiggyLoyalty-Button"}
            variant={"contained"}
            data-cy="piggy-open-rewards-page"
            sx={{
              borderRadius: "45px",
              color: "white",
              padding: "12px 40px",
              fontSize: "14px",
              letterSpacing: "0.5px",
              fontFamily: "sans-serif",
              display: "inline-block",
            }}
            onClick={onClick}
          >
            <FormattedMessageJamezz id="Loyalty" />
            <Box component="span" sx={{ fontWeight: "normal", fontSize: "0.8rem" }}>
              {" " + email}
            </Box>
          </Button>
        ) : (
          <>
            <Button
              className={"JS-PiggyLoyalty-Button"}
              variant={"contained"}
              data-cy="piggy-btn-login"
              sx={{
                borderRadius: "45px",
                color: "primary.contrastText",
                padding: "12px 40px",
                fontSize: "14px",
                letterSpacing: "0.5px",
                fontFamily: "sans-serif",
                display: "inline-block",
              }}
              onClick={onClick}
            >
              {customTexts["menu page - save loyalty points button"] ?? "Save loyaltypoints!"}
            </Button>
          </>
        )}
      </Box>
    </Box>
  );
}
