import { useMemo, useState } from "react";
import Article from "../../../global/utils/models/menu/Article";
import Image from "../../../global/components/Image";
import { IconButton } from "@mui/material";
import { ArrowLeft, ArrowRight } from "@mui/icons-material";
import Box from "@mui/material/Box";
import { Swiper, SwiperSlide, useSwiper } from "swiper/react";
import "swiper/css";

interface Props {
  article: Article;
  bigger?: boolean;
}

export default function ArticleImages(props: Props) {
  const [index, setIndex] = useState(0);

  const maxIndex = useMemo(() => {
    return props.article.imageMediaUrls?.length - 1;
  }, [props.article]);

  const items = useMemo(() => {
    return props.article.imageMediaUrls.map((imageMediaUrl) => {
      return (
        <SwiperSlide key={imageMediaUrl.id}>
          <Box key={imageMediaUrl.id}>
            <Image
              key={imageMediaUrl.id}
              srcSet={imageMediaUrl.conversions?.responsive?.srcset}
              style={{ height: props.bigger ? 600 : 300, width: "100%", objectFit: "contain" }}
              className={"JS-OrderArticleSelectorPage-Image"}
            />
          </Box>
        </SwiperSlide>
      );
    });
  }, [props.article]);

  return (
    <Box sx={{ position: "relative", width: 1 }}>
      <Swiper
        tabIndex={index}
        spaceBetween={20}
        slidesPerView={1}
        onActiveIndexChange={(e) => {}}
        onSlideChange={(swiper) => setIndex(swiper.activeIndex)}
        onSwiper={(swiper) => {}}
      >
        {index > 0 && <PrevButton />}
        {index < maxIndex && <NextButton />}
        {items}
      </Swiper>
    </Box>
  );
}

function PrevButton() {
  const swiper = useSwiper();
  return (
    <IconButton
      sx={{ position: "absolute", left: 30, top: "50%", transform: "translate(0,-50%)", zIndex: 1 }}
      onClick={() => {
        swiper.slidePrev();
      }}
    >
      <ArrowLeft fontSize={"large"} />
    </IconButton>
  );
}
function NextButton() {
  const swiper = useSwiper();
  return (
    <IconButton
      // ref={nextElRef}
      sx={{ position: "absolute", right: 30, top: "50%", transform: "translate(0,-50%)", zIndex: 1 }}
      onClick={() => {
        swiper.slideNext();
        // setIndex((prevState) => prevState + 1);
      }}
    >
      <ArrowRight fontSize={"large"} />
    </IconButton>
  );
}
