import { useCallback, useMemo } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../global/utils/redux/store";
import ArticleTrait from "../../../../../global/utils/models/ArticleTraits/ArticleTrait";
import { ButtonBase, Grid, Typography } from "@mui/material";
import FormattedMessageJamezz from "../../../../../global/components/FormattedMessageJamezz";
import { getImageFilePath } from "../../../../../global/utils/models/ImageSrc";
import Image from "../../../../../global/components/Image";
import { setIsFilterPageOpen } from "../../../../../global/utils/redux/traitFilterSlice";
import { selectArticleTraitsMap } from "../../../../../global/utils/redux/selectors/selectArticleTraitsMap";

interface Props {
  xs: number;
}

export default function QuickTraitAsTopArticlegroupShortcutPage(props: Props) {
  const { quickFilterOnArticleTrait, allArticleTraits } = useAppSelector(selectArticleTraitsMap);

  if (allArticleTraits?.length > 0 && quickFilterOnArticleTrait != null) {
    return (
      <Content
        allArticleTraits={allArticleTraits}
        quickFilterOnArticleTrait={quickFilterOnArticleTrait}
        xs={props.xs}
      />
    );
  } else {
    return null;
  }
}

interface PropsContent {
  allArticleTraits: ArticleTrait[];
  quickFilterOnArticleTrait: ArticleTrait;
  xs: number;
}

function Content(props: PropsContent) {
  const dispatch = useAppDispatch();
  const allArticleTraits = props.allArticleTraits;
  const articleTraitsHaveValues = useMemo(() => {
    return allArticleTraits.some((articleTrait) => {
      return Object.keys(articleTrait.values).length > 0;
    });
  }, [allArticleTraits]);

  const imageSrc = useMemo(() => {
    if (props.quickFilterOnArticleTrait.imageSrcs[1]) {
      return props.quickFilterOnArticleTrait.imageSrcs[1];
    } else {
      return props.quickFilterOnArticleTrait.imageSrcs[0] ?? null;
    }
  }, [props.quickFilterOnArticleTrait]);
  const setDialogIsOpen = useCallback(
    (isOpen: boolean) => {
      dispatch(setIsFilterPageOpen(isOpen));
    },
    [dispatch]
  );

  if (!articleTraitsHaveValues) {
    return null;
  }

  return (
    <Grid item xs={props.xs} className={"JS-ArticlegroupsShortcutPage-GridItem"} sx={{ minHeight: 300 }}>
      <ButtonBase
        className={"JS-ArticlegroupsShortcutPage-ButtonBase"}
        sx={{ width: 1, height: 1, position: "relative", zIndex: 0 }}
        onClick={() => {
          setDialogIsOpen(true);
        }}
      >
        {props.quickFilterOnArticleTrait.imageSrcs[1] && (
          <Image
            src={getImageFilePath(imageSrc, 600)}
            style={{ position: "absolute", width: "100%", height: "100%", zIndex: 0, objectFit: "contain" }}
            className={"JS-ArticlegroupsShortcutPage-Image"}
          />
        )}
        <Typography sx={{ zIndex: 1 }} className={"JS-ArticlegroupsShortcutPage-Title"}>
          <FormattedMessageJamezz id={"Brands"} />
        </Typography>
      </ButtonBase>
    </Grid>
    // <TabUnstyled
    //   key={"ArticleTraitValues"}
    //   value={"ArticleTraitValues"}
    //   style={{ flexGrow: 1, border: "none", background: "none" }}
    //   className={"JS-MultipleLayersBottomTabBar-Top-Tab"}
    // >
    //
    //   <Box
    //     className={clsx(
    //       "JS-HorizontalArticlegroupTabBar-Button"
    //       // isArticlegroupSelected && "JS-HorizontalArticlegroupTabBar-Button-Selected"
    //     )}
    //     onClick={() => {
    //       setDialogIsOpen(true);
    //     }}
    //     sx={{ height: 1 }}
    //   >
    //     <Box
    //       sx={{
    //         width: 1,
    //         height: 1,
    //         display: "flex",
    //         flexDirection: "column",
    //         alignItems: "center",
    //       }}
    //     >
    //       <ArticlegroupTabContent
    //         text={props.quickFilterOnArticleTrait.name}
    //         imageSrc={props.quickFilterOnArticleTrait.imageSrcs[0] ?? null}
    //       />
    //     </Box>
    //   </Box>
    //   );
    // </TabUnstyled>
  );
}
