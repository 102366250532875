import CustomField from "../../../../types/shared/CustomField";
import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from "@mui/material";
import useCustomField from "./useCustomField";
import { selectAppLanguage } from "../../../../kiosk/components/LanguageSelector/useLanguage.ts";
import { useAppSelector } from "../../../../global/utils/redux/store.tsx";

interface Props {
  customField: CustomField;
}

function CustomFieldSelectContent(props: Props) {
  const { value, setCustomField } = useCustomField(props.customField);
  const customField = props.customField;
  const selectedLanguage = useAppSelector(selectAppLanguage);
  return (
    <FormControl sx={{ width: 1, marginY: 1 }}>
      <FormHelperText>{customField.description}</FormHelperText>
      <InputLabel>{customField.label}</InputLabel>
      <Select
        label={customField.label}
        variant={"outlined"}
        fullWidth
        inputProps={{ required: customField.required }}
        value={value}
        onChange={(e) => {
          setCustomField(e.target.value);
        }}
      >
        {customField.options?.map((option: any) => (
          <MenuItem value={option.id} key={option.id}>
            {option.translationOverwrite?.[selectedLanguage] ?? option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default CustomFieldSelectContent;
