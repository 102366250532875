import { VoucherDef } from "../../global/utils/vouchers/Voucher";

export type ValidResponse = {
  result: {
    status: "Valid";
    message: string;
    code: string;
  };
  products: any[];
  voucher: {
    id: number;
    active: 0 | 1;
    vouchercode: string;
    // JSON: {sessionId: string, timeout: DateTime}
    useRequestList: string;
  };
  voucherDef: VoucherDef;
  subVouchers: ValidResponse[];
};

type InvalidResponse = {
  result: {
    status: "Invalid";
    message: string;
    code: string;
  };
  products: [];
  voucher: null;
  voucherDef: [];
};

export type VoucherResponse = {
  data: {
    data: ValidResponse | InvalidResponse;
  };
};

export function assertIsValidVoucherResponse(
  response: VoucherResponse
): asserts response is { data: { data: ValidResponse } } {
  if (response.data.data.result.status !== "Valid") throw new Error("Invalid response");
}
