import clsx from "clsx";
import { FormLabel, styled } from "@mui/material";
import { useSwitch } from "@mui/base";
import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { useAppDispatch, useAppSelector } from "../../../global/utils/redux/store";
import { setMenuTagPreferences } from "../../../global/utils/redux/menuStateSlice";
import MenuTag, { getMenuTagTranslatedName } from "../../../global/utils/models/menu/MenuTag";
import Image from "../../../global/components/Image";
import { addAllergenOrderArticle, removeAllergenOrderArticle } from "../../../global/utils/redux/shoppingCartSlice";
import { initOrderArticle } from "../../../global/utils/models/order/OrderArticle";
import { selectArticlesMap } from "../../../global/utils/redux/selectors/selectArticlesMap";
import { unstable_LowPriority, unstable_scheduleCallback } from "scheduler";
import { selectAppLanguage } from "../../../kiosk/components/LanguageSelector/useLanguage.ts";
import { postAnalyticsEvent } from "../../../global/utils/analytics/useAnalytics.ts";

const grey = {
  400: "#BFC7CF",
  500: "#AAB4BE",
};

interface Props {
  menuTag: MenuTag;
  articleId?: number;
}

const BasicSwitchRoot = styled("span")(({ theme }) => ({
  fontSize: 0,
  position: "relative",
  display: "inline-block",
  width: "80px",
  height: "40px",
  margin: "10px",
  background: grey[400],
  borderRadius: "20px",
  cursor: "pointer",
  transform: "rotate(-90deg)",
  "&.Switch-disabled": {
    opacity: 0.4,
    cursor: "not-allowed",
  },

  "&.Switch-checked": {
    background: theme.palette.primary.main,
  },
}));

const BasicSwitchInput = styled("input")({
  cursor: "inherit",
  position: "absolute",
  width: "100%",
  height: "100%",
  top: 0,
  left: 0,
  opacity: 0,
  zIndex: 1,
  margin: 0,
});

const BasicSwitchThumb = styled("span")({
  display: "block",
  width: "28px",
  height: "28px",
  top: "6px",
  left: "3px",
  borderRadius: "32px",
  backgroundColor: "#fff",
  position: "relative",
  transition: "all 200ms ease",

  "&.Switch-focusVisible": {
    backgroundColor: grey[500],
    boxShadow: "0 0 1px 8px rgba(0, 0, 0, 0.25)",
  },

  "&.Switch-checked": {
    left: "47px",
    top: "6px",
    backgroundColor: "#fff",
  },
});

function MenuFilter(props: Props) {
  const menuTagPreferenceStore = useAppSelector((state) => state.menuState.menuTagPreferences[props.menuTag.id]);
  const [menuTagPreference, setMenuTagPreference] = useState(menuTagPreferenceStore);
  const dispatch = useAppDispatch();
  const articlesMap = useAppSelector(selectArticlesMap);

  const { menuTag } = props;
  const selectedLanguage = useAppSelector(selectAppLanguage);
  const { getInputProps, disabled, focusVisible } = useSwitch({});

  const stateClasses = {
    "JS-MenuFilter-Switch": true,
    "JS-MenuFilter-Switch-not-checked": !menuTagPreference?.checked ?? false,
    "JS-MenuFilter-Switch-checked": menuTagPreference?.checked ?? false,

    "Switch-checked": menuTagPreference?.checked ?? false,
    "Switch-disabled": disabled,
    "Switch-focusVisible": focusVisible,
  };

  useEffect(() => {
    if (props.articleId) {
      if (menuTagPreference?.checked) {
        const article = articlesMap[props.articleId];
        if (article) {
          unstable_scheduleCallback(unstable_LowPriority, function () {
            dispatch(
              addAllergenOrderArticle({
                allergenId: menuTag.id,
                orderArticle: initOrderArticle(articlesMap, article, 1, "", false),
              })
            );
          });
        }
      } else {
        unstable_scheduleCallback(unstable_LowPriority, function () {
          dispatch(removeAllergenOrderArticle({ allergenId: menuTag.id }));
        });
      }
    }
  }, [dispatch, articlesMap, menuTag.id, menuTagPreference, props.articleId]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        textAlign: "center",
        justifyContent: "space-between",
        height: 1,
        mx: 0.5,
      }}
      onClick={() => {
        postAnalyticsEvent({
          category: "MenuFilter",
          action: "setMenuTagPreferences(" + props.menuTag.name + ")",
        });
        const newChecked = !menuTagPreference?.checked;

        setMenuTagPreference({
          checked: newChecked,
          menuTag: props.menuTag,
        });

        unstable_scheduleCallback(unstable_LowPriority, function () {
          dispatch(
            setMenuTagPreferences({
              id: props.menuTag.id,
              value: { checked: newChecked, menuTag: props.menuTag },
            })
          );
        });

        if (props.articleId) {
          if (newChecked) {
            const article = articlesMap[props.articleId];
            if (article) {
              unstable_scheduleCallback(unstable_LowPriority, function () {
                dispatch(
                  addAllergenOrderArticle({
                    allergenId: menuTag.id,
                    orderArticle: initOrderArticle(articlesMap, article, 1, "", false),
                  })
                );
              });
            }
          } else {
            unstable_scheduleCallback(unstable_LowPriority, function () {
              dispatch(removeAllergenOrderArticle({ allergenId: menuTag.id }));
            });
          }
        }
      }}
    >
      <FormLabel sx={{ marginY: 2 }} key={selectedLanguage + "-" + menuTag.id}>
        {getMenuTagTranslatedName(menuTag, selectedLanguage)}
      </FormLabel>
      <BasicSwitchRoot className={clsx(stateClasses)}>
        <BasicSwitchThumb className={clsx(stateClasses)}>
          {menuTag.mediaUrl ? (
            <Image
              style={{ width: "100%", height: "100%" }}
              srcSet={menuTag.mediaUrl.conversions?.responsive?.srcset}
            />
          ) : null}
        </BasicSwitchThumb>
        <BasicSwitchInput {...getInputProps()} aria-label="Demo switch" />
      </BasicSwitchRoot>
    </Box>
  );
}

export default MenuFilter;
