import { useEffect, useMemo, useRef } from "react";
import { ButtonBase, Grid, Typography } from "@mui/material";
import { getArticlegroupsById } from "../../../global/utils/models/menu/Menu";
import { setSelectedArticlegroup } from "../../../global/utils/redux/menuSlice";
import { useAppDispatch, useAppSelector } from "../../../global/utils/redux/store";
import Image from "../../../global/components/Image";
import QuickTraitAsTopArticlegroupShortcutPage from "../../pages/MenuPage/MultipleLayersMenuPage/QuickTraits/QuickTraitAsTopArticlegroupShortcutPage";
import Articlegroup from "../../../global/utils/models/menu/Articlegroup";
import { selectArticlegroupsMap } from "../../../global/utils/redux/selectors/selectArticlegroupsMap";
import _ from "lodash";

export default function ArticlegroupsShortcutPage() {
  const articlegroupsMap = useAppSelector(selectArticlegroupsMap);

  const mediaUrls = useAppSelector((state) => state.global.salesarea.media_urls?.articlegroups_shortcut_page);
  const allArticlegroupsApiIdMap = useMemo(() => {
    const map: { [key: string]: Articlegroup } = {};
    Object.keys(articlegroupsMap).forEach((articlegroupId) => {
      const apiId1 = articlegroupsMap[articlegroupId].apiId1;
      if (apiId1) {
        map[apiId1] = articlegroupsMap[articlegroupId];
      }
    });
    return map;
  }, [articlegroupsMap]);

  const open = useAppSelector((state) => state.traitFilter.isFilterPageOpen);
  const stateOfArticleTraits = useAppSelector((state) => state.traitFilter.stateOfArticleTraits);
  const dispatch = useAppDispatch();
  const settings = useAppSelector((state) => state.global.salesarea.articlegroups_shortcut_page);
  const initialNumberOfArticleTraits = useRef<number | null>(null);
  const shouldNavigate = useRef<Articlegroup | null>(null);

  const numberOfColumns = useMemo(() => {
    return Number(settings.number_of_columns);
  }, [settings]);

  const xs = useMemo(() => {
    return 12 / numberOfColumns;
  }, [numberOfColumns]);

  useEffect(() => {
    const numberOfItems = Object.keys(stateOfArticleTraits).reduce((sum, arrKey) => {
      return sum + stateOfArticleTraits[arrKey].length;
    }, 0);

    if (initialNumberOfArticleTraits.current != null && numberOfItems > initialNumberOfArticleTraits.current) {
      settings.articlegroup_settings?.some((articlegroupSetting: any) => {
        const articlegroups = getArticlegroupsById(articlegroupsMap, [String(articlegroupSetting.value)]);
        const articlegroup = articlegroups?.[0];

        if (articlegroup) {
          shouldNavigate.current = articlegroup;
          return true;
        }
      });
    }
    initialNumberOfArticleTraits.current = numberOfItems;
  }, [articlegroupsMap, settings.articlegroup_settings, stateOfArticleTraits]);

  useEffect(() => {
    if (shouldNavigate.current && !open) {
      dispatch(setSelectedArticlegroup({ articlegroup: shouldNavigate.current, articlegroupsMap }));
    }
  }, [dispatch, open]);

  return (
    <Grid container sx={{ height: 1, flex: 1 }}>
      <QuickTraitAsTopArticlegroupShortcutPage xs={xs} />
      {settings.articlegroup_settings?.map((articlegroupSetting: any) => {
        const articlegroups = getArticlegroupsById(articlegroupsMap, [String(articlegroupSetting.value)]);

        let articlegroup = articlegroups?.[0];
        if (
          articlegroup == null &&
          articlegroupSetting.apiId1 &&
          allArticlegroupsApiIdMap[articlegroupSetting.apiId1]
        ) {
          articlegroup = allArticlegroupsApiIdMap[articlegroupSetting.apiId1];
        }

        if (articlegroup != null) {
          const mediaUrl = _.find(mediaUrls, (url) => url.id === articlegroupSetting.media_id);

          return (
            <Grid
              key={articlegroupSetting.id}
              item
              xs={xs}
              className={"JS-ArticlegroupsShortcutPage-GridItem"}
              sx={{ minHeight: 300 }}
            >
              <ButtonBase
                className={"JS-ArticlegroupsShortcutPage-ButtonBase"}
                sx={{ width: 1, height: 1, position: "relative", zIndex: 0 }}
                onClick={() => {
                  dispatch(setSelectedArticlegroup({ articlegroup, articlegroupsMap }));
                }}
              >
                {mediaUrl?.conversions?.optimized?.srcset ? (
                  <Image
                    srcSet={mediaUrl?.conversions?.optimized?.srcset}
                    style={{ position: "absolute", width: "100%", height: "100%", zIndex: 0, objectFit: "contain" }}
                    className={"JS-ArticlegroupsShortcutPage-Image"}
                  />
                ) : null}
                <Typography sx={{ zIndex: 1 }} className={"JS-ArticlegroupsShortcutPage-Title"}>
                  {articlegroup.name}
                </Typography>
              </ButtonBase>
            </Grid>
          );
        } else {
          return null;
        }
      })}
    </Grid>
  );
}
