import { useCallback, useEffect, useMemo, useRef } from "react";
import Box from "@mui/material/Box";
import useCheckPayment from "../../../global/utils/useCheckPayment";
import CheckoutArticleContent from "../CheckoutPage/CheckoutArticleContent";
import { Button, Collapse, Divider, Grid, Link, Skeleton, Typography } from "@mui/material";
import FormattedMessageJamezz from "../../../global/components/FormattedMessageJamezz";
import { createPath, routes } from "../../../global/utils/config";
import JamezzLoading from "../../../global/components/JamezzLoading/JamezzLoading";
import store, { useAppDispatch, useAppSelector } from "../../../global/utils/redux/store";
import { clearFieldsWithoutRemember, clearOrderCustomFields } from "../../../global/utils/redux/customFieldsSlice";
import { orderSucceeded, selectAutoAddedItems } from "../../../global/utils/redux/shoppingCartSlice";
import { NewPiggyPointsStatus, useShowNewPiggyPoints } from "../../../global/utils/piggy/useShowNewPiggyPoints";
import { postAnalyticsEvent, submitEComEvent } from "../../../global/utils/analytics/useAnalytics";
import useSalesareaType, { SalesareaType } from "../../../global/utils/hooks/useSalesareaType";
import useAppNavigate from "../../../global/utils/useAppNavigate";
import { useSearchParams } from "react-router-dom";
import DiscountsCheckCalculator from "../../../global/components/MWise/DiscountsCheckCalculator";
import { OrderState } from "../../../types/shared/checkPayment";
import { useCustomTexts } from "../../../global/utils/useCustomTexts";
import { selectVoucherOrderItems } from "../../../global/utils/redux/selectors/selectVoucherOrderItems";
import { selectVoucherDiscounts } from "../../../global/utils/vouchersV2/selectors/selectVoucherDiscounts";
import { orderCreated } from "../../../global/utils/redux/globalSlice";

interface Props {
  afterPay?: boolean;
}

export function useGoToHomeQr() {
  const navigate = useAppNavigate();
  return useCallback(() => {
    const startUrl = store.getState().global.salesarea.start_url;
    if (startUrl) {
      window.location.href = startUrl;
    } else {
      navigate(routes.qr.rootPath, { replace: true });
    }
  }, [navigate]);
}

export default function ReturnPage(props: Props) {
  const isSharedShoppingCartEnabled = useAppSelector((state) => state.shoppingCart.isSharedShoppingCartEnabled);
  const { orderState, responseData } = useCheckPayment();

  const items = useMemo(() => {
    const excluded = store.getState().shoppingCart.excludedFromOrdering;
    return store.getState().shoppingCart.items.filter((item) => !excluded.includes(item.article.id));
  }, []);
  const autoAddedItems = useMemo(() => {
    return selectAutoAddedItems(store.getState());
  }, []);

  const voucherOrderItems = useMemo(() => {
    return selectVoucherOrderItems(store.getState());
  }, []);

  const goToHome = useGoToHomeQr();
  const navigate = useAppNavigate();
  const dispatch = useAppDispatch();
  const salesareaType = useSalesareaType();
  const afterOrderUrl = useAppSelector((state) => state.global.salesarea?.afterOrderUrl);
  const afterOrderTimeout = useAppSelector((state) => state.global.salesarea?.afterOrderTimeout);
  const showQrCode = useAppSelector((state) => state.global.salesarea?.genQRCode);
  const showReview = useAppSelector((state) => state.global.salesarea?.showReview);
  const orderMessage = useRef<any>(null);
  const { newlyReceivedPoints, onAfterPayment, status: piggyNewPointsStatus } = useShowNewPiggyPoints();
  const customTexts = useCustomTexts(["order_dispatch_error"]);

  useEffect(() => {
    let timeout: ReturnType<typeof window.setTimeout> | null = null;
    if (orderState === OrderState.SUCCESS) {
      store.dispatch(clearOrderCustomFields());
      store.dispatch(orderSucceeded());
      postAnalyticsEvent({
        category: "ReturnPage",
        action: "OrderState.SUCCESS",
      });

      submitEComEvent("purchase", autoAddedItems, items, voucherOrderItems, {
        value: responseData.current.orderValue,
        transaction_id: responseData.current.payTransID ?? "n/a",
      });
      submitEComEvent("add_payment_info", autoAddedItems, items, voucherOrderItems, {
        value: responseData.current.orderValue,
        payment_type: responseData.current.payMethod ?? "CASH",
      });

      onAfterPayment(responseData.current.timestamp);
      if (store.getState().global.sessionState?.payAfter === 0) {
        timeout = setTimeout(() => {
          if (afterOrderUrl) {
            window.location = afterOrderUrl;
          }
        }, Math.max(3000, afterOrderTimeout * 1000));
      }
      if (isSharedShoppingCartEnabled) {
        /**
         * Clear previous order state
         */
        dispatch(orderCreated(null));
      }
    }
    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [
    orderState,
    afterOrderTimeout,
    isSharedShoppingCartEnabled,
    afterOrderUrl,
    responseData,
    autoAddedItems,
    items,
    voucherOrderItems,
    dispatch,
  ]); // do not add onAfterPayment as dependency

  const isLoading = useMemo(() => {
    return !(
      orderState === OrderState.SUCCESS ||
      orderState === OrderState.FAILED ||
      orderState === OrderState.PAYMENT_PENDING ||
      orderState === OrderState.PAYMENT_FAILED ||
      orderState === OrderState.ORDER_FAILED ||
      orderState === OrderState.PRINT_FAILED
    );
  }, [orderState]);

  const isNotDispatched = useMemo(() => {
    return !isLoading && orderState === OrderState.ORDER_FAILED;
  }, [orderState, isLoading]);

  const isFailed = useMemo(() => {
    return (
      !isLoading &&
      orderState !== OrderState.SUCCESS &&
      orderState !== OrderState.PAYMENT_PENDING &&
      orderState !== OrderState.WAITING_ON_DISPATCH
    );
  }, [orderState, isLoading]);

  const isPending = useMemo(() => {
    return !isLoading && orderState === OrderState.PAYMENT_PENDING;
  }, [orderState, isLoading]);

  const themeColor = useMemo(() => {
    if (isLoading) {
      return "primary.main";
    } else if (isFailed) {
      return "error.main";
    } else if (isPending) {
      return "error.main";
    } else {
      return "#68ab81";
    }
  }, [isLoading, isFailed, isPending]);

  useEffect(() => {
    if (orderMessage.current && responseData.current?.orderMsg) {
      orderMessage.current.innerHTML = responseData.current.orderMsg;
    }
  }, [orderMessage, orderState, responseData]);

  const message = useMemo(() => {
    switch (orderState) {
      case OrderState.WAITING_FOR_PAYMENT:
        return (
          <Typography sx={{ color: "lightgrey", whiteSpace: "nowrap" }}>
            <FormattedMessageJamezz id={"Waiting for payment..."} />
          </Typography>
        );
      case OrderState.WAITING_ON_DISPATCH:
        return (
          <Typography sx={{ color: "lightgrey", whiteSpace: "nowrap" }}>
            <FormattedMessageJamezz id={"Waiting for order response..."} />
          </Typography>
        );
      case OrderState.INIT:
        return (
          <Typography sx={{ color: "lightgrey", whiteSpace: "nowrap" }}>
            {props.afterPay ? (
              <FormattedMessageJamezz id={"Waiting for payment..."} />
            ) : (
              <FormattedMessageJamezz id={"Checking order..."} />
            )}
          </Typography>
        );
      case OrderState.PRINT_FAILED:
      case OrderState.WAITING_FOR_PRINT:
      case OrderState.SUCCESS:
        return (
          <>
            <Typography sx={{ color: "lightgrey", whiteSpace: "nowrap" }}>
              {props.afterPay ? (
                <FormattedMessageJamezz id={"Your payment has been sent successfully!"} />
              ) : (
                <FormattedMessageJamezz id={"Your order is placed successfully!"} />
              )}
            </Typography>
          </>
        );
      case OrderState.ORDER_FAILED:
        return (
          <Typography sx={{ color: "error.main", whiteSpace: "break-spaces", textAlign: "center" }}>
            {customTexts["order_dispatch_error"] ?? <FormattedMessageJamezz id={"Dispatch failed"} />}
          </Typography>
        );
      case OrderState.FAILED:
        return (
          <Typography sx={{ color: "error.main", whiteSpace: "nowrap" }}>
            <FormattedMessageJamezz id={"There was some error!"} />
          </Typography>
        );
      case OrderState.PAYMENT_FAILED:
        return (
          <Typography sx={{ color: "error.main", whiteSpace: "nowrap" }}>
            <FormattedMessageJamezz id={"Payment failed..."} />
          </Typography>
        );
      case OrderState.PAYMENT_PENDING:
        return (
          <Typography sx={{ color: "war.main", whiteSpace: "nowrap" }}>
            <FormattedMessageJamezz id={"Payment pending..."} />
          </Typography>
        );
    }
    return (
      <Typography sx={{ color: "lightgrey", whiteSpace: "nowrap" }}>
        <FormattedMessageJamezz id={"Checking order..."} />
      </Typography>
    );
  }, [customTexts, orderState, props.afterPay]);

  const piggyResult =
    piggyNewPointsStatus === NewPiggyPointsStatus.PiggyNotEnabled ? null : (
      <Box data-cy="orderpage-piggy-points-earned" sx={{ mt: 2, textAlign: "center" }}>
        <Typography variant="h2" fontSize="1.2em" sx={{ mb: 1, mt: 2 }}>
          <FormattedMessageJamezz id="Piggy.gainedPoints.title" />
        </Typography>
        {piggyNewPointsStatus === NewPiggyPointsStatus.Loading ? (
          <Typography>
            <Skeleton variant="text" sx={{ width: "80vw" }} />
          </Typography>
        ) : piggyNewPointsStatus === NewPiggyPointsStatus.MissedOutOnPoints ? (
          <Typography>
            <FormattedMessageJamezz id="Piggy.gainedPoints.missedOut" />
          </Typography>
        ) : piggyNewPointsStatus === NewPiggyPointsStatus.Success ? (
          <>
            <Typography>
              <FormattedMessageJamezz
                id="Piggy.gainedPoints.receivedPoints"
                values={{
                  newPoints: newlyReceivedPoints,
                }}
              />
            </Typography>
            {/*{piggyPoints ? (*/}
            {/*  <FormattedMessageJamezz*/}
            {/*    id="Piggy.gainedPoints.totalPoints"*/}
            {/*    values={{*/}
            {/*      points: piggyPoints,*/}
            {/*    }}*/}
            {/*  />*/}
            {/*) : null}*/}
          </>
        ) : piggyNewPointsStatus === NewPiggyPointsStatus.Error ? (
          <Typography>
            <FormattedMessageJamezz id="Piggy.gainedPoints.error" />
          </Typography>
        ) : null}
      </Box>
    );

  const voucherDiscounts = useMemo(() => {
    return selectVoucherDiscounts(store.getState());
  }, []);

  return (
    <Box
      sx={{
        paddingTop: 9,
        paddingX: 4,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        maxWidth: 800,
        width: 1,
        marginX: "auto",
      }}
    >
      <Grid container>
        <Grid item xs={12}>
          <Typography variant={"h5"} sx={{ fontStyle: "italic", fontWeight: 800, textAlign: "center", width: 1 }}>
            {!props.afterPay ? (
              <FormattedMessageJamezz id={"Your order"} />
            ) : (
              <FormattedMessageJamezz id={"Your payment"} />
            )}
          </Typography>
        </Grid>
        {!props.afterPay ? <DiscountsCheckCalculator /> : null}
        {!props.afterPay
          ? autoAddedItems.map((orderArticle) => (
              <CheckoutArticleContent
                key={orderArticle.uuid}
                orderArticle={orderArticle}
                voucherDiscounts={voucherDiscounts}
              />
            ))
          : null}
        {!props.afterPay
          ? items.map((orderArticle, index) => (
              <CheckoutArticleContent
                key={orderArticle.uuid}
                orderArticle={orderArticle}
                piggyDiscountsIndex={index}
                voucherDiscounts={voucherDiscounts}
              />
            ))
          : null}
        {!props.afterPay
          ? voucherOrderItems.map((orderArticle) => (
              <CheckoutArticleContent
                key={orderArticle.uuid}
                orderArticle={orderArticle}
                voucherDiscounts={voucherDiscounts}
              />
            ))
          : null}
      </Grid>
      <Box sx={{ width: 100, height: 100, marginTop: 2 }}>
        <JamezzLoading
          isLoading={isLoading}
          color={themeColor}
          isSuccessful={!isFailed && !isLoading && !isPending ? true : false}
          padding={20}
        />
      </Box>
      <Box sx={{ marginBottom: 5, width: 1 }}>
        <Typography sx={{ width: 1, whiteSpace: "unset", textAlign: "center" }}>{message}</Typography>
      </Box>

      <Collapse in={!isLoading && !isFailed && !isPending ? true : false}>
        <Grid container sx={{ display: "flex", justifyContent: "center" }}>
          <Grid item>
            <Button
              data-cy="return-page-start-new-order-btn"
              variant={"contained"}
              color={"primary"}
              onClick={() => {
                postAnalyticsEvent({
                  category: "ReturnPage",
                  action: "Start new order",
                });
                clearFieldsWithoutRemember();
                goToHome();
              }}
              style={{ marginBottom: "25px" }}
            >
              {salesareaType === SalesareaType.CATALOG || props.afterPay ? (
                <FormattedMessageJamezz id={"Go back to menu card"} />
              ) : (
                <FormattedMessageJamezz id={"Start new order"} />
              )}
            </Button>
          </Grid>
          {!props.afterPay ? <Grid item>{piggyResult}</Grid> : null}
        </Grid>
        <Grid container sx={{ display: "flex", justifyContent: "center" }}>
          <Grid item>
            {!isLoading && !isFailed && !isPending ? <Typography sx={{ marginY: 3 }} ref={orderMessage} /> : null}
          </Grid>
        </Grid>
        {showQrCode && responseData.current.orderHash ? (
          <>
            <Divider />
            <Box
              sx={{
                p: 1,
                maxWidth: 1,
                boxSizing: "content-box",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Link
                href={"/qrs/code/gen/" + responseData.current.orderHash + "?download=true"}
                target="_blank"
                style={{ maxWidth: "60%" }}
              >
                <img
                  src={"/qrs/code/gen/" + responseData.current.orderHash}
                  style={{ width: "100%", height: "100%" }}
                />
              </Link>
            </Box>

            <Typography style={{ fontWeight: 800 }}>
              <FormattedMessageJamezz
                id={
                  "Click the QR-Code to save it on your device. You can also find your QR-Code in your e-mail if you entered your e-mailaddress with the order. You need it to collect your items."
                }
              />
            </Typography>
          </>
        ) : null}
        {showReview ? (
          <Grid container sx={{ display: "flex", justifyContent: "center" }}>
            <Grid item style={{ textAlign: "center" }}>
              <Typography sx={{ textAlign: "center", width: 1 }}>
                <FormattedMessageJamezz id={"Write a review"} />
              </Typography>
              <a href="https://g.page/r/CZm_mmG-lLwjEAg/review" target="_blank" rel="noreferrer">
                <img src="/img/googleReview.png" alt="Open google bedrijven" style={{ maxWidth: "175px" }} />
              </a>
            </Grid>
          </Grid>
        ) : null}
      </Collapse>
      <Collapse in={!isLoading && !isFailed && isPending && !isNotDispatched ? true : false}>
        <Grid container sx={{ display: "flex", justifyContent: "center" }}>
          <Grid item>
            <Button
              variant={"contained"}
              color={"primary"}
              onClick={() => {
                postAnalyticsEvent({
                  category: "ReturnPage",
                  action: "Start new order",
                });
                clearFieldsWithoutRemember();
                goToHome();
              }}
              style={{ marginBottom: "25px" }}
            >
              <FormattedMessageJamezz id={"Start new order"} />
            </Button>
          </Grid>
        </Grid>
        <Grid container sx={{ display: "flex", justifyContent: "center" }}>
          <Grid item>
            {!isLoading && !isFailed && isPending ? <Typography sx={{ marginY: 3 }} ref={orderMessage} /> : null}
          </Grid>
        </Grid>

        {showReview ? (
          <Grid container sx={{ display: "flex", justifyContent: "center" }}>
            <Grid item style={{ textAlign: "center" }}>
              <Typography sx={{ textAlign: "center", width: 1 }}>
                <FormattedMessageJamezz id={"Write a review"} />
              </Typography>
              <a href="https://g.page/r/CZm_mmG-lLwjEAg/review" target="_blank" rel="noreferrer">
                <img src="/img/googleReview.png" alt="Open google bedrijven" style={{ maxWidth: "175px" }} />
              </a>
            </Grid>
          </Grid>
        ) : null}
      </Collapse>
      <Collapse in={!isLoading && isFailed && !isPending && !isNotDispatched ? true : false}>
        <Grid container spacing={6}>
          <Grid item xs={6}>
            <Button
              variant={"contained"}
              color={"error"}
              size={"large"}
              onClick={() => navigate(createPath(routes.qr.checkoutPath))}
              data-cy="retry-btn"
            >
              <FormattedMessageJamezz id={"Go to checkout"} />
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              variant={"contained"}
              color={"error"}
              size={"large"}
              onClick={() => {
                postAnalyticsEvent({
                  category: "ReturnPage",
                  action: "Stop",
                });

                clearFieldsWithoutRemember();
                goToHome();
              }}
            >
              <FormattedMessageJamezz id={"Stop"} />
            </Button>
          </Grid>
        </Grid>
      </Collapse>
    </Box>
  );
}

export function ReturnPageWrapper() {
  const [params] = useSearchParams();

  const oid = params.get("oid") ?? "oid";

  return <ReturnPage key={oid} />;
}
