import { memo, RefObject, useEffect, useMemo, useRef, useState } from "react";
import ArticleContent from "./ArticleContent/ArticleContent";
import { Collapse } from "@mui/material";
import Articlegroup from "../../../global/utils/models/menu/Articlegroup";
import useArticlegroup from "../../../global/utils/menu/useArticlegroup";
import { useArticleTraitFiltering } from "../../../global/utils/menu/filters/useArticleTraitFiltering";
import useArticleSearchFiltering from "../../../global/utils/menu/filters/useArticleSearchFiltering";
import ArticlegroupBanner from "./ArticlegroupBanner";
import Box from "@mui/material/Box";
import { useAppSelector } from "../../../global/utils/redux/store";
import SearchContent from "./SearchContent";
import { selectArticlegroupsMap } from "../../../global/utils/redux/selectors/selectArticlegroupsMap";
import { selectArticlegroupChildrenMap } from "../../../global/utils/redux/selectors/selectArticlegroupChildrenMap";
import Article from "../../../global/utils/models/menu/Article";
import { postEcomAnalyticsEvent } from "../../../global/utils/analytics/useAnalytics";
import SubArticlegroupTabBar from "../../components/SubArticlegroupsTabBar/SubArticlegroupsTabBar";
import { useArticlegroupFilter } from "../../../global/utils/menu/filters/useMenuFilters";
import Image from "../../../global/components/Image";

export type MenuOrientation = "HORIZONTAL" | "VERTICAL";
interface Props {
  articlegroup: Articlegroup;
  menuOrientation: MenuOrientation;
  pixelHeightCartButton?: string;
  depth: number;
  scrollingRef?: RefObject<HTMLElement>;
}

function _ArticlegroupContent(props: Props) {
  const { articles } = useArticlegroup(props.articlegroup);
  const { articleTraitFilter } = useArticleTraitFiltering();
  const searchArticles = useArticleSearchFiltering();
  const articlegroupFilter = useArticlegroupFilter();
  const articlegroupsMap = useAppSelector(selectArticlegroupsMap);
  const useArticlegroupsHierarchically = useAppSelector(
    (state) => state.global.salesarea.custom_data?.appv5_use_articlegroups_hierarchically?.enabled
  );
  const articlegroupChildrenMap = useAppSelector(selectArticlegroupChildrenMap);
  const childArticlegroupIds = useMemo(() => {
    return articlegroupChildrenMap[props.articlegroup.id] ?? [];
  }, [articlegroupChildrenMap, props.articlegroup]);

  const childArticlegroups = useMemo(() => {
    return (
      childArticlegroupIds?.map((articlegroupId) => articlegroupsMap[articlegroupId]).filter(articlegroupFilter) ?? []
    );
  }, [childArticlegroupIds, articlegroupsMap, articlegroupFilter]);

  const filteredArticles = searchArticles(articles.filter(articleTraitFilter));
  const contentRef = useRef<null | HTMLDivElement>(null);
  const widthPercentage = useMemo(() => {
    return 1 / (props.articlegroup.numberOfColumns ?? 2);
  }, [props.articlegroup]);
  const searchValue = useAppSelector((state) => state.global.articleSearchText);
  const isStickyBanner =
    useAppSelector((state) => state.global.salesarea.custom_data?.styling?.kiosk?.stickyBanner) ?? false;

  useEffect(() => {
    /**
     * Anayltics ecom data
     */
    const ecomAnalyticsItems: object[] = [];
    filteredArticles.forEach((article: Article) => {
      ecomAnalyticsItems.push({
        item_id: article.id,
        item_name: article.name,
        price: article.price,
        quantity: 1,
      });
    });
    postEcomAnalyticsEvent("view_item_list", {
      item_list_id: props.articlegroup.id,
      item_list_name: props.articlegroup.name,
      items: ecomAnalyticsItems,
    });
  }, [filteredArticles, props.articlegroup]);

  const [height, setHeight] = useState(0);
  const [heightParent, setHeightParent] = useState(0);

  useEffect(() => {
    setTimeout(() => {
      let element = document.getElementById("JS-ArticlegroupContent-StickyBox:" + (props.depth - 1));
      if (element) {
        setHeightParent(element.getBoundingClientRect().height);
      }
      element = document.getElementById("JS-ArticlegroupContent-StickyBox:" + props.depth);
      if (element) {
        setHeight(element.getBoundingClientRect().height);
      }
    }, 500);
  }, []);

  return (
    <>
      <Box
        id={"Articlegroup-" + props.articlegroup.id}
        className={"JS-ArticlegroupContent-Root"}
        ref={contentRef}
        sx={{
          minHeight: props.menuOrientation === "VERTICAL" ? "calc(100% - 200px)" : undefined,
          display: "flex",
          flexWrap: "wrap",
          alignContent: "flex-start",
          width: 1,
        }}
      >
        <Collapse
          in={!searchValue}
          sx={{ width: 1, ...(isStickyBanner ? { position: "sticky", top: 0, zIndex: 22 + props.depth } : {}) }}
        >
          {props.articlegroup.imageBannerMediaUrl ? (
            <Image
              key={props.articlegroup.imageBannerMediaUrl.id}
              srcSet={props.articlegroup.imageBannerMediaUrl?.conversions?.responsive?.srcset}
              style={{
                display: "block",
                width: "100%",
                height: "auto",
                objectFit: "cover",
              }}
              className={"JS-ArticlegroupContent-Image"}
            />
          ) : null}
        </Collapse>
        <Box
          sx={{
            ...(isStickyBanner ? {} : { position: "sticky", top: props.depth * heightParent - 1 }),
            width: 1,
            zIndex: 20 - props.depth,
            // backgroundImage: `linear-gradient(to top, ${defaultColor + "0"}, ${defaultColor + "F"})`,
            backgroundColor: "background.default",
            // transition: "all 10000ms ease-in",
          }}
          className={"JS-ArticlegroupContent-StickyBox"}
          id={"JS-ArticlegroupContent-StickyBox:" + props.depth}
        >
          {!useArticlegroupsHierarchically || childArticlegroups?.length > 0 || filteredArticles.length > 0 ? (
            <ArticlegroupBanner
              articlegroup={props.articlegroup}
              addKioskTopBar={props.depth === 0 && props.menuOrientation === "VERTICAL"}
            />
          ) : null}
          {!searchValue && useArticlegroupsHierarchically && childArticlegroups?.length > 0 && props.depth === 0 ? (
            <>
              <SubArticlegroupTabBar
                articlegroups={childArticlegroups}
                contentRef={props.scrollingRef}
                heightTopBar={height}
              />
            </>
          ) : null}
        </Box>
        {searchValue ? (
          <SearchContent
            pixelHeightCartButton={props.pixelHeightCartButton ?? "200px"}
            scrollContainerRef={props.scrollingRef}
          />
        ) : (
          <>
            {filteredArticles.map((article) => (
              <Box key={article.id} sx={{ width: widthPercentage }}>
                <ArticleContent articlegroup={props.articlegroup} article={article} />
              </Box>
            ))}
            {useArticlegroupsHierarchically
              ? childArticlegroups?.map((articlegroup) => {
                  return (
                    <ArticlegroupContent
                      key={articlegroup.id}
                      menuOrientation={props.menuOrientation}
                      articlegroup={articlegroup}
                      depth={(props.depth ?? 0) + 1}
                    />
                  );
                })
              : null}
          </>
        )}
      </Box>
    </>
  );
}

const ArticlegroupContent = memo(_ArticlegroupContent);
export default ArticlegroupContent;
