import { alpha, ButtonBase, Collapse, IconButton, Paper, Typography, useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import { useDialog } from "../../../global/utils/dialog/DialogProvider";
import useCurrency, { CurrencyLocation } from "../../../global/utils/useCurrency";
import { useMemo } from "react";
import { Close, InfoOutlined, Loyalty } from "@mui/icons-material";
import Article, { ArticleType, getArticleDescription, getArticleName } from "../../../global/utils/models/menu/Article";
import CountDownTimer from "../../../global/components/CountDownTimer";
import SlideUpTransition from "../../../global/components/SlideUpTransition";
import OrderArticleSelectorPage from "../OrderArticleSelectorPage/OrderArticleSelectorPage";
import useArticle from "../../../global/utils/menu/useArticle";
import Image from "../../../global/components/Image";
import FormattedMessageJamezz from "../../../global/components/FormattedMessageJamezz";
import { useAppSelector } from "../../../global/utils/redux/store";
import MenuTagView from "../../components/MenuTagView";
import useMenuTagsForArticle from "../../../global/utils/hooks/useMenuTagsForArticle";
import Articlegroup from "../../../global/utils/models/menu/Articlegroup";
import { postAnalyticsEvent, postEcomAnalyticsEvent } from "../../../global/utils/analytics/useAnalytics";
import { JSClassAnnotatedWithIds } from "../../../kiosk/utils/Helpers";
import { MenuTagType } from "../../../global/utils/models/menu/MenuTag";
import { useIsPiggyOffer } from "../../../global/utils/piggy/usePiggyRewards";
import PiggyRewardsDialog from "../../components/Piggy/PiggyRewardsDialog.tsx";
import useIsWidescreen from "../../../global/utils/useIsWidescreen";
import { ComponentOverlay } from "../../../global/components/ComponentOverlay";
import JTooltip from "../../../kiosk/components/JamezzMUI/JTooltip";
import { selectAppLanguage } from "../../../kiosk/components/LanguageSelector/useLanguage";
import { selectArticleMissingImagePlaceholder } from "../../../global/utils/redux/selectors/selectArticleMissingImagePlaceholder";
import QuickAddOrderButton from "./QuickAddOrderButton/QuickAddOrderButton";

interface Props {
  article: Article;
  isColumnOrientation: boolean;
  articlegroup: Articlegroup;
}

function ArticleContent(props: Props) {
  const { openDialog } = useDialog();
  const toCurrency = useCurrency({ location: CurrencyLocation.Menu });
  const lang = useAppSelector(selectAppLanguage);
  const theme = useTheme();
  const { collapsed, setCollapsed, secondsUntilEndTime, activeMenuTags } = useArticle(props.article);
  const menuTags = useMenuTagsForArticle(props.article);
  // const activeMenuTags = useActiveMenuTags(props.article);
  const articleMissingImagePlaceholder = useAppSelector(selectArticleMissingImagePlaceholder);
  const backgroundColor = useMemo(() => {
    // @ts-ignore
    return alpha(theme.palette.background.third, 1);
  }, [theme]);
  const piggyOffer = useIsPiggyOffer(props.article);
  const piggyRewardsClaimingEnabled = useAppSelector(
    (state) =>
      state.global.salesarea.piggy.loyalty.enabled && state.global.salesarea.piggy.loyalty.rewards_redeeming_enabled
  );
  const isColumnDirection = props.isColumnOrientation;
  const isWidescreen = useIsWidescreen();
  const isCatalogMode = useAppSelector((state) => state.global.salesarea.salesarea_type === "CATALOG");

  const allergenIsActive = useMemo(() => {
    return activeMenuTags.some((activeMenuTag) => {
      return activeMenuTag.type === MenuTagType.Exclude;
    });
  }, [activeMenuTags]);

  return (
    <Collapse
      in={!collapsed}
      sx={{
        display: "flex",
        flexDirection: "column",
        // flex: "1 1 0%",
        // "& .MuiCollapse-wrapper": { display: "flex", flexDirection: "column", flex: "1 1 0%" },
        "& .MuiCollapse-wrapper": { height: 1 },
        "& .MuiCollapse-wrapperInner": {
          display: "flex",
          flexDirection: "column",
          height: 1,
          // flex: "1 1 0%",
        },
        height: 1,
      }}
      unmountOnExit
    >
      {props.article.customData?.box_tooltip?.custom_html ? (
        <div
          dangerouslySetInnerHTML={{
            __html: props.article.customData?.box_tooltip?.custom_html,
          }}
        />
      ) : null}
      {/*discount, Loyalty, MoneyOff, Stars, VolunteerActivism, Redeem */}
      <Paper
        data-cy="article-content"
        sx={{
          opacity: props.article.type === ArticleType.Filler ? 0 : allergenIsActive ? 0.6 : 1,
          margin: 1,
          position: "relative",
          borderRadius: 3,
          overflow: "hidden",
          display: "flex",
          flexDirection: "column",
          paddingTop: piggyOffer ? 4 : 0,
          backgroundColor: allergenIsActive ? "red" : props.article.customData?.bgColor ?? undefined,
          flex: "1 1 0%",
        }}
        className={JSClassAnnotatedWithIds("JS-ArticleContent-Paper", props.article)}
      >
        <ComponentOverlay
          disabled={!props.article.isOutOfStock && !props.article.isNotAvailable ? true : false}
          overlayContent={
            props.article.isOutOfStock ? (
              <Typography variant={"h6"} sx={{ fontWeight: 1000 }} className={"JS-ArticleContent-OutOfStock-Text"}>
                <FormattedMessageJamezz id={"OUT OF STOCK"} />
              </Typography>
            ) : props.article.isNotAvailable ? (
              <Typography variant={"h6"} sx={{ fontWeight: 1000 }} className={"JS-NotAvailable-NotAvailable-Text"}>
                <FormattedMessageJamezz id={"NOT AVAILABLE"} />
              </Typography>
            ) : (
              <></>
            )
          }
          className={
            props.article.isOutOfStock
              ? "JS-ArticleContent-OutOfStock"
              : props.article.isNotAvailable
              ? "JS-ArticleContent-NotAvailable"
              : ""
          }
        >
          <>
            {piggyOffer && piggyRewardsClaimingEnabled ? (
              <ButtonBase data-cy="piggy-product-reward-banner">
                <Box
                  onClick={() => {
                    openDialog({
                      children: <PiggyRewardsDialog reward={piggyOffer} />,
                      fullScreen: !isWidescreen,
                      TransitionComponent: SlideUpTransition,
                    });
                  }}
                  className="JS-ArticleContent-PiggyRewardBanner"
                  sx={{
                    width: "100%",
                    backgroundColor: "#ccc",
                    height: "32px",
                    position: "relative",
                    top: "-32px",
                    display: "flex",
                    padding: "8px",
                    borderRadius: "4px",
                    borderBottomLeftRadius: 0,
                    borderBottomRightRadius: 0,
                    alignItems: "center",
                  }}
                >
                  <Loyalty fontSize="small" sx={{ mr: "4px" }} />
                  <Typography sx={{ display: "inline", fontSize: "1rem" }}>
                    <FormattedMessageJamezz
                      id="Piggy.menu-page.messages.get-for-free"
                      values={{
                        points: piggyOffer.required_credits,
                      }}
                    />
                  </Typography>
                </Box>
              </ButtonBase>
            ) : null}
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                flex: "1 1 0%",
              }}
              className={JSClassAnnotatedWithIds("JS-ArticleContent-FilterTag-Wrapper", props.article)}
            >
              <ButtonBase
                data-cy="open-article-button"
                className={JSClassAnnotatedWithIds("JS-ArticleContent-ButtonBase", props.article)}
                sx={{
                  opacity: props.articlegroup.disableOrdering ? 0.5 : 1,
                  width: 1,
                  borderRadius: 3,
                  textAlign: "left",
                  display: "flex",
                  flexDirection: isColumnDirection ? "column" : "row",
                  flex: "1 1 0%",
                }}
                // disabled={props.articlegroup.disableOrdering}
                onClick={() => {
                  if (
                    !(
                      props.article.isOutOfStock ||
                      props.article.isNotAvailable ||
                      props.article.type === ArticleType.InfoDismissable ||
                      props.article.type === ArticleType.Filler
                    )
                  ) {
                    postAnalyticsEvent({
                      category: "ArticleContent",
                      action: "OrderArticleSelectorPage(" + props.article.name + ")",
                    });
                    postEcomAnalyticsEvent("select_item", {
                      item_list_id: props.articlegroup.id,
                      item_list_name: props.articlegroup.name,
                      items: [
                        {
                          item_id: props.article.id,
                          item_name: props.article.name,
                          price: props.article.price,
                          quantity: 1,
                        },
                      ],
                    });
                    openDialog({
                      children: <OrderArticleSelectorPage article={props.article} articlegroup={props.articlegroup} />,
                      fullScreen: !isWidescreen,
                      TransitionComponent: SlideUpTransition,
                    });
                  }
                }}
              >
                {props.article.imageMediaUrls?.[0]?.conversions?.responsive?.srcset ||
                articleMissingImagePlaceholder ? (
                  <Box
                    className={JSClassAnnotatedWithIds(
                      "JS-ArticleContent-ImageWrapper",
                      props.article,
                      props.articlegroup
                    )}
                    sx={{
                      height: 84,
                      padding: 1,
                      aspectRatio: "1/1",
                      maxWidth: isColumnDirection ? undefined : 0.33,
                    }}
                  >
                    <Image
                      srcSet={
                        props.article.imageMediaUrls?.[0]?.conversions?.responsive?.srcset ??
                        articleMissingImagePlaceholder?.conversions?.optimized?.srcset
                      }
                      style={{ width: "100%", height: "100%", objectFit: "contain" }}
                    />
                  </Box>
                ) : null}
                <Box
                  sx={{
                    width: isColumnDirection ? 1 : 0,
                    flex: "1 1 0%",
                    padding: 1,
                    display: "flex",
                    flexDirection: isColumnDirection ? "column" : "row",
                    flexWrap: isColumnDirection ? undefined : "wrap",
                    // alignItems: isColumnDirection ? "center" : "flex-start",
                    textAlign: isColumnDirection ? "center" : "initial",
                    height: isColumnDirection ? 0 : 1,
                    justifyContent: isColumnDirection ? "space-between" : "flex-start",
                    alignItems: "center",
                    gap: 0.5,
                  }}
                  className={JSClassAnnotatedWithIds("JS-ArticleContent-Wrapper1", props.article)}
                >
                  {/*<Box id={"JS-ArticleContent-Name-Description-Wrapper"}>*/}
                  <Typography
                    sx={{ fontWeight: 800, width: 1 }}
                    className={JSClassAnnotatedWithIds("JS-ArticleContent-Name", props.article)}
                  >
                    {getArticleName(props.article, lang)}
                  </Typography>
                  {getArticleDescription(props.article, lang) ? (
                    <Typography
                      sx={{
                        opacity: 0.6,
                        paddingRight: 1,
                        width: 1,
                        fontStyle: "italic",
                        textOverflow: "ellipsis",
                        lineClamp: "2",
                        WebkitLineClamp: "2",
                        overflow: "hidden",
                        boxOrient: "vertical",
                        WebkitBoxOrient: "vertical",
                        display: "-webkit-box",
                      }}
                      dangerouslySetInnerHTML={{ __html: getArticleDescription(props.article, lang) }}
                      className={JSClassAnnotatedWithIds("JS-ArticleContent-Description", props.article)}
                    />
                  ) : null}
                  {menuTags.filter((menuTag) => menuTag.showTag).length > 0 ? (
                    <Box
                      className={JSClassAnnotatedWithIds("JS-ArticleContent-MenuTags", props.article)}
                      sx={{
                        display: "flex",
                        flexWrap: "wrap",
                        alignItems: "center",
                        justifyContent: isColumnDirection ? "center" : "initial",
                      }}
                    >
                      {menuTags
                        .filter((menuTag) => menuTag.showTag)
                        .map((menuTag) => (
                          <MenuTagView key={menuTag.id} menuTag={menuTag} />
                        ))}
                    </Box>
                  ) : null}
                  {/*</Box>*/}
                  <Typography
                    sx={{ fontWeight: 800 }}
                    className={JSClassAnnotatedWithIds("JS-ArticleContent-Price", props.article)}
                  >
                    {props.article.price > 0 || props.article.vaprice > 0
                      ? toCurrency(props.article.vaprice > 0 ? props.article.vaprice : props.article.price, {
                          name: "ArticleContent-price",
                        })
                      : null}
                  </Typography>
                  {isCatalogMode ? (
                    <Box sx={{ ml: "auto", mt: "auto" }}>
                      <InfoOutlined />
                    </Box>
                  ) : (
                    <QuickAddOrderButton
                      article={props.article}
                      articlegroup={props.articlegroup}
                      isColumnOrientation={isColumnDirection}
                    />
                  )}
                </Box>
              </ButtonBase>
              {secondsUntilEndTime != null && secondsUntilEndTime < 900 ? (
                <JTooltip
                  title={
                    <FormattedMessageJamezz
                      id={"{articleName} will be unavailable soon!}"}
                      values={{ articleName: getArticleName(props.article, lang) }}
                    />
                  }
                >
                  <Box
                    sx={{
                      position: "absolute",
                      right: 0,
                      top: 0,
                      backgroundColor: backgroundColor,
                      borderTopRightRadius: 12,
                      borderBottomLeftRadius: 12,
                      fontWeight: 800,
                      fontSize: "1.5rem",
                      paddingX: 2,
                      paddingY: 1,
                      zIndex: 10,
                      color: "white",
                    }}
                  >
                    <CountDownTimer seconds={secondsUntilEndTime} />
                  </Box>
                </JTooltip>
              ) : null}

              {props.article.type === ArticleType.InfoDismissable || props.article.isOutOfStock ? (
                <IconButton
                  size={"large"}
                  sx={{
                    position: "absolute",
                    top: 0,
                    right: 0,
                    marginTop: 0,
                    marginRight: 0,
                  }}
                  onClick={(e) => {
                    postAnalyticsEvent({
                      category: "ArticleContent",
                      action: "InfoDismissable || isOutOfStock",
                    });
                    e.stopPropagation();
                    setCollapsed(true);
                  }}
                >
                  <Close />
                </IconButton>
              ) : null}
            </Box>
          </>
        </ComponentOverlay>
      </Paper>
    </Collapse>
  );
}

export default ArticleContent;
