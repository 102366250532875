import {
  ActiveArrangement,
  selectArrangementCreditCost,
  selectArrangementLoadingState,
  selectCurrentOrderRound,
  selectNumberOfActiveArrangements,
} from "../../../global/utils/redux/arrangements/arrangementsSlice.tsx";
import { useAppSelector } from "../../../global/utils/redux/store.tsx";
import { AyceRuleResult, useCheckAyceRules } from "../../utils/arrangements/useCheckAyceRules.ts";
import {
  CREDITS_PER_COVER_PER_ROUND_DISABLED,
  selectArrangementMaxRounds,
  selectArrangementMaxTime,
  selectMaxDishesInRound,
} from "../../../global/utils/redux/arrangements/arrangementRuleSelectors.ts";
import { Box } from "@mui/material";
import JTooltip from "../../../kiosk/components/JamezzMUI/JTooltip.tsx";
import { Restaurant, Warning } from "@mui/icons-material";
import { ArrangementOverText } from "./ArrangementOverText.tsx";
import { ArrangementsLoadingState } from "../../../types/qr/arrangements.ts";
import { JamezzIcon } from "../../../global/assets/JamezzIcon.tsx";
import { ArrangementChip, AyceInformationItem, AyceTicketTimer, RoundTimer } from "./ArrangementsOverview.tsx";
import FormattedMessageJamezz from "../../../global/components/FormattedMessageJamezz.tsx";

export function RegularArrangementOverview({ arrangement }: { arrangement: ActiveArrangement }) {
  const arrangementLoadingState = useAppSelector(selectArrangementLoadingState);
  const numberOfActiveArrangements = useAppSelector(selectNumberOfActiveArrangements);
  const rule = useCheckAyceRules();
  const currentOrderRound = useAppSelector(selectCurrentOrderRound);

  const arrangementCreditCount = useAppSelector(selectArrangementCreditCost);
  const maxDishesInRound = useAppSelector(selectMaxDishesInRound);

  const maxTime = useAppSelector(selectArrangementMaxTime);
  const maxRounds = useAppSelector(selectArrangementMaxRounds);
  const visitLength = arrangement?.visit_length;

  // TODO translations: arrangement name is not translated, customizable text?
  return (
    <>
      <Box sx={{ display: "flex", gap: 0.5, mb: 1, alignItems: "center" }}>
        <ArrangementChip name={arrangement!.name} covers={arrangement!.own_covers} />
        {arrangement?.supplementary_arrangements.map((supplementary_arrangement) => (
          <ArrangementChip
            name={supplementary_arrangement.name}
            covers={supplementary_arrangement.covers}
            supplementary
          />
        ))}
        {numberOfActiveArrangements > 1 ? (
          <JTooltip title={<FormattedMessageJamezz id="AYCE.overview.warning-multiple-active-regular-arrangements" />}>
            <Warning color="warning" data-cy="arrangements-multiple-active-warning" />
          </JTooltip>
        ) : null}
      </Box>
      <Box
        sx={{
          display: "grid",
          gridAutoFlow: "row",
          gap: "16px",
          maxWidth: "70vw",
          gridTemplateColumns: "1fr 1fr",
        }}
      >
        {rule === AyceRuleResult.EATING_TIME_OVER || rule === AyceRuleResult.ROUND_COUNTER_EXCEEDED ? (
          <ArrangementOverText rule={rule} />
        ) : (
          <>
            {maxTime === 0 || visitLength === "no_time" ? null : (
              <AyceTicketTimer arrangementLoadingState={arrangementLoadingState} />
            )}
            <AyceInformationItem
              icon={<Restaurant style={{ alignSelf: "center" }} />}
              value={
                <>
                  {currentOrderRound} / {maxRounds > 0 ? maxRounds : "∞"}
                </>
              }
              helper={<FormattedMessageJamezz id="AYCE.order-round" />}
              isLoading={arrangementLoadingState === ArrangementsLoadingState.LOADING}
            />
            {maxDishesInRound && maxDishesInRound !== CREDITS_PER_COVER_PER_ROUND_DISABLED ? (
              <AyceInformationItem
                data-cy="arrangement-info-view-credits"
                icon={<JamezzIcon />}
                value={
                  <>
                    {arrangementCreditCount} / {maxDishesInRound}
                  </>
                }
                helper="Credits"
                isLoading={arrangementLoadingState === ArrangementsLoadingState.LOADING}
              />
            ) : null}
            <RoundTimer timeBetweenRounds={arrangement?.minutes_between_every_round ?? null} />
          </>
        )}
      </Box>
    </>
  );
}
