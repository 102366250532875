import store, { useAppSelector } from "../../../global/utils/redux/store";
import Box from "@mui/material/Box";
import { Button, DialogActions, Typography } from "@mui/material";
import FormattedMessageJamezz from "../../../global/components/FormattedMessageJamezz";
import { useDialog } from "../../../global/utils/dialog/DialogProvider";
import { useState } from "react";
import {
  selectAllPackagingsTypesFromShoppingCartItems,
  userSelectedPackagingTypeId,
} from "../../../global/utils/redux/packagingsSlice";

function AskForWhichPackagingTypeToUse({ onClose }: { onClose: (packagingTypeId: string) => void }) {
  const packagingsPerType = useAppSelector(selectAllPackagingsTypesFromShoppingCartItems);
  const packagingTypes = useAppSelector((state) => state.packagings.packagingTypes);
  const [closing, setClosing] = useState(false);
  return (
    <Box sx={{ m: 4 }}>
      <Typography variant="h1" fontSize="2em" sx={{ mb: 2 }}>
        <FormattedMessageJamezz id="Popup.askForPackagingType.title" />
      </Typography>
      <DialogActions
        sx={{
          display: "grid",
          w: 1,
          gridTemplateColumns: "1fr 1fr",
          gap: 1,
        }}
      >
        {Object.keys(packagingsPerType).map((packagingTypeId) => {
          const packagingType = packagingTypes[packagingTypeId];
          if (!packagingType) {
            return null;
          }
          return (
            <Button
              key={packagingTypeId}
              onClick={() => {
                if (!closing) {
                  onClose(packagingTypeId);
                  setClosing(true);
                }
              }}
              variant="contained"
            >
              {packagingType.name}
            </Button>
          );
        })}
      </DialogActions>
    </Box>
  );
}

export default function useAskForWhichPackagingTypeToUse() {
  const { openDialog, closeDialog } = useDialog();

  return () => {
    new Promise<void>((resolve) => {
      openDialog({
        disabledBackdropClick: true,
        maxWidth: "sm",
        children: (
          <AskForWhichPackagingTypeToUse
            onClose={(packagingTypeId) => {
              closeDialog();
              store.dispatch(userSelectedPackagingTypeId(packagingTypeId));
              resolve();
            }}
          />
        ),
      });
    });
  };
}
