import Voucher from "./Voucher";
import { signalToServerReloadVouchersFromLocalStorage } from "../reloadVoucherSession";
import { useEffect } from "react";

export default function useVoucherSession() {
  useEffect(() => {
    let vouchersCartJson = localStorage.getItem("V5.shoppingCart.vouchers");
    let vouchersCart: Voucher[] = [];
    if (vouchersCartJson != null) {
      vouchersCart = JSON.parse(vouchersCartJson) as Voucher[];
      if (vouchersCart.length > 0) {
        // tell server to reinstate the vouchersession
        signalToServerReloadVouchersFromLocalStorage("reloadVouchersFromLocalStorage", vouchersCart);
      } else {
        signalToServerReloadVouchersFromLocalStorage("reloadVouchersFromLocalStorage", []);
      }
    } else {
      signalToServerReloadVouchersFromLocalStorage("reloadVouchersFromLocalStorage", []);
    }
  }, []);

  useEffect(() => {
    /// Lets process vouchers from server
  }, []);
}
