import Articlegroup from "../models/menu/Articlegroup";
import { getArticlesByArticlegroupId } from "../models/menu/Menu";
import useMenuFilters, { useArticlegroupFilter } from "../menu/filters/useMenuFilters";
import { useMemo } from "react";
import store, { useAppSelector } from "../redux/store";
import { selectArticlesMap } from "../redux/selectors/selectArticlesMap";
import { selectArticlegroupArticlesMap } from "../redux/selectors/selectArticlegroupArticlesMap";
import { selectArticlegroupChildrenMap } from "../redux/selectors/selectArticlegroupChildrenMap";

export default function useArticlegroups(articlegroups: Articlegroup[]) {
  const articlesMap = useAppSelector(selectArticlesMap);

  const articlegroupArticlesMap = useAppSelector(selectArticlegroupArticlesMap);
  const articlegroupChildrenMap = useAppSelector(selectArticlegroupChildrenMap);
  const articleFilter = useMenuFilters();
  const articlegroupFilter = useArticlegroupFilter();
  const productsLoaded = useAppSelector((state) => state.menuData.productsLoaded);

  const result = useMemo(
    () =>
      articlegroups.filter(articlegroupFilter).filter((articlegroup) => {
        if (store.getState().global.salesarea.custom_data?.appv5_use_articlegroups_hierarchically?.enabled) {
          const checkArticlegroupChildren = (articlegroupId: string): boolean => {
            if (
              getArticlesByArticlegroupId(articlesMap, articlegroupArticlesMap, articlegroupId).filter(articleFilter)
                .length > 0
            ) {
              return true;
            }
            return (
              articlegroupChildrenMap[articlegroupId]?.some((articlegroupChildId) => {
                return checkArticlegroupChildren(articlegroupChildId);
              }) ?? false
            );
          };
          return productsLoaded ? checkArticlegroupChildren(articlegroup.id) : true;
        } else {
          return productsLoaded
            ? getArticlesByArticlegroupId(articlesMap, articlegroupArticlesMap, articlegroup.id).filter(articleFilter)
                .length > 0
            : true;
        }
      }),
    [
      articlegroups,
      articlegroupFilter,
      articlesMap,
      articlegroupArticlesMap,
      articleFilter,
      articlegroupChildrenMap,
      productsLoaded,
    ]
  );

  // const { data: dataProducts } = useFetchProductsDataQuery(
  //   { menukaartIds: [result?.[0]?.id ?? "0"] },
  //   { pollingInterval: 120_000, skip: productsLoaded || result.length == 0 }
  // );
  // console.log(result);
  //
  // useEffect(() => {
  //   if (dataProducts) {
  //     store.dispatch(setMenuDataProductsPreloaded(dataProducts.products));
  //     store.dispatch(setMenuDataTags(dataProducts.tags));
  //   }
  // }, [dataProducts]);

  return result;
}
