import store, { useAppSelector } from "../../../global/utils/redux/store.tsx";
import { useLazyContactByIdentifierQuery } from "../../../global/utils/redux/api/piggyAuthApi.ts";
import { useLoadingScreen } from "../../../global/modules/LoadingScreen/loadingScreen.ts";
import { useEffect } from "react";
// @ts-ignore
import scrollIntoView from "scroll-into-view";
import { piggyUserLogInFailed } from "../../../global/utils/redux/piggySlice.tsx";

export default function usePiggyLogin() {
  const timestamp = useAppSelector((state) => state.piggy.contactIdentifierScan?.timestamp);
  const [trigger, { isError, isFetching }] = useLazyContactByIdentifierQuery();

  useLoadingScreen(isFetching);

  useEffect(() => {
    if (timestamp) {
      const contactIdentifierScan = store.getState().piggy.contactIdentifierScan;
      if (contactIdentifierScan) {
        trigger({
          identifier: contactIdentifierScan.code,
          timestamp: contactIdentifierScan.timestamp,
          create_if_not_exists: true,
        })
          .catch(() => {
            store.dispatch(piggyUserLogInFailed({ code: contactIdentifierScan.code }));
          })
          .then(() => {
            const element = document.getElementById("PiggyLoyaltyContent-Card");
            if (element) {
              scrollIntoView(element, {
                time: 500,
                align: {
                  top: 20,
                },
                validTarget: () => {
                  return true;
                },
              });
            }
          });
      }
    }
  }, [timestamp, trigger]);

  useEffect(() => {
    if (timestamp && isError) {
      const contactIdentifierScan = store.getState().piggy.contactIdentifierScan;
      if (contactIdentifierScan?.timestamp == timestamp) {
        store.dispatch(piggyUserLogInFailed({ code: contactIdentifierScan.code }));
      }
    }
  }, [isError, timestamp]);
}
