import { useAppSelector } from "../redux/store";
import { useCallback, useMemo } from "react";
import MenuTag from "../models/menu/MenuTag";
import Article from "../models/menu/Article";
import OrderArticle from "../models/order/OrderArticle";
import { selectMenuTagFilters } from "../redux/selectors/selectMenuTagFilters";
import { useIntl } from "react-intl";

export default function useActiveMenuTags(article: Article) {
  const menuTagPreferences = useAppSelector((state) => state.menuState.menuTagPreferences);

  return useMemo<MenuTag[]>(() => {
    return (
      article.menuTagIds?.reduce((activeTags: MenuTag[], menuTagId) => {
        if (menuTagPreferences[menuTagId]?.checked) {
          activeTags.push(menuTagPreferences[menuTagId].menuTag);
        }
        return activeTags;
      }, []) ?? []
    );
  }, [article?.menuTagIds, menuTagPreferences]);
}

export function useActiveMenuTagsOfOrderArticle(orderArticle: OrderArticle) {
  const menuTagPreferences = useAppSelector((state) => state.menuState.menuTagPreferences);
  const intl = useIntl();
  const menuTagFilters = useAppSelector((state) => selectMenuTagFilters(state, intl));

  const getActiveMenuTags = useCallback(
    (orderArticle: OrderArticle, menuTags: Record<string, MenuTag>) => {
      orderArticle.article.menuTagIds?.forEach((menuTagId) => {
        const menuTag = menuTagFilters[menuTagId];
        if (menuTag && menuTagPreferences[menuTagId]?.checked) {
          menuTags[menuTagId] = menuTag;
        }
      });

      orderArticle.orderOptionGroups.forEach((orderOptionGroup) => {
        orderOptionGroup.orderArticles.forEach((optionOrderArticle) => {
          if (optionOrderArticle.count > 0) {
            getActiveMenuTags(optionOrderArticle, menuTags);
          }
        });
      });
    },
    [menuTagFilters, menuTagPreferences]
  );

  return useMemo<MenuTag[]>(() => {
    const menuTags: Record<string, MenuTag> = {};

    getActiveMenuTags(orderArticle, menuTags);

    return Object.keys(menuTags).map((menuTagId) => menuTags[menuTagId]);
  }, [orderArticle, getActiveMenuTags]);
}

export function useMenuTagsOfOrderArticles(orderArticles: OrderArticle[]) {
  const intl = useIntl();
  const menuTagFilters = useAppSelector((state) => selectMenuTagFilters(state, intl));

  return useMemo<MenuTag[]>(() => {
    const menuTags: Record<string, MenuTag> = {};

    orderArticles.forEach((orderArticle) => {
      orderArticle.article.menuTagIds?.forEach((menuTagId) => {
        const menuTag = menuTagFilters[menuTagId];
        if (menuTag) {
          menuTags[menuTagId] = menuTag;
        }
      });
    });

    return Object.keys(menuTags).map((menuTagId) => menuTags[menuTagId]);
  }, [menuTagFilters, orderArticles]);
}
