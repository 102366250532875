import { useAppSelector } from "../../global/utils/redux/store";
import { useEffect } from "react";

/**
 * The kiosk is specifically designed for the resolution 1080x1920 in portrait mode
 * this customer uses iPad pros in landscape mode, 1366x1024
 * The iPad has a physical resolution of 2732x2048, but with a device-pixel-ratio of 2
 * meaning the logical pixels reported to the browser are 1366x1024.
 *
 * This fix looks for the exact resolution with a media query and changes the meta viewport tag
 * to zoom the content to 0.5, inverting the device-pixel-ratio
 */
export function adjustViewport() {
  let viewportTag = document.querySelector('meta[name="viewport"]');
  const ipadProMediaQueries = [
    "only screen and (width: 1024px) and (orientation: portrait)",
    "only screen and (width: 1366px) and (orientation: landscape)",
  ];

  if (window.matchMedia(ipadProMediaQueries[0]).matches || window.matchMedia(ipadProMediaQueries[1]).matches) {
    if (!viewportTag) {
      // The viewport tag should always exist, but handle anyway
      viewportTag = document.createElement("meta");
      viewportTag.setAttribute("name", "viewport");
      document.head.appendChild(viewportTag);
    }
    viewportTag.setAttribute("content", `width=device-width, initial-scale=0.5 maximum-scale=0.5, user-scalable=no`);
  }
}

export function adjustViewportWithZoom(zoomFactor: number) {
  let viewportTag = document.querySelector('meta[name="viewport"]');

  if (!viewportTag) {
    // The viewport tag should always exist, but handle anyway
    viewportTag = document.createElement("meta");
    viewportTag.setAttribute("name", "viewport");
    document.head.appendChild(viewportTag);
  }
  viewportTag.setAttribute(
    "content",
    `width=device-width, initial-scale=${zoomFactor} maximum-scale=${zoomFactor}, user-scalable=no`
  );
}

export function useZoomFactor() {
  const zoomFactor = useAppSelector((state) => state.global.salesarea?.custom_data?.kiosk?.zoom_factor ?? 0);
  useEffect(() => {
    if (zoomFactor > 0) {
      adjustViewportWithZoom(zoomFactor);
    }
  }, [zoomFactor]);
}
