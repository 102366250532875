import { Alert, Box, Button, CircularProgress, Divider, FormHelperText, Grid, Typography } from "@mui/material";
import CloseDialogButton from "../../components/Dialog/CloseDialogButton.tsx";
import { useAppDispatch, useAppSelector } from "../../../global/utils/redux/store";
import {
  selectArrangementShoppingCartItems,
  selectCurrentOrderRound,
} from "../../../global/utils/redux/arrangements/arrangementsSlice.tsx";
import { AyceOrderTimer } from "../../components/Arrangements/AyceOrderTimer";
import { OptionTranslationsContext } from "../../../global/utils/translations/useOptionTranslations";
import CheckoutArticleContent from "../CheckoutPage/CheckoutArticleContent";
import { useCustomTexts } from "../../../global/utils/useCustomTexts";
import FormattedMessageJamezz from "../../../global/components/FormattedMessageJamezz";
import useSalesareaIsOpen from "../../../global/utils/useSalesareaIsOpen";
import { useAccountTotalValue } from "../../../global/utils/redux/accountSlice";
import useCurrency, { CurrencyLocation } from "../../../global/utils/useCurrency";
import useSendCheckout2 from "../../../global/utils/useSendCheckout2";
import { partialOrderCreated } from "../../../global/utils/redux/shoppingCartSlice";
import BackgroundMediaUrlFromSalesarea from "../../../kiosk/hooks/useBackgroundMediaUrl.tsx";
import { useFetchTableStateQuery } from "../../../global/utils/redux/api/arrangementsApi";
import { AyceRuleResult, useCheckAyceRules } from "../../utils/arrangements/useCheckAyceRules.ts";
import { ArrangementOverText } from "../../components/Arrangements/ArrangementOverText.tsx";
import { selectActiveArrangement } from "../../../global/utils/redux/arrangements/selectActiveArrangement.tsx";

export const AyceCheckoutPage = () => {
  const { inside_arrangement, outside_arrangement } = useAppSelector(selectArrangementShoppingCartItems);
  const customTexts = useCustomTexts(["pay button", "order button", "checkout_message"]);
  const isOpen = useSalesareaIsOpen();
  const totalAmount = useAccountTotalValue();
  const toCurrency = useCurrency({ location: CurrencyLocation.Checkout });
  const sendCheckout = useSendCheckout2();
  const dispatch = useAppDispatch();
  const currentOrderRound = useAppSelector(selectCurrentOrderRound);
  const orderTimerFinished = useAppSelector((state) => state.arrangements.orderTimeElapsed);
  const { isLoading: tableStateLoading } = useFetchTableStateQuery();

  const ruleCheckResult = useCheckAyceRules();
  // for testing
  // const ruleCheckResult = AyceRuleFailed.NO_RULE_FAILURE;

  const activeArrangement = useAppSelector(selectActiveArrangement);

  const allItems = [...inside_arrangement, ...outside_arrangement];
  const isRound = inside_arrangement.length > 0;
  const hasItemsOutsideRound = outside_arrangement.length > 0;

  return (
    <div data-cy="ayce-checkout-page" style={{ height: "100%" }}>
      <BackgroundMediaUrlFromSalesarea
        keyName={"extra_settings__qrv5_background_of_checkout_page"}
        className="JS-CheckoutPage-BackgroundImage"
        sx={{
          display: "flex",
          flexDirection: "column",
          p: 4,
          width: 1,
          height: 1,
          overflowY: "auto",
        }}
      >
        <CloseDialogButton />
        {ruleCheckResult === AyceRuleResult.ROUND_COUNTER_EXCEEDED ||
        ruleCheckResult === AyceRuleResult.EATING_TIME_OVER ? (
          <ArrangementOverText rule={ruleCheckResult} />
        ) : (
          <>
            {activeArrangement === null || ruleCheckResult === AyceRuleResult.RULES_NOT_AVAILABLE ? (
              <Typography
                sx={{ textAlign: "center", position: "absolute", left: "50%", bottom: "50%", translate: "-50% 0" }}
              >
                <CircularProgress />
                <br />
                <FormattedMessageJamezz id="Loading" />
              </Typography>
            ) : (
              <>
                {orderTimerFinished ? (
                  allItems.map((orderArticle) => (
                    <OptionTranslationsContext.Provider
                      key={orderArticle.uuid}
                      value={orderArticle.article.translations}
                    >
                      <CheckoutArticleContent key={orderArticle.uuid} orderArticle={orderArticle} />
                    </OptionTranslationsContext.Provider>
                  ))
                ) : (
                  <Grid container>
                    {outside_arrangement.length > 0 ? (
                      <>
                        <h2>
                          <FormattedMessageJamezz id="AYCE.heading.drinks-and-extras" />
                        </h2>
                        {outside_arrangement.map((orderArticle) => (
                          <OptionTranslationsContext.Provider
                            key={orderArticle.uuid}
                            value={orderArticle.article.translations}
                          >
                            <CheckoutArticleContent key={orderArticle.uuid} orderArticle={orderArticle} />
                          </OptionTranslationsContext.Provider>
                        ))}
                        {outside_arrangement.length > 0 ? (
                          <Box sx={{ my: 1, width: 1 }}>
                            <Button
                              data-cy="order-drinks-and-extras-btn"
                              variant="outlined"
                              sx={{ width: 1, mt: 2 }}
                              onClick={() => {
                                dispatch(
                                  partialOrderCreated({
                                    excludedItemIds: inside_arrangement.map(
                                      (order_article) => order_article.article.id
                                    ),
                                  })
                                );
                                sendCheckout();
                              }}
                            >
                              <FormattedMessageJamezz id="AYCE.order-drinks-and-extras" />
                            </Button>
                            <FormHelperText>
                              <FormattedMessageJamezz id="AYCE.helpertext.order-drinks-and-extras"></FormattedMessageJamezz>
                            </FormHelperText>
                          </Box>
                        ) : null}
                      </>
                    ) : null}
                    {inside_arrangement.length > 0 ? (
                      <h2>
                        <FormattedMessageJamezz id="AYCE.heading.next-round" />
                      </h2>
                    ) : null}
                    {inside_arrangement.map((orderArticle) => (
                      <OptionTranslationsContext.Provider
                        key={orderArticle.uuid}
                        value={orderArticle.article.translations}
                      >
                        <CheckoutArticleContent key={orderArticle.uuid} orderArticle={orderArticle} />
                      </OptionTranslationsContext.Provider>
                    ))}
                  </Grid>
                )}
                {customTexts["checkout_message"] ? (
                  <>
                    <Alert severity="info" className="JS-CheckoutPage-CheckoutMessage" icon={false}>
                      {customTexts["checkout_message"]}
                    </Alert>
                    <Divider sx={{ height: 4, width: 1, marginY: 2 }} />
                  </>
                ) : null}
                <Divider sx={{ height: 4, width: 1, marginY: 2 }} />
                <Box sx={{ display: "flex", justifyContent: "space-between", width: 1 }}>
                  <Typography className="JS-CheckoutPage-Total">
                    <FormattedMessageJamezz id={"Total amount"} />
                  </Typography>

                  <Typography
                    className="JS-CheckoutPage-Price"
                    align={"right"}
                    sx={{ fontWeight: 800, whiteSpace: "nowrap" }}
                  >
                    {toCurrency(totalAmount, { name: "CheckoutPage-Price" })}
                  </Typography>
                </Box>
                <Divider sx={{ height: 4, width: 1, marginY: 2 }} />
                <AyceOrderTimer />
                {allItems.length > 0 && (orderTimerFinished || currentOrderRound === 1) ? (
                  <Box sx={{ my: 1, width: 1 }}>
                    <Button
                      data-cy="orderpage-checkout-payOrOrder-button"
                      className="JS-CheckoutPage-PayOrOrderButton"
                      sx={{ marginTop: 4, width: 1 }}
                      variant={"contained"}
                      size={"large"}
                      type={"submit"}
                      disabled={!isOpen || tableStateLoading}
                      onClick={() => {
                        sendCheckout();
                      }}
                    >
                      {!isRound && hasItemsOutsideRound ? (
                        <FormattedMessageJamezz id="AYCE.order-outside-round" />
                      ) : (
                        <FormattedMessageJamezz id="AYCE.order-round" />
                      )}
                    </Button>
                    {!hasItemsOutsideRound && ruleCheckResult === AyceRuleResult.PRE_DINING ? (
                      <FormHelperText>
                        <FormattedMessageJamezz id="AYCE.overview.no-arrangement-selected.default-body" />
                      </FormHelperText>
                    ) : null}
                  </Box>
                ) : null}
              </>
            )}
          </>
        )}
      </BackgroundMediaUrlFromSalesarea>
    </div>
  );
};
