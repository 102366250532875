import { Button, ButtonProps, SxProps } from "@mui/material";
import { Theme } from "@mui/material/styles";

export type OrderPageActionColor = "emphasize" | "regular";

export function OrderPageAction({
  emphasize = false,
  sx,
  children,
  onClick,
  ...props
}: Omit<ButtonProps, "sx" | "color"> & {
  emphasize?: boolean;
  sx?: SxProps<Theme>;
}) {
  return (
    <Button
      variant="contained"
      size="large"
      onClick={onClick}
      sx={{
        backgroundColor: emphasize ? "primary.contrastText" : "primary",
        color: emphasize ? "primary.light" : "primary.contrastText",
        borderRadius: "30px",
        padding: "16px",
        fontSize: 42,
        fontWeight: 800,
        ...sx,
      }}
      {...props}
    >
      {children}
    </Button>
  );
}
