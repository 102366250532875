import { useRef } from "react";
import CustomField from "../../../../types/shared/CustomField";
import { FormControl, FormHelperText, TextField } from "@mui/material";
import useCustomField from "./useCustomField";
import { onFocusKeyboard } from "../../../../global/utils/redux/globalSlice";
import { useAppDispatch } from "../../../../global/utils/redux/store";
import isQr from "../../../../global/utils/helpers/isQr";
import { useFocusCustomField } from "./useFocusCustomField";
import isKiosk from "../../../../global/utils/helpers/isKiosk";
import Explanation from "./Explanation";

interface Props {
  customField: CustomField;
}

function CustomFieldTextContent(props: Props) {
  const { value, setCustomField } = useCustomField(props.customField);
  const customField = props.customField;
  const dispatch = useAppDispatch();
  const inputRef = useRef<HTMLInputElement | null>(null);

  const maxLength = Number.isNaN(customField.maxlength) ? 1024 : Math.max(1, Number(customField.maxlength));
  useFocusCustomField(inputRef, customField.name);

  return (
    <>
      <FormControl sx={{ marginY: 1, width: 1 }}>
        <FormHelperText>{customField.description}</FormHelperText>
        <TextField
          helperText={<Explanation customField={customField} />}
          focused={true}
          variant={isQr() ? "filled" : "outlined"}
          inputRef={inputRef}
          name={customField.name}
          type={"text"}
          inputProps={{
            inputMode: isKiosk() ? "none" : undefined,
            pattern: customField.pattern ?? (customField.required ? ".*\\S+.*" : null),
            required: customField.required,
            className: "JS-CustomFieldTextContent-Input JS-CustomFields-Input",
            maxLength,
          }}
          InputLabelProps={{
            className: "JS-CustomFields-Input-Label JS-CustomFieldTextContent-Label",
          }}
          disabled={customField.disabled}
          label={customField.label}
          rows={customField.rows ?? 1}
          multiline={(customField.rows ?? 1) > 1}
          fullWidth
          value={value}
          onChange={(e) => {
            setCustomField(e.target.value);
          }}
          onKeyDown={(e) => {
            if (isKiosk()) {
              e.stopPropagation();
              if (e.keyCode == 13) {
                e.preventDefault();
                return false;
              }
            }
          }}
          onFocus={() => {
            if (isKiosk()) {
              dispatch(
                onFocusKeyboard({
                  value: value,
                  layout: "default",
                })
              );
            }
          }}
        />
      </FormControl>
    </>
  );
}

export default CustomFieldTextContent;
